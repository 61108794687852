export enum ProspectLoanSteps {
  WELCOME = 'WELCOME',
  LOAN_USE = 'LOAN_USE',
  RESTAURANT_INFO = 'RESTAURANT_INFO',
  BUSINESS_INFO = 'BUSINESS_INFO',
  OWNERSHIP = 'OWNERSHIP',
  FINANCES = 'FINANCES',
  LINK_ACCOUNTS = 'LINK_ACCOUNTS',
  REVIEW_TERMS = 'REVIEW_TERMS',
  SUBMITTING = 'SUBMITTING',
  SUBMITTED = 'SUBMITTED',
  ERROR = 'ERROR'
}

export type ProspectLoanStepProps = {
  isValid: boolean
  validateForm(): void
  hasLinkedAccountsInCodat: boolean
  onLinkAccountsInCodat(): void
  setPageError(pageError: string | undefined): void
  getCodatCompany(dealNumber: string): void
  codatCompanyId?: string
}

export enum OWNER_ROLE {
  OWNER = 'OWNER',
  CONTROL_PERSON = 'CONTROL_PERSON'
}

export interface Owner {
  role: OWNER_ROLE
  firstName: string
  lastName: string
  jobTitle: string
  email: string
  phoneNumber: number
  dob: string
  ssn: string
  ownershipStake: number
  address: Address
  yearsAtAddress?: number
  attestation: boolean
}

export interface Address {
  street1: string
  street2?: string
  city: string
  state: string
  zip: string
  country?: string
}

export const BASE_OWNER = {
  attestation: false
}

export interface Restaurant {
  name: string
  phoneNumber: number
  website?: string
  address: Address
}

export interface Merchant {
  name: string
  tinType: string
  tin: string
  address: Address
}

export interface DebtItem {
  debtType: string
  debtAmount: number
}

export enum OWNERSHIP_TYPE {
  FINANCIAL_INSTITUTION = 'FINANCIAL_INSTITUTION',
  FRANCHISE = 'FRANCHISE',
  LLC = 'LLC',
  NON_PROFIT = 'NON_PROFIT',
  PARTNERSHIP = 'PARTNERSHIP',
  PRIVATE_CORPORATION = 'PRIVATE_CORPORATION',
  PUBLIC_CORPORATION = 'PUBLIC_CORPORATION',
  SEC_REGULATED_CORPORATION = 'SEC_REGULATED_CORPORATION',
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
  TRUST = 'TRUST',
  OTHER = 'OTHER'
}

export interface ProspectLoanFormValues {
  restaurant: Restaurant
  merchant: Merchant
  owners: Owner[]
  ownershipType: OWNERSHIP_TYPE
  hasFiledPriorYearTaxes: string
  debtList: DebtItem[]
  hasNoDebt: boolean
  loanUseCase: string
  threeMonthTotalSales: number
  filingYear: string
  netIncome: number
  isLeasing: string
  leaseEndDate?: number
  hasTaxLiens: string
  taxLienAmount: number
  taxLienFilingDate: string
  hasJudgementsOrLawsuits: string
  hasBankruptcy: string
  hasOwnershipChanged: string
  hasHistoryOfClosure: string
  hasPlannedChangeOfOwnership: string
  hasPlannedClosures: string
  hasPlannedLocationChange: string
  socialScore: string
  contactName: string
  contactPhoneNumber: number
  contactEmail: string
  contactTimes: string[]
  termsAndConditionsConsent: boolean
}

export interface OwnerPayload {
  firstName: string
  lastName: string
  address: Address
  email: string
  phoneNumber: string
  dob: string
  ownershipStake: number
  ssn: string
  jobTitle: string
  yearsAtAddress?: number
  isControlPerson: boolean
  hasOwnerInformationAttestation: boolean
}

export interface ProspectLoanApplicationPayload {
  // Six digit deal number
  opportunityExternalId: string
  owners: OwnerPayload[]
  restaurant: {
    name: string
    address: Address
    phoneNumber: string
    website?: string
  }
  merchant: {
    name: string
    address: Address
    tin: string
    tinType: string
  }
  contact: {
    fullName: string
    phoneNumber: string
    email: string
  }
  legalEntity: string
  socialScore: string
  loanUseCase: string
  debts: { amount: number; type: string }[]
  currentDebt: number
  taxFiling: {
    filingYear: string
    lastYearsProfitMargin: number
    hasFiledLastYearsTaxes: boolean
  }
  taxLien?: {
    amount: number
    filingDate: string
  }
  threeMonthTotalSales: number
  bestContactTimes: string[]
  hasHistoryOfClosure: boolean
  hasPlannedChangeOfOwnership: boolean
  hasPlannedClosures: boolean
  hasPlannedLocationChange: boolean
  isPropertyOwned: boolean
  leaseEndDate?: string
  hasOwnershipChanged: boolean
  hasPastBankruptcy: boolean
  hasTermsAndConditionsConsent: boolean
  hasJudgementsOrLawsuits: boolean
  loanAttribution?: {
    source: string
    campaign: string
    medium: string
  }
}

export enum APPLICATION_STAGE {
  DOES_NOT_QUALIFY_MANUAL_REVIEW = 'DOES_NOT_QUALIFY_MANUAL_REVIEW',
  DOES_NOT_QUALIFY = 'DOES_NOT_QUALIFY',
  OFFER_DECLINED = 'OFFER_DECLINED',
  OFFER_EXPIRED = 'OFFER_EXPIRED',
  FINAL_OFFER = 'FINAL_OFFER',
  FINAL_OFFER_MANUAL_REVIEW = 'FINAL_OFFER_MANUAL_REVIEW',
  FUNDING_REQUESTED = 'FUNDING_REQUESTED',
  OFFER_SIGNED = 'OFFER_SIGNED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PREQUALIFIED_OFFER = 'PREQUALIFIED_OFFER',
  PREAPPROVED = 'PREAPPROVED',
  PREAPPROVED_NOT_PROCESSING = 'PREAPPROVED_NOT_PROCESSING'
}

export interface ProspectLoanApplication {
  fixedFeeAmount: number
  originationAmount: number
  repaymentRate: number
  term: number
  stage: APPLICATION_STAGE
}

export interface CodatCompany {
  companyId: string
}
