import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useBanquetProps } from 'banquet-runtime-modules'
import { formatPercent } from '@toasttab/buffet-pui-number-utilities'
import { ReviewSection } from './ReviewSection'
import { Owner, OWNER_ROLE, ProspectLoanSteps } from '../types'
import { formatAddress } from './utils'

interface Props {
  owners: Owner[]
  goToStep(step: ProspectLoanSteps): void
}

export const OwnershipReview = ({ owners, goToStep }: Props): ReactElement => {
  const { t } = useTranslation()
  const { i18n } = useBanquetProps()

  const ownersList = owners.filter((owner) => owner.role === OWNER_ROLE.OWNER)
  const controlPerson = owners.find(
    (owner) => owner.role === OWNER_ROLE.CONTROL_PERSON
  ) as Owner

  return (
    <ReviewSection
      title={t('prospectLoans.review.ownershipTitle')}
      onEditClick={() => goToStep(ProspectLoanSteps.OWNERSHIP)}
    >
      <div className='mt-6'>
        {ownersList.map((owner, index) => (
          <div className='mb-6' key={`${owner.firstName} ${owner.lastName}`}>
            <p className='font-medium text-default mb-1'>
              {t('prospectLoans.review.ownerLabel', { number: index + 1 })}{' '}
              {owner.firstName} {owner.lastName}
            </p>
            <p className='text-default'>
              {t('prospectLoans.review.ownership')}{' '}
              <span className='text-secondary'>
                {formatPercent(
                  owner.ownershipStake / 100,
                  i18n?.language ?? 'en-US'
                )}
              </span>
            </p>
            <p className='text-default'>
              {t('prospectLoans.review.address')}{' '}
              <span className='text-secondary'>
                {formatAddress(owner.address)}
              </span>
            </p>
          </div>
        ))}
        <div>
          <p
            className='font-medium text-default mb-1'
            data-testid='control-person-name'
          >
            {t('prospectLoans.review.controlPerson')} {controlPerson.firstName}{' '}
            {controlPerson.lastName}
          </p>
          <p className='text-default' data-testid='control-person-address'>
            {t('prospectLoans.review.address')}{' '}
            <span className='text-secondary'>
              {formatAddress(controlPerson.address)}
            </span>
          </p>
        </div>
      </div>
    </ReviewSection>
  )
}
