import React, { ReactElement, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Formik } from 'formik'
import { useFlowNavigation } from '@local/use-flow-navigation'
import { Page, PageContainer } from '@toasttab/buffet-pui-wizard-templates'
import ToastLogo from '../../src/components/ToastLogo'
import FinancingApi from '../../src/api/Financing'
import { EasyPayWelcome } from './Welcome'
import { BusinessInfo } from './BusinessInfo'
import { RestaurantInfo } from './RestaurantInfo'
import { ReviewTerms } from './ReviewTerms'
import { Submitting } from './Submitting'
import { EasyPayStepProps, EasyPaySteps, EasyPayApplicationForm } from './types'
import { useValidationSchema } from './validation'
import { formatRequestBody, eligibleForWelcomeLoan } from './helpers'
import { EasyPayThankYou } from './EasyPayThankYou'
import { EasyPayThankYouMessage } from './EasyPayThankYouMessage'
import { EasyPayAside } from './EasyPayAside'
import { Error } from './Error'
import { MarketingPage } from '@local/prospect-loans'

export const EasyPay = (): ReactElement => {
  const [displayMarketingPage, setDisplayMarketingPage] = useState(false)
  const { dealNumber } = useParams<{ dealNumber: string }>()
  const { StepComponent, currentStepId, goToStep } =
    useFlowNavigation<EasyPayStepProps>({
      steps: [
        {
          id: EasyPaySteps.WELCOME,
          component: EasyPayWelcome
        },
        {
          id: EasyPaySteps.RESTAURANT_INFO,
          component: RestaurantInfo
        },
        {
          id: EasyPaySteps.BUSINESS_INFO,
          component: BusinessInfo
        },
        {
          id: EasyPaySteps.REVIEW_TERMS,
          component: ReviewTerms
        },
        {
          id: EasyPaySteps.SUBMITTING,
          component: Submitting
        },
        {
          id: EasyPaySteps.THANK_YOU,
          component: EasyPayThankYou
        },
        {
          id: EasyPaySteps.ERROR,
          component: Error
        }
      ]
    })

  const validationSchema = useValidationSchema(currentStepId as EasyPaySteps)

  return (
    <>
      {displayMarketingPage ? (
        <MarketingPage
          thankYou={<EasyPayThankYouMessage />}
          disclaimer={<EasyPayAside />}
        />
      ) : (
        <Formik
          initialValues={
            {
              owner: {
                disclosureCheckbox: false
              }
            } as EasyPayApplicationForm
          }
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            goToStep(EasyPaySteps.SUBMITTING)
            try {
              await FinancingApi.submitApplication(
                dealNumber,
                formatRequestBody(values)
              )
              if (eligibleForWelcomeLoan(values.merchant.address.state)) {
                // the page will now load the MarketingPage component instead
                // with the thank you message and disclaimer for EasyPay
                setDisplayMarketingPage(true)
              } else {
                // only shows the thank you message and disclaimer in the form formatting
                goToStep(EasyPaySteps.THANK_YOU)
              }
            } catch (e) {
              goToStep(EasyPaySteps.ERROR)
            }
          }}
        >
          {({ isValid, validateForm }) => (
            <Page>
              <div className='absolute pt-3 pl-4 md:pt-8 md:pl-12 z-50'>
                <ToastLogo />
              </div>
              <PageContainer>
                <StepComponent isValid={isValid} validateForm={validateForm} />
              </PageContainer>
            </Page>
          )}
        </Formik>
      )}
    </>
  )
}
