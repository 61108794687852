import { object, string, number, bool } from 'yup'
import { validateDayAgainstMonth } from '../../src/components/DateField/dateFieldUtils'
import { FinancingSteps } from './Types'
import { useTranslation } from 'react-i18next'

const addressFieldsSchema = (t: (key: string) => string) =>
  object().shape({
    street1: string().required(t('validation.address.street')),
    street2: string().nullable(),
    city: string().required(t('validation.address.city')),
    state: string().required(t('validation.address.state')),
    zip: string()
      .matches(/^[0-9]{5}$/, t('validation.address.zip'))
      .required(t('validation.address.zip'))
  })

const RestaurantInformationSchema = (t: (key: string) => string) =>
  object().shape({
    restaurant: object().shape({
      name: string().required(t('validation.restaurant.name')),
      address: addressFieldsSchema(t),
      website: string(),
      phoneNumber: string()
        .min(10, t('validation.restaurant.phone'))
        .required(t('validation.restaurant.phone'))
    })
  })

export const BusinessInformationSchema = (t: (key: string) => string) =>
  object().shape({
    merchant: object().shape({
      name: string().required(t('validation.merchantNameDescription')),
      tin: string()
        .matches(/^[0-9]{9}$/, t('validation.taxIdError'))
        .required(t('validation.taxIdError')),
      tinType: string().required(),
      address: addressFieldsSchema(t),
      phoneNumber: string()
        .min(10, t('validation.business.phone'))
        .required(t('validation.business.phone'))
    })
  })

export const dateFieldSchema = (t: (key: string) => string) =>
  string()
    .required('Please enter a valid date')
    .matches(
      /^(0[1-9]|1[0-2])(0[1-9]|1\d|2\d|3[01])(19|20)\d{2}$/,
      t('validation.dateFormatError')
    )
    .test('date-test', t('validation.dateFormatError'), (value) =>
      validateDayAgainstMonth(value)
    )

export const OwnershipInformationSchema = (t: (key: string) => string) =>
  object().shape({
    owner: object().shape({
      firstName: string().required(t('validation.person.first')),
      lastName: string().required(t('validation.person.last')),
      ssn: string()
        .matches(/^[0-9]{9}$/, t('validation.person.ssn'))
        .required(t('validation.person.ssn')),
      address: addressFieldsSchema(t),
      dob: dateFieldSchema(t),
      phoneNumber: string()
        .min(10, t('validation.person.phone'))
        .required(t('validation.person.phone')),
      email: string()
        .email(t('validation.person.email.valid'))
        .required(t('validation.person.email.required')),
      ownershipStake: number()
        .min(1, t('validation.person.ownership.between'))
        .max(100, t('validation.person.ownership.between'))
        .integer(t('validation.person.ownership.integer'))
        .required(t('validation.person.ownership.between'))
    })
  })

const ReviewSchema = (t: (key: string) => string) =>
  object().shape({
    owner: object().shape({
      disclosureCheckbox: bool().oneOf([true])
    }),
    contact: object().shape({
      isGuarantor: bool(),
      firstName: string().when('isGuarantor', {
        is: false,
        then: string().required(t('validation.person.first'))
      }),
      lastName: string().when('isGuarantor', {
        is: false,
        then: string().required(t('validation.person.last'))
      }),
      phoneNumber: string().when('isGuarantor', {
        is: false,
        then: string()
          .min(10, t('validation.person.phone'))
          .required(t('validation.person.phone'))
      }),
      email: string().when('isGuarantor', {
        is: false,
        then: string()
          .email(t('validation.person.email.valid'))
          .required(t('validation.person.email.required'))
      })
    })
  })

export const useValidationSchema = (stepId: FinancingSteps) => {
  const { t } = useTranslation()
  switch (stepId) {
    case FinancingSteps.RESTAURANT_INFO:
      return RestaurantInformationSchema(t)
    case FinancingSteps.BUSINESS_INFO:
      return BusinessInformationSchema(t)
    case FinancingSteps.GUARANTOR_INFO:
      return OwnershipInformationSchema(t)
    case FinancingSteps.REVIEW_TERMS:
      return ReviewSchema(t)
    default:
      return undefined
  }
}
