import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { Image } from '@toasttab/buffet-pui-image'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { QuoteIcon } from './QuoteIcon'

interface Props {
  className?: string
  imageSrc: string
  quote: string
  name: string
  url: string
}

export const TestimonialCard = ({
  className,
  imageSrc,
  quote,
  name,
  url
}: Props): ReactElement => {
  const { t } = useTranslation()

  return (
    <CardContainer noPadding className={cx('overflow-hidden', className)}>
      <div className='relative'>
        <Image
          src={imageSrc}
          alt='testimonial image'
          fit='contain'
          position='center'
        />
        <QuoteIcon
          className='absolute left-[2rem] bottom-[-1.25rem]'
          aria-label='quote icon'
        />
      </div>
      <div className='p-8 mt-4'>
        <p className='type-large mb-4'>{quote}</p>
        <p className='text-secondary type-default mb-8'>{name}</p>
        <a
          className='text-link font-semibold type-default'
          href={url}
          target='_blank'
          rel='noreferrer'
        >
          {t('prospectLoans.marketingPage.testimonials.viewCaseStudy')}
        </a>
      </div>
    </CardContainer>
  )
}
