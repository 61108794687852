import React, { ReactElement } from 'react'
import { PageAside } from '@toasttab/buffet-pui-wizard-templates'
import { Disclaimer } from './Disclaimer'

export const DisclaimerAside = (): ReactElement => {
  return (
    <PageAside>
      <Disclaimer className='mt-auto' />
    </PageAside>
  )
}
