import { object, string } from 'yup'

const zipCodeError = 'Please enter a valid 5 digit zip code'

export const addressFieldsSchema = object().shape({
  street1: string().required('Please enter a valid US address'),
  street2: string().nullable(),
  city: string().required('Please enter a city'),
  state: string().required('Please enter a state'),
  zip: string()
    .matches(/^[0-9]{5}$/, zipCodeError)
    .required(zipCodeError)
})
