import React, { ReactElement } from 'react'
import useClassnames from '@local/use-classnames'
import { BarContainer } from './BarContainer'
import { BarContainerNoSales } from './BarContainerNoSales'
import graphSectionStyles from './GraphSection.module.css'

export const BarSection = (): ReactElement => {
  const cx = useClassnames(graphSectionStyles)

  return (
    <div className={cx('bar-section', 'h-72 flex border-b-2 border-gray-500')}>
      <BarContainer top={3.56} bottom={59.28} payment={231} sales={3854} />
      <BarContainerNoSales />
      <BarContainer top={2.98} bottom={54.61} payment={213} sales={3550} />
      <BarContainer top={3.31} bottom={55.22} payment={215} sales={3589} />
      <BarContainer top={3.95} bottom={65.84} payment={256} sales={4280} />
      <BarContainer top={4.8} bottom={80.3} payment={312} sales={5220} />
      <BarContainer top={5.43} bottom={90.6} payment={353} sales={5890} />
    </div>
  )
}
