import { string } from 'yup'
import { validateDayAgainstMonth } from './dateFieldUtils'

const dateFormatError = 'Please enter a date in the format mm/dd/yyyy'

export const dateFieldSchema = string()
  .required('Please enter a valid date')
  .matches(
    /^(0[1-9]|1[0-2])(0[1-9]|1\d|2\d|3[01])(19|20)\d{2}$/,
    dateFormatError
  )
  .test('date-test', dateFormatError, (value) => validateDayAgainstMonth(value))
