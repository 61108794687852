import React, { ReactElement } from 'react'
import { useFlowNavigation } from '@local/use-flow-navigation'
import { FinancingWelcome } from './Welcome'
import { Formik } from 'formik'
import { RestaurantInfo } from './RestaurantInfo'
import { BusinessInfo } from './BusinessInfo'
import { Page, PageContainer } from '@toasttab/buffet-pui-wizard-templates'
import ToastLogo from '../../src/components/ToastLogo'
import { PersonalGuarantor } from './PersonalGuarantor'
import { ReviewTerms } from './ReviewTerms'
import {
  FinancingApplicationForm,
  FinancingStepProps,
  FinancingSteps
} from './Types'
import { useValidationSchema } from './validation'
import { ThankYou } from './ThankYou'
import { Submitting } from './Submiting'
import { Error } from './Error'
import FinancingApi from '../../src/api/Financing'
import { useParams } from 'react-router-dom'
import { formatRequestBody } from './helpers'

export const Financing = (): ReactElement => {
  const { dealNumber } = useParams<{ dealNumber: string }>()
  const { StepComponent, currentStepId, goToStep } =
    useFlowNavigation<FinancingStepProps>({
      steps: [
        {
          id: FinancingSteps.WELCOME,
          component: FinancingWelcome
        },
        {
          id: FinancingSteps.RESTAURANT_INFO,
          component: RestaurantInfo
        },
        {
          id: FinancingSteps.BUSINESS_INFO,
          component: BusinessInfo
        },
        {
          id: FinancingSteps.GUARANTOR_INFO,
          component: PersonalGuarantor
        },
        {
          id: FinancingSteps.REVIEW_TERMS,
          component: ReviewTerms
        },
        {
          id: FinancingSteps.SUBMITTING,
          component: Submitting
        },
        {
          id: FinancingSteps.THANK_YOU,
          component: ThankYou
        },
        {
          id: FinancingSteps.ERROR,
          component: Error
        }
      ]
    })
  const validationSchema = useValidationSchema(currentStepId as FinancingSteps)
  return (
    <Formik<FinancingApplicationForm>
      isInitialValid={false}
      validationSchema={validationSchema}
      initialValues={
        {
          contact: {
            isGuarantor: true
          },
          owner: {
            disclosureCheckbox: false
          }
        } as FinancingApplicationForm
      }
      onSubmit={async (values) => {
        goToStep(FinancingSteps.SUBMITTING)
        try {
          await FinancingApi.submitApplication(
            dealNumber,
            formatRequestBody(values)
          )
          goToStep(FinancingSteps.THANK_YOU)
        } catch (e) {
          goToStep(FinancingSteps.ERROR)
        }
      }}
    >
      {({ isValid, validateForm }) => (
        <Page>
          <div className='absolute pt-3 pl-4 md:pt-8 md:pl-12 z-50'>
            <ToastLogo />
          </div>
          <PageContainer>
            <StepComponent isValid={isValid} validateForm={validateForm} />
          </PageContainer>
        </Page>
      )}
    </Formik>
  )
}
