import React from 'react'
import {
  TelephoneField,
  TextInputField,
  SelectField
} from '@toasttab/buffet-pui-forms'
import { AddressFields } from '@local/address-fields'
import { InfoButton } from '../../src/components/InfoButton/InfoButton'
import { LeadingZerosNumberInputField } from '@local/leading-zeros-number-input-field'
import { useFormikContext } from 'formik'
import { TIN_FIELD_CONFIG, TIN_TYPES } from '@local/tin-field-config'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

interface Props {
  className?: string
  hidePhoneField?: boolean
  showRequiredLabel?: boolean
}

export const RestaurantLegalInfoFields = ({
  className,
  hidePhoneField,
  showRequiredLabel = false
}: Props) => {
  const { values } = useFormikContext<{ merchant: { tinType: string } }>()
  const { t } = useTranslation()
  return (
    <div className={cx('grid grid-cols-2 gap-y-4 gap-x-8', className)}>
      <TextInputField
        required={showRequiredLabel}
        className='fs-mask'
        containerClassName='col-span-2'
        autoComplete='false'
        name='merchant.name'
        label={
          <div className='flex items-center'>
            <p className='mr-2'>{t('common.business.fields.name.label')}</p>
            <InfoButton
              infoTextParagraph1={t('common.business.fields.name.tooltip')}
            />
          </div>
        }
        data-testid={'merchant-name'}
        helperText={t('common.business.fields.name.helperText')}
        placeholder={t('common.business.fields.name.placeholder')}
      />

      <label className='font-medium text-default type-default -mb-2'>
        {showRequiredLabel && <span className='text-error'>*&nbsp;</span>}
        {t('common.business.fields.businessAddressLabel')}
      </label>

      <AddressFields
        addressLine1FieldName='merchant.address.street1'
        addressLine2FieldName='merchant.address.street2'
        addressLine1FieldLabel={t('common.business.fields.address1.label')}
        addressLine2FieldLabel={t('common.business.fields.address2.label')}
        cityFieldName='merchant.address.city'
        stateFieldName='merchant.address.state'
        zipCodeFieldName='merchant.address.zip'
        dataTestIdPrefix='business'
        hideLabels
      />

      {!hidePhoneField && (
        <TelephoneField
          className='fs-mask'
          containerClassName='col-span-2 md:col-span-1 md:col-start-1'
          autoComplete='false'
          name='merchant.phoneNumber'
          label={t('common.business.fields.phone.label')}
          data-testid={'business-phone'}
        />
      )}

      <div className='col-span-2 md:col-span-1 md:col-start-1'>
        <label
          className='flex w-full mb-1 max-h-6 items-center type-default font-semibold text-default'
          htmlFor='tin-select-field'
        >
          <span className='mr-2'>
            {showRequiredLabel && <span className='text-error'>*&nbsp;</span>}
            {t('common.business.fields.tinType.label')}
          </span>
          <InfoButton
            infoTextParagraph1={t('common.business.fields.tinType.popup')}
          />
        </label>
        <SelectField
          placement='top'
          className='fs-mask'
          id='tin-select-field'
          testId='tinType'
          options={Object.values(TIN_TYPES).map((type) => ({
            value: type,
            label: type
          }))}
          name='merchant.tinType'
          aria-label='tinType'
        />
      </div>

      <LeadingZerosNumberInputField
        required={showRequiredLabel}
        className='fs-mask'
        containerClassName='col-span-2 md:col-span-1 '
        autoComplete='false'
        name='merchant.tin'
        label={t('common.business.fields.tin.label')}
        format={TIN_FIELD_CONFIG[values?.merchant?.tinType]?.format}
        mask='_'
        placeholder={TIN_FIELD_CONFIG[values?.merchant?.tinType]?.placeholder}
        data-testid={'business-tin'}
      />
    </div>
  )
}
