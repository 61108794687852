import React, { ReactElement } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import i18n from 'i18next'

export const Disclaimers = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <div data-testid='disclaimers-section' className='mt-12 pb-12'>
      <p className='text-secondary type-caption'>
        <Trans
          i18nKey='prospectLoans.marketingPage.disclaimers.webBankDisclaimer'
          t={t}
          i18n={(i18n as any).i18next}
        >
          <sup>0</sup>
        </Trans>
      </p>
      <p className='text-secondary type-caption mt-4'>
        <Trans
          i18nKey='prospectLoans.marketingPage.disclaimers.repaymentDisclaimer'
          t={t}
          i18n={(i18n as any).i18next}
        >
          <sup>0</sup>
        </Trans>
      </p>
      <p className='text-secondary type-caption mt-4'>
        {t('prospectLoans.marketingPage.disclaimers.approvedDisclaimer')}
      </p>
    </div>
  )
}
