import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { PageAside } from '@toasttab/buffet-pui-wizard-templates'

export const LegalDisclaimerAside = (): ReactElement => {
  const { t } = useTranslation()
  return (
    <PageAside>
      <p className='text-secondary type-caption mt-auto'>
        {t('prospectLoans.shared.legalDisclaimer')}
      </p>
    </PageAside>
  )
}
