import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export const EasyPayThankYouMessage = (): ReactElement => {
  const { t } = useTranslation()
  return (
    <>
      <div className='mt-28 text-center'>
        <div className='type-headline-2 font-bold mb-6'>
          {t('easypay.thankYou.title')}
        </div>
        <div className='type-large mb-4'>{t('easypay.thankYou.body')}</div>
      </div>
    </>
  )
}
