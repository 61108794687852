import React, { ReactElement } from 'react'
import { TelephoneField, TextInputField } from '@toasttab/buffet-pui-forms'
import { useTranslation } from 'react-i18next'

export const PersonalInfoFields = (): ReactElement => {
  const { t } = useTranslation()
  return (
    <>
      <p className='col-span-2 text-secondary'>Enter your information.</p>
      <TextInputField
        className='fs-mask'
        autoComplete='false'
        name='contact.firstName'
        label={t('financing.review.fields.first.label')}
        data-testid='contact-first-name'
        containerClassName='col-span-2 md:col-span-1'
      />

      <TextInputField
        className='fs-mask'
        autoComplete='false'
        name='contact.lastName'
        label={t('financing.review.fields.last.label')}
        data-testid='contact-last-name'
        containerClassName='col-span-2 md:col-span-1'
      />

      <TelephoneField
        className='fs-mask'
        autoComplete='false'
        name='contact.phoneNumber'
        label={t('financing.review.fields.phone.label')}
        data-testid='contact-phone'
        containerClassName='col-span-2 md:col-span-1'
      />

      <TextInputField
        className='fs-mask'
        autoComplete='false'
        name='contact.email'
        label={t('financing.review.fields.email.label')}
        data-testid='contact-email'
        type='email'
        containerClassName='col-span-2 md:col-span-1'
      />
    </>
  )
}
