export const financingTranslations = {
  financing: {
    welcome: {
      title: 'Welcome to Toast Financing',
      subtitle:
        'Only current or prospective Toast customers that are purchasing new hardware which has not yet shipped are eligible for financing. If you are unsure of whether your hardware has shipped, please contact your sales rep before completing this credit application.'
    },
    businessValidationModal: {
      title: "We're Sorry",
      body: "We couldn't verify your business information, but that's okay! Check what you've entered and correct any mistakes you might have made. If everything appears to be accurate, just click the \"Next\" button again to continue with your application."
    },
    guarantorValidationModal: {
      title: "We're Sorry",
      body: "We couldn't verify your ownership information, but that's okay! Check what you've entered and correct any mistakes you might have made. If everything appears to be accurate, just click the \"Next\" button again to continue with your application."
    },
    personalGuarantor: {
      title: 'Who is your personal guarantor?',
      subtitle:
        'Most transactions require a personal guarantor. Please provide the owner who can serve as the personal guarantor. If you are providing information on behalf of another person, you must have already obtained that person’s written approval to submit the information in connection with this credit application, and be able to provide Toast with a copy of that written authorization upon request.',
      fields: {
        first: {
          label: 'First Name',
          error: 'Please enter a first name'
        },
        last: {
          label: 'Last Name',
          error: 'Please enter a last name'
        },
        address1: {
          label: 'Home Address Line 1',
          error: 'Please enter a valid US address'
        },
        address2: {
          label: 'Home Address Line 2 (Optional)'
        },
        city: {
          label: 'City',
          error: 'Please enter a city'
        },
        state: {
          label: 'State'
        },
        zip: {
          label: 'Zip',
          error: 'Please enter a valid 5 digit zip code'
        },
        phone: {
          label: 'Phone',
          error: 'Please enter a 10 digit restaurant phone number'
        },
        email: {
          label: 'E-mail',
          error: 'Please enter an e-mail'
        },
        dob: {
          label: 'Date of Birth',
          error: 'Please enter a valid date'
        },
        ssn: {
          label: 'SSN/ITIN',
          popup:
            'This is for verifying credit. If you do not have a Social Security Number (SSN), you can enter an Individual Tax Identification Number (ITIN).',
          error: 'Please enter a valid 9 digit SSN/ITIN'
        },
        ownership: {
          label: '% Ownership',
          error: 'Must be between 1-100%'
        },
        isGuarantor: 'I am the personal guarantor entered above.'
      }
    },
    review: {
      title: 'Review and submit.',
      fields: {
        title: 'Enter your information.',
        first: {
          label: 'First Name',
          error: 'Please enter a first name'
        },
        last: {
          label: 'Last Name',
          error: 'Please enter a last name'
        },
        phone: {
          label: 'Phone',
          error: 'Please enter a 10 digit restaurant phone number'
        },
        email: {
          label: 'E-mail',
          error: 'Please enter an E-mail'
        }
      },
      attestations: {
        title: 'Attestations',
        checkbox: 'By checking this box:',
        one: 'I certify that I am an owner of my restaurant, or, if not, I have been authorized by an owner to apply for credit on my restaurant’s behalf.',
        two: 'I certify that all of the information provided in this application for credit, and any information that I provide to the lender, is true, accurate, and complete.',
        three:
          'I understand that Toast is not a lender and authorize Toast to share the information that I have provided in this application with one of Toast’s lender partners, which will be disclosed to me after I apply. I further understand that this application is subject to credit approval by the lender and that the lender may charge my restaurant an application fee.',
        four: 'If I have entered information on behalf of a personal guarantor, I certify that I have obtained written authorization from the personal guarantor to provide that individual’s information in connection with this credit application, and that I can provide this written authorization upon request.'
      }
    },
    thankYou: {
      title: 'Thank you for completing your application!',
      body: 'Our team will be in touch shortly with next steps.'
    },
    disclaimer:
      "Financing is provided by Toast's third-party lender partners, each of which has its own credit criteria. The lender will be disclosed to you when you receive notification of its decision. All applications are subject to approval by the applicable lender and the lender may discontinue offering financing at any time. The lender receiveing your application may charge a one-time fee, no greater than $95."
  }
}
