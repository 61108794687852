import React from 'react'

export const displayAddress = (address) => {
  let { street, city, state, zip } = address
  street = street ? street : 'Unknown street'
  city = city ? city : 'Unknown city'
  state = state ? state : 'Unknown state'
  zip = zip ? zip : 'Unknown zip code'

  return `${street} ${city}, ${state} ${zip}`
}

export const convertArrayToSelectOptions = (array) =>
  array.map((el) => (
    <option key={el} value={el}>
      {el}
    </option>
  ))

const capitalizeWords = (phrase) => {
  return phrase.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const convertConstsArrayToHumanReadableSelectOptions = (array) => {
  return array.map((el) => {
    const x = capitalizeWords(el.split('_').join(' '))
    return (
      <option key={el} value={el}>
        {x}
      </option>
    )
  })
}

export const DEAL_NUMBER_LENGTH = 6

// note that react router still will allow capital letters with this regex
export const DEAL_NUMBER_REGEX = `[a-z0-9-]{${DEAL_NUMBER_LENGTH}}`

export const FINANCING_METHODS = {
  LEASE: 'lease',
  LOAN: 'loan'
}

export const TIN_TYPES = {
  EIN: 'EIN',
  SSN: 'SSN',
  ITIN: 'ITIN'
}

export const TIN_FIELD_CONFIG = {
  EIN: {
    format: '##-#######',
    placeholder: '__-_______'
  },
  SSN: {
    format: '###-##-####',
    placeholder: '___-__-____'
  },
  ITIN: {
    format: '###-##-####',
    placeholder: '___-__-____'
  }
}
