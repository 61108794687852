import React from 'react'
import { TelephoneField, TextInputField } from '@toasttab/buffet-pui-forms'
import { AddressFields } from '@local/address-fields'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { InfoButton } from '../../src/components/InfoButton/InfoButton'

interface Props {
  className?: string
  showRequiredLabel?: boolean
}

export const RestaurantInfoFields = ({
  className,
  showRequiredLabel = false
}: Props) => {
  const { t } = useTranslation()
  return (
    <div className={cx('grid grid-cols-2 gap-y-4 gap-x-8', className)}>
      <TextInputField
        required={showRequiredLabel}
        className='fs-mask'
        containerClassName='col-span-2'
        autoComplete='false'
        name='restaurant.name'
        label={
          <div className='flex items-center'>
            <p className='mr-2'>{t('common.restaurant.fields.name.label')}</p>
            <InfoButton
              infoTextParagraph1={t('common.restaurant.fields.name.tooltip')}
            />
          </div>
        }
        placeholder={t('common.restaurant.fields.placeholder')}
        helperText={t('common.restaurant.fields.helperText')}
        data-testid='restaurant-name'
      />

      <label className='font-medium text-default type-default -mb-2'>
        {showRequiredLabel && <span className='text-error'>*&nbsp;</span>}
        {t('common.restaurant.fields.restaurantAddressLabel')}
      </label>

      <AddressFields
        addressLine1FieldName='restaurant.address.street1'
        addressLine2FieldName='restaurant.address.street2'
        cityFieldName='restaurant.address.city'
        stateFieldName='restaurant.address.state'
        zipCodeFieldName='restaurant.address.zip'
        addressLine1FieldLabel={t('common.restaurant.fields.address1.label')}
        addressLine2FieldLabel={t('common.restaurant.fields.address2.label')}
        dataTestIdPrefix='restaurant'
        hideLabels
      />

      <TelephoneField
        required={showRequiredLabel}
        className='fs-mask'
        containerClassName='col-span-2 md:col-span-1 md:col-start-1'
        autoComplete='false'
        name='restaurant.phoneNumber'
        label={t('common.restaurant.fields.phone.label')}
        data-testid={'restaurant-phone'}
      />

      <TextInputField
        className='fs-mask'
        containerClassName='col-span-2'
        autoComplete='false'
        name='restaurant.website'
        label={t('common.restaurant.fields.website.label')}
        data-testid={'restaurant-website'}
        placeholder='https://'
      />
    </div>
  )
}
