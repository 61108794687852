import { hot } from 'react-hot-loader'
import React from 'react'
import { HashRouter as Router, Redirect, Switch, Route } from 'react-router-dom'
import Welcome from '../components/Welcome'
import FinancingApplication from '../components/FinancingApplication'
import { DEAL_NUMBER_REGEX, FINANCING_METHODS } from '../utils/utils'
import { Financing } from '@local/financing'
import EasyPay from '@local/easy-pay'
import {
  ProspectLoans,
  ProspectLoanApplicationStatus,
  MarketingPage
} from '@local/prospect-loans'
import { useFlags } from '@toasttab/banquet-feature-flags'

const dealNumberRegex = `(${DEAL_NUMBER_REGEX})`

const App = () => {
  const { pmtsToastCapitalFinancingWizardUi } = useFlags()

  return (
    <Router>
      <Switch>
        {pmtsToastCapitalFinancingWizardUi && (
          <Route path={`/financing/:dealNumber${dealNumberRegex}?/`}>
            <Financing />
          </Route>
        )}
        {pmtsToastCapitalFinancingWizardUi && (
          <Route path={`/easypay/:dealNumber${dealNumberRegex}?/`}>
            <EasyPay />
          </Route>
        )}
        <Route path={`/welcome-loan/how-it-works`}>
          <MarketingPage />
        </Route>
        <Route path={`/welcome-loan/:dealNumber${dealNumberRegex}?/`}>
          <ProspectLoans />
        </Route>
        <Route path={`/welcome-loan-status/:dealNumber${dealNumberRegex}?/`}>
          <ProspectLoanApplicationStatus />
        </Route>
        <Route exact path='/easypay'>
          <Welcome financingMethod={FINANCING_METHODS.LEASE} />
        </Route>
        <Route exact path={`/easypay/:number${dealNumberRegex}`}>
          <Welcome financingMethod={FINANCING_METHODS.LEASE} />
        </Route>
        <Route exact path={`/easypay/:number${dealNumberRegex}/apply`}>
          <FinancingApplication financingMethod={FINANCING_METHODS.LEASE} />
        </Route>
        <Route exact path='/financing'>
          <Welcome financingMethod={FINANCING_METHODS.LOAN} />
        </Route>
        <Route exact path={`/financing/:number${dealNumberRegex}`}>
          <Welcome financingMethod={FINANCING_METHODS.LOAN} />
        </Route>
        <Route exact path={`/financing/:number${dealNumberRegex}/apply`}>
          <FinancingApplication financingMethod={FINANCING_METHODS.LOAN} />
        </Route>
        {/* any unmatched route with 'financing' should route to financing, otherwise go to easypay */}
        <Redirect from='(.*financing.*)' to='/financing' />
        <Redirect from='*' to='/easypay' />
      </Switch>
    </Router>
  )
}

export default hot(module)(App)
