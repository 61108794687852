import React, { useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import {
  PageBody,
  PageMain,
  ContentFooter,
  ContinueButton,
  BackButton
} from '@toasttab/buffet-pui-wizard-templates'
import { FlowProps } from '@local/use-flow-navigation'
import {
  ProspectLoanFormValues,
  ProspectLoanStepProps,
  ProspectLoanSteps
} from '../types'
import { PageTitle, LegalDisclaimerAside } from '../shared'
import { ReviewSection } from './ReviewSection'
import { ReviewField } from './ReviewField'
import { RestaurantInfoReview } from './RestaurantInfoReview'
import { BusinessInfoReview } from './BusinessInfoReview'
import { OwnershipReview } from './OwnershipReview'
import { FinancesReview } from './FinancesReview'
import { ContactForm } from './ContactForm'
import { CustomerAttestations } from './CustomerAttestations'

export const Review = ({
  isValid,
  validateForm,
  goToPreviousStep,
  goToStep
}: FlowProps<ProspectLoanStepProps>): ReactElement => {
  const { t } = useTranslation()
  const { values, submitForm } = useFormikContext<ProspectLoanFormValues>()

  useEffect(() => {
    validateForm()
  }, [validateForm])

  return (
    <PageBody testId='prospect-loans-review-page'>
      <PageMain>
        <PageTitle
          title={t('prospectLoans.review.title')}
          description={t('prospectLoans.review.description')}
        />
        <ReviewSection
          onEditClick={() => goToStep(ProspectLoanSteps.LOAN_USE)}
          hideBorder
        >
          <ReviewField
            label={t('prospectLoans.review.purposeOfLoan')}
            testId='loanUseCase'
          >
            {values.loanUseCase}
          </ReviewField>
        </ReviewSection>
        <RestaurantInfoReview values={values} goToStep={goToStep} />
        <BusinessInfoReview values={values} goToStep={goToStep} />
        <OwnershipReview owners={values.owners} goToStep={goToStep} />
        <FinancesReview values={values} goToStep={goToStep} />
        <ContactForm />
        <CustomerAttestations />
        <ContentFooter className='mt-6 py-6 bg-white'>
          <div className='flex justify-end w-full'>
            <BackButton onClick={goToPreviousStep} />
            <ContinueButton
              onClick={submitForm}
              disabled={!isValid}
              className='mr-6'
              testId='review'
            >
              {t('prospectLoans.review.submit')}
            </ContinueButton>
          </div>
        </ContentFooter>
      </PageMain>
      <LegalDisclaimerAside />
    </PageBody>
  )
}
