import { RestaurantLegalInfoFields } from '@local/restaurant-legal-info-fields'
import {
  BackButton,
  ContentFooter,
  ContinueButton,
  PageBody,
  PageMain
} from '@toasttab/buffet-pui-wizard-templates'
import React, { ReactElement, useEffect, useState } from 'react'
import FinancingApi from '../../src/api/Financing'
import { useFormikContext } from 'formik'
import { FinancingApplicationForm, FinancingStepProps } from './Types'
import { useMutation } from 'react-query'
import InvalidInfoModal from '../../src/components/InvalidInfoModal'
import { FlowProps } from '@local/use-flow-navigation'
import { scrollToTop } from './helpers'
import { useParams } from 'react-router-dom'
import { DisclaimerAside } from './DisclaimerAside'
import { useTranslation } from 'react-i18next'

export const BusinessInfo = ({
  isValid,
  validateForm,
  goToNextStep,
  goToPreviousStep
}: FlowProps<FinancingStepProps>): ReactElement => {
  const { t } = useTranslation()
  const { values } = useFormikContext<FinancingApplicationForm>()
  const { dealNumber } = useParams<{ dealNumber: string }>()
  const [isValidationWarningModalOpen, setIsValidationWarningModalOpen] =
    useState(false)
  const {
    mutate: validateBusinessInfo,
    failureCount,
    isLoading
  } = useMutation(
    () =>
      FinancingApi.validateBusinessInfo(dealNumber, {
        name: values.merchant.name,
        tin: values.merchant.tin
      }),
    {
      onSuccess: goToNextStep,
      onError: () => setIsValidationWarningModalOpen(true)
    }
  )

  useEffect(() => {
    validateForm()
    scrollToTop()
  }, [validateForm])

  const onSubmit = async () => {
    // if failureCount > 0, we already tried to validate and failed
    if (failureCount > 0) {
      goToNextStep()
    } else {
      validateBusinessInfo()
    }
  }
  return (
    <>
      <InvalidInfoModal
        buttonText={t('common.next')}
        infoType='business'
        isOpen={isValidationWarningModalOpen}
        setIsOpen={setIsValidationWarningModalOpen}
      />
      <PageBody testId='business-info-page' className='pb-10'>
        <PageMain containerClassName='pb-10'>
          <h1 className='type-headline-4 md:type-headline-2 font-bold text-default mb-3'>
            {t('common.business.title')}
          </h1>
          <h2 className='type-headline-3 mb-4'>
            {t('common.business.fields.title')}
          </h2>
          <RestaurantLegalInfoFields className='mb-6' />
          <ContentFooter className='py-6 bg-white'>
            <div className='flex justify-end w-full'>
              <BackButton title={t('common.back')} onClick={goToPreviousStep} />
              <ContinueButton
                testId='business-info'
                onClick={onSubmit}
                disabled={isLoading || !isValid}
                className='mr-6'
              >
                {isLoading ? t('common.validating') : t('common.next')}
              </ContinueButton>
            </div>
          </ContentFooter>
        </PageMain>
        <DisclaimerAside />
      </PageBody>
    </>
  )
}
