import React, { ReactElement } from 'react'
import { BarSection } from './BarSection'
import { LabelSection } from './LabelSection'
import { Legend } from './Legend'

export const GraphSection = (): ReactElement => {
  return (
    <div className='relative px-4 mx-auto max-w-[45.625rem]'>
      <BarSection />
      <LabelSection />
      <Legend />
    </div>
  )
}
