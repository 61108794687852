import React, { ReactElement } from 'react'
import { APPLICATION_STAGE, ProspectLoanApplication } from '../types'
import { OfferDeclined } from './OfferDeclined'
import { OfferExpired } from './OfferExpired'
import { OfferApproved } from './OfferApproved'
import { GoToToastWeb } from './GoToToastWeb'
import { OfferPending } from './OfferPending'

interface Props {
  application: ProspectLoanApplication
}

export const ApplicationStatusCard = ({ application }: Props): ReactElement => {
  switch (application.stage) {
    case APPLICATION_STAGE.FUNDING_REQUESTED:
    case APPLICATION_STAGE.FINAL_OFFER_MANUAL_REVIEW:
    case APPLICATION_STAGE.DOES_NOT_QUALIFY_MANUAL_REVIEW:
      return <OfferPending />
    case APPLICATION_STAGE.DOES_NOT_QUALIFY:
    case APPLICATION_STAGE.OFFER_DECLINED:
      return <OfferDeclined />
    case APPLICATION_STAGE.OFFER_EXPIRED:
      return <OfferExpired />
    case APPLICATION_STAGE.PREAPPROVED:
    case APPLICATION_STAGE.PREAPPROVED_NOT_PROCESSING:
      return <OfferApproved application={application} />
    default:
      return <GoToToastWeb />
  }
}
