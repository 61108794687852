import React, { ReactElement, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import i18n from 'i18next'
import {
  PageBody,
  PageMain,
  ContentFooter,
  ContinueButton,
  BackButton
} from '@toasttab/buffet-pui-wizard-templates'
import { Badge } from '@toasttab/buffet-pui-badge'
import { Button } from '@toasttab/buffet-pui-buttons'
import { ArrowForwardIcon } from '@toasttab/buffet-pui-icons'
import { FlowProps } from '@local/use-flow-navigation'
import { ProspectLoanStepProps } from '../types'
import { PageTitle } from '../shared'
import { LinkAccountsAside } from './LinkAccountsAside'
import { CodatModal } from './CodatModal'

export const LinkAccounts = ({
  goToNextStep,
  goToPreviousStep,
  hasLinkedAccountsInCodat,
  onLinkAccountsInCodat,
  codatCompanyId
}: FlowProps<ProspectLoanStepProps>): ReactElement => {
  const { t } = useTranslation()
  const [isCodatModalOpen, setIsCodatModalOpen] = useState(false)

  return (
    <>
      <PageBody testId='prospect-loans-link-accounts-page'>
        <PageMain>
          <PageTitle
            title={t('prospectLoans.linkAccounts.title')}
            description={t('prospectLoans.linkAccounts.description')}
          />
          <div className='rounded mt-6 p-6 bg-gray-0'>
            {hasLinkedAccountsInCodat ? (
              <div className='w-full flex justify-between font-medium items-center'>
                <p className='text-secondary'>
                  {t('prospectLoans.linkAccounts.accountingAndBankingInfo')}
                </p>
                <Badge color='success' className='uppercase'>
                  {t('prospectLoans.linkAccounts.connected')}
                </Badge>
              </div>
            ) : (
              <div className='flex flex-col items-center'>
                <p className='text-secondary mb-4'>
                  {t('prospectLoans.linkAccounts.connectTheBankAccounts')}
                </p>
                <Button
                  testId='link-accounts-button'
                  onClick={() => setIsCodatModalOpen(true)}
                  variant='secondary'
                  iconRight={<ArrowForwardIcon accessibility='decorative' />}
                >
                  {t('prospectLoans.linkAccounts.linkBusinessAccounts')}
                </Button>
              </div>
            )}
          </div>
          <div className='mt-6 text-secondary type-subhead'>
            <p className='mb-4'>
              {t('prospectLoans.linkAccounts.toProvideAdditionalInfo')}
            </p>
            <p className='mb-4'>
              <Trans
                i18nKey='prospectLoans.linkAccounts.purpose'
                t={t}
                i18n={(i18n as any).i18next}
              >
                <span className='font-semibold'>0</span>
              </Trans>
            </p>
            <p className='mb-4'>{t('prospectLoans.linkAccounts.select')}</p>
            <p>{t('prospectLoans.linkAccounts.toastCustomer')}</p>
          </div>
          <ContentFooter className='mt-6 py-6 bg-white'>
            <div className='flex justify-end w-full'>
              <BackButton onClick={goToPreviousStep} />
              <ContinueButton
                onClick={goToNextStep}
                disabled={!hasLinkedAccountsInCodat}
                className='mr-6'
                testId='link-accounts'
              >
                {t('prospectLoans.shared.continue')}
              </ContinueButton>
            </div>
          </ContentFooter>
        </PageMain>
        <LinkAccountsAside />
      </PageBody>
      <CodatModal
        codatGuid={codatCompanyId as string}
        isOpen={isCodatModalOpen}
        onFinish={() => {
          setIsCodatModalOpen(false)
          onLinkAccountsInCodat()
        }}
        onClose={() => {
          setIsCodatModalOpen(false)
        }}
      />
    </>
  )
}
