import {
  Owner,
  OWNER_ROLE,
  OwnerPayload,
  ProspectLoanApplicationPayload,
  ProspectLoanFormValues
} from './types'
import { stringToBoolean } from './utils'

const calculateTotalDebt = (formValues: ProspectLoanFormValues): number => {
  if (formValues.hasNoDebt) {
    return 0
  }
  return formValues.debtList.reduce(
    (total, currentDebtItem) => total + currentDebtItem.debtAmount,
    0
  )
}

const convertOwnersToPayload = (owners: Owner[]): OwnerPayload[] => {
  return owners.map((owner) => ({
    firstName: owner.firstName,
    lastName: owner.lastName,
    address: { ...owner.address, country: 'US' },
    email: owner.email,
    phoneNumber: owner.phoneNumber.toString(),
    dob: owner.dob,
    ownershipStake: owner.ownershipStake,
    ssn: owner.ssn,
    jobTitle: owner.jobTitle,
    yearsAtAddress: owner.yearsAtAddress,
    isControlPerson: owner.role === OWNER_ROLE.CONTROL_PERSON,
    hasOwnerInformationAttestation: owner.attestation
  }))
}

export const convertFormValuesToRequestPayload = (
  formValues: ProspectLoanFormValues,
  dealNumber: string
): ProspectLoanApplicationPayload => {
  const isPropertyOwned = !stringToBoolean(formValues.isLeasing)

  return {
    bestContactTimes: formValues.contactTimes,
    contact: {
      email: formValues.contactEmail,
      fullName: formValues.contactName,
      phoneNumber: formValues.contactPhoneNumber.toString()
    },
    currentDebt: calculateTotalDebt(formValues),
    debts: formValues.hasNoDebt
      ? []
      : formValues.debtList.map((debt) => ({
          amount: debt.debtAmount,
          type: debt.debtType
        })),
    hasHistoryOfClosure: stringToBoolean(formValues.hasHistoryOfClosure),
    hasJudgementsOrLawsuits: stringToBoolean(
      formValues.hasJudgementsOrLawsuits
    ),
    hasOwnershipChanged: stringToBoolean(formValues.hasOwnershipChanged),
    hasPastBankruptcy: stringToBoolean(formValues.hasBankruptcy),
    hasPlannedChangeOfOwnership: stringToBoolean(
      formValues.hasPlannedChangeOfOwnership
    ),
    hasPlannedClosures: stringToBoolean(formValues.hasPlannedClosures),
    hasPlannedLocationChange: stringToBoolean(
      formValues.hasPlannedLocationChange
    ),
    hasTermsAndConditionsConsent: formValues.termsAndConditionsConsent,
    isPropertyOwned,
    leaseEndDate: formValues.leaseEndDate?.toString(),
    loanUseCase: formValues.loanUseCase,
    merchant: {
      address: { ...formValues.merchant.address, country: 'US' },
      name: formValues.merchant.name,
      tin: formValues.merchant.tin,
      tinType: formValues.merchant.tinType
    },
    owners: convertOwnersToPayload(formValues.owners),
    restaurant: {
      address: { ...formValues.restaurant.address, country: 'US' },
      name: formValues.restaurant.name,
      phoneNumber: formValues.restaurant.phoneNumber.toString()
    },
    socialScore: formValues.socialScore,
    taxFiling: {
      filingYear: formValues.filingYear,
      hasFiledLastYearsTaxes: stringToBoolean(
        formValues.hasFiledPriorYearTaxes
      ),
      lastYearsProfitMargin: formValues.netIncome
    },
    taxLien: stringToBoolean(formValues.hasTaxLiens)
      ? {
          amount: formValues.taxLienAmount,
          filingDate: formValues.taxLienFilingDate
        }
      : undefined,
    threeMonthTotalSales: formValues.threeMonthTotalSales,
    opportunityExternalId: dealNumber,
    legalEntity: formValues.ownershipType
  }
}
