import React from 'react'
import { PageBody, PageMain } from '@toasttab/buffet-pui-wizard-templates'
import { DisclaimerAside } from './DisclaimerAside'
import { useTranslation } from 'react-i18next'

export const ThankYou = () => {
  const { t } = useTranslation()
  return (
    <PageBody>
      <PageMain>
        <div className='mt-28 text-center'>
          <div
            className='type-headline-2 font-bold mb-6'
            data-testid='thank-you'
          >
            {t('financing.thankYou.title')}
          </div>
          <div className='type-large mb-4'>{t('financing.thankYou.body')}</div>
        </div>
      </PageMain>
      <DisclaimerAside />
    </PageBody>
  )
}
