import React from 'react'
import { NumberInputProps } from '@toasttab/buffet-pui-text-input'
import { LeadingZerosNumberInputField } from '@local/leading-zeros-number-input-field'

export const DateField = (props: Omit<NumberInputProps, 'onChange'>) => {
  return (
    <LeadingZerosNumberInputField
      className='fs-mask'
      autoComplete='false'
      format='##/##/####'
      mask={['m', 'm', 'd', 'd', 'y', 'y', 'y', 'y']}
      placeholder='mm/dd/yyyy'
      {...props}
    />
  )
}
