import React, { ReactElement } from 'react'
import flatten from 'lodash/flatten'
import { useTranslation } from 'react-i18next'
import { PageStepper } from '@toasttab/buffet-pui-wizard-templates'
import { BaseStepProps } from '@toasttab/buffet-pui-stepper'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { ProspectLoanSteps } from '../../types'
import { getDisplayStepState } from './getDisplayStepState'
import { DISPLAY_STEPS, displayStepsConfig } from './constants'

interface Props {
  currentStep: ProspectLoanSteps
}

export const FormStepper = ({ currentStep }: Props): ReactElement | null => {
  const { t } = useTranslation()
  const screenSize = useScreenSize()
  const isMobile = screenSize < ScreenSize.MD

  const steps: BaseStepProps[] = [
    {
      title: t('prospectLoans.steps.loanUse'),
      state: getDisplayStepState(currentStep, DISPLAY_STEPS.LOAN_USE)
    },
    {
      title: t('prospectLoans.steps.business'),
      state: getDisplayStepState(currentStep, DISPLAY_STEPS.BUSINESS)
    },
    {
      title: t('prospectLoans.steps.ownership'),
      state: getDisplayStepState(currentStep, DISPLAY_STEPS.OWNERSHIP)
    },
    {
      title: t('prospectLoans.steps.finances'),
      state: getDisplayStepState(currentStep, DISPLAY_STEPS.FINANCES)
    },
    {
      title: t('prospectLoans.steps.review'),
      state: getDisplayStepState(currentStep, DISPLAY_STEPS.REVIEW)
    }
  ]

  const stepsToDisplayPageStepper = flatten(Object.values(displayStepsConfig))
  const shouldDisplayPageStepper =
    stepsToDisplayPageStepper.includes(currentStep)

  return shouldDisplayPageStepper ? (
    <PageStepper
      steps={steps}
      type='number'
      orientation={isMobile ? 'horizontal-text-below' : 'horizontal-text-side'}
      size={isMobile ? 'sm' : 'default'}
    />
  ) : null
}
