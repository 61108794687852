import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import {
  TelephoneField,
  TextInputField,
  CheckboxGroupField
} from '@toasttab/buffet-pui-forms'

export const ContactForm = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <div className='pt-8'>
      <h4 className='type-headline-4 font-medium mb-6'>
        {t('prospectLoans.review.contactForm.title')}
      </h4>
      <p className='text-default type-default mb-6'>
        {t('prospectLoans.review.contactForm.description')}
      </p>
      <div className='grid grid-cols-2 gap-y-4 gap-x-8'>
        <TextInputField
          label={t('prospectLoans.review.contactForm.fields.contactName.label')}
          name='contactName'
          containerClassName='col-span-2 md:col-span-1'
          testId='contactName'
        />
        <TelephoneField
          className='fs-mask'
          containerClassName='col-span-2 md:col-span-1'
          autoComplete='false'
          name='contactPhoneNumber'
          label={t(
            'prospectLoans.review.contactForm.fields.contactPhoneNumber.label'
          )}
          data-testid='contactPhoneNumber'
        />
        <TextInputField
          label={t(
            'prospectLoans.review.contactForm.fields.contactEmail.label'
          )}
          name='contactEmail'
          containerClassName='col-span-2'
          testId='contactEmail'
        />
        <CheckboxGroupField
          label={t(
            'prospectLoans.review.contactForm.fields.contactTimes.label'
          )}
          subLabel={t(
            'prospectLoans.review.contactForm.fields.contactTimes.subLabel'
          )}
          name='contactTimes'
          className='col-span-2 mt-4'
          testId='contactTimes'
          options={[
            {
              value: 'Morning (9AM - 12PM ET)',
              label: t(
                'prospectLoans.review.contactForm.fields.contactTimes.morning'
              )
            },
            {
              value: 'Noon (12PM - 2PM ET)',
              label: t(
                'prospectLoans.review.contactForm.fields.contactTimes.noon'
              )
            },
            {
              value: 'Afternoon (2PM - 5PM ET)',
              label: t(
                'prospectLoans.review.contactForm.fields.contactTimes.afternoon'
              )
            }
          ]}
        />
      </div>
    </div>
  )
}
