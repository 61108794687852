export const validationTranslations = {
  validation: {
    merchantNameDescription:
      "Please provide the exact registered name including punctuation. For example 'ABC Company, LLC' not 'ABC Company LLC'",
    taxIdError:
      'Please enter the unique nine-digit number assigned by the Internal Revenue Service to identify your business entity.',
    dateFormatError: 'Please enter a date in the format mm/dd/yyyy',
    dateRequiredError: 'Please enter a valid date',
    address: {
      street: 'Please enter a valid US address',
      city: 'Please enter a city',
      state: 'Please enter a state',
      zip: 'Please enter a valid 5 digit zip code'
    },
    restaurant: {
      name: 'Please enter the name of your restaurant',
      phone: 'Please enter a 10 digit restaurant phone number'
    },
    business: {
      phone: 'Please enter a 10 digit business phone number'
    },
    person: {
      first: 'Please enter your first name',
      last: 'Please enter your last name',
      ssn: 'Please enter a valid 9 digit SSN/ITIN',
      phone: 'Please enter your 10 digit phone number',
      email: {
        valid: 'Not a valid email address',
        required: 'Please enter your email'
      },
      ownership: {
        between: 'Must be between 1-100%',
        integer: 'Needs to be a whole number, cannot contain a decimal point'
      }
    }
  }
}
