import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody } from '@toasttab/buffet-pui-modal'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

interface Props {
  isOpen: boolean
  isBusinessInfo?: boolean
}

export const ValidationLoadingModal = ({
  isOpen,
  isBusinessInfo = false
}: Props) => {
  const { t } = useTranslation()
  return (
    <Modal
      testId='validation-loading-modal'
      // @ts-ignore
      appElement={document.body}
      isOpen={isOpen}
    >
      <ModalBody>
        <p className='text-center type-headline-4 text-default'>
          {t(
            isBusinessInfo
              ? 'prospectLoans.businessInfo.validationLoading'
              : 'prospectLoans.ownership.validationLoading'
          )}
        </p>
        <div className='p-6 flex items-center justify-center'>
          <MerryGoRound size={MerryGoRound.Size.sm} />
        </div>
      </ModalBody>
    </Modal>
  )
}
