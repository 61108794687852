import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { CardSelectorGroupField } from '@toasttab/buffet-pui-forms'
import { OWNER_ROLE, Owner } from '../types'

interface Props {
  owners: Owner[]
}

export const OwnerRoleSelect = ({ owners }: Props): ReactElement => {
  const { t } = useTranslation()

  const currentIndex = owners.length - 1

  return (
    <CardSelectorGroupField
      name={`owners[${currentIndex}].role`}
      itemsContainerClassName='grid grid-cols-1 md:grid-cols-2 gap-2'
      options={[
        {
          value: OWNER_ROLE.OWNER,
          contents: (
            <div data-testid='owner-role-select' className='p-2'>
              <p className='font-medium text-default'>
                {t(
                  'prospectLoans.ownership.addOwnerModal.ownerRoleSection.owner.title'
                )}
              </p>
              <p className='text-secondary font-normal'>
                {t(
                  'prospectLoans.ownership.addOwnerModal.ownerRoleSection.owner.description'
                )}
              </p>
            </div>
          )
        },
        {
          value: OWNER_ROLE.CONTROL_PERSON,
          contents: (
            <div data-testid='control-person-role-select' className='p-2'>
              <p className='font-medium text-default'>
                {t(
                  'prospectLoans.ownership.addOwnerModal.ownerRoleSection.controlPerson.title'
                )}
              </p>
              <p className='text-secondary font-normal'>
                {t(
                  'prospectLoans.ownership.addOwnerModal.ownerRoleSection.controlPerson.description'
                )}
              </p>
            </div>
          )
        }
      ]}
    />
  )
}
