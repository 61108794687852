import {
  RestaurantIcon,
  MaintenanceIcon,
  ForkKnifeIcon,
  FullScreenIcon,
  GroupIcon,
  BankRecurringIcon,
  CostControlIcon,
  MoneyBagIcon
} from '@toasttab/buffet-pui-icons'

export const LOAN_USE_OPTIONS = [
  { value: 'New location', key: 'newLocation', icon: RestaurantIcon },
  { value: 'Renovations', key: 'renovations', icon: MaintenanceIcon },
  {
    value: 'New kitchen equipment',
    key: 'kitchenEquipment',
    icon: ForkKnifeIcon
  },
  {
    value: 'Expansion of existing space',
    key: 'expansion',
    icon: FullScreenIcon
  },
  { value: 'Pay my employees', key: 'employees', icon: GroupIcon },
  { value: 'Refinance my debt', key: 'debt', icon: BankRecurringIcon },
  { value: 'Operational expenses', key: 'operations', icon: CostControlIcon },
  { value: 'Short term cash flow needs', key: 'cashFlow', icon: MoneyBagIcon }
]
