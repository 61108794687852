export const createDebtTypes = (t: (key: string) => string) => [
  {
    label: t('prospectLoans.finances.businessDebt.debtTypes.creditCard'),
    value: 'CREDIT_CARD'
  },
  {
    label: t('prospectLoans.finances.businessDebt.debtTypes.termLoan'),
    value: 'TERM_LOAN'
  },
  {
    label: t('prospectLoans.finances.businessDebt.debtTypes.mortgage'),
    value: 'MORTGAGE'
  },
  {
    label: t('prospectLoans.finances.businessDebt.debtTypes.mca'),
    value: 'MCA'
  },
  {
    label: t('prospectLoans.finances.businessDebt.debtTypes.equipmentLease'),
    value: 'EQUIPMENT_LEASE'
  },
  {
    label: t('prospectLoans.finances.businessDebt.debtTypes.other'),
    value: 'OTHER'
  }
]
