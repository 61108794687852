import React, { ReactElement, useState } from 'react'
import { Formik } from 'formik'
import { useMutation } from 'react-query'
import { Page, PageContainer } from '@toasttab/buffet-pui-wizard-templates'
import { useFlowNavigation } from '@local/use-flow-navigation'
import { ToastLogoLink } from '@toasttab/buffet-pui-toast-logo'
import {
  ProspectLoanSteps,
  ProspectLoanStepProps,
  ProspectLoanFormValues
} from './types'
import { FormStepper, PageError } from './shared'
import { useValidationSchema } from './validation'
import { Welcome } from './Welcome'
import { LoanUse } from './LoanUse'
import { RestaurantInfo } from './RestaurantInfo'
import { BusinessInfo } from './BusinessInfo'
import { Ownership } from './Ownership'
import { Finances } from './Finances'
import { LinkAccounts } from './LinkAccounts'
import { Review } from './Review'
import { Submitting, Submitted, Error } from './SubmitStatus'
import { useSubmitProspectLoanApplication } from './useSubmitProspectLoanApplication'
import FinancingApi from '../../src/api/Financing'
import {
  useTrackProspectLoanApplication,
  useTrackApplicationProgress
} from './Analytics'
import PreventNavigateAway from './PreventNavigateAway'

export const ProspectLoans = (): ReactElement => {
  const { StepComponent, currentStepId, goToStep } =
    useFlowNavigation<ProspectLoanStepProps>({
      steps: [
        {
          id: ProspectLoanSteps.WELCOME,
          component: Welcome
        },
        {
          id: ProspectLoanSteps.LOAN_USE,
          component: LoanUse
        },
        {
          id: ProspectLoanSteps.RESTAURANT_INFO,
          component: RestaurantInfo
        },
        {
          id: ProspectLoanSteps.BUSINESS_INFO,
          component: BusinessInfo
        },
        {
          id: ProspectLoanSteps.OWNERSHIP,
          component: Ownership
        },
        {
          id: ProspectLoanSteps.FINANCES,
          component: Finances
        },
        {
          id: ProspectLoanSteps.LINK_ACCOUNTS,
          component: LinkAccounts
        },
        {
          id: ProspectLoanSteps.REVIEW_TERMS,
          component: Review
        },
        {
          id: ProspectLoanSteps.SUBMITTING,
          component: Submitting
        },
        {
          id: ProspectLoanSteps.SUBMITTED,
          component: Submitted
        },
        {
          id: ProspectLoanSteps.ERROR,
          component: Error
        }
      ]
    })

  const { trackApplicationSubmitted } = useTrackProspectLoanApplication()

  useTrackApplicationProgress(currentStepId)

  const validationSchema = useValidationSchema(
    currentStepId as ProspectLoanSteps
  )

  const [pageError, setPageError] = useState<string>()

  const [hasLinkedAccountsInCodat, setHasLinkedAccountsInCodat] =
    useState(false)

  const submitProspectLoanApplication =
    useSubmitProspectLoanApplication(goToStep)

  const { mutate: getCodatCompany, data: codatResponse } = useMutation(
    'get-codat-company',
    (dealNumber: string) => FinancingApi.getCodatCompany(dealNumber)
  )

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          hasFiledPriorYearTaxes: '',
          debtList: [{ debtType: '', debtAmount: '' }],
          hasNoDebt: false,
          termsAndConditionsConsent: false
        }}
        onSubmit={(values) => {
          goToStep(ProspectLoanSteps.SUBMITTING)

          submitProspectLoanApplication(
            values as unknown as ProspectLoanFormValues
          )
          trackApplicationSubmitted()
        }}
      >
        {({ isValid, validateForm }) => (
          <Page>
            <div className='absolute z-50 w-full border-b'>
              <ToastLogoLink href='/' size='lg' />
            </div>
            <PageContainer>
              <FormStepper currentStep={currentStepId as ProspectLoanSteps} />
              {pageError && (
                <PageError
                  errorMessage={pageError}
                  onDismiss={() => setPageError(undefined)}
                />
              )}
              <StepComponent
                isValid={isValid}
                validateForm={validateForm}
                setPageError={setPageError}
                hasLinkedAccountsInCodat={hasLinkedAccountsInCodat}
                onLinkAccountsInCodat={() => setHasLinkedAccountsInCodat(true)}
                getCodatCompany={getCodatCompany}
                codatCompanyId={codatResponse?.data?.companyId}
              />
            </PageContainer>
          </Page>
        )}
      </Formik>
      <PreventNavigateAway currentStepId={currentStepId} />
    </>
  )
}
