import React, { ReactElement } from 'react'
import { HelpDisplayIcon } from '@toasttab/buffet-pui-icons'
import { PageAside } from '@toasttab/buffet-pui-wizard-templates'
import { t } from 'i18next'

export const EasyPayAside = (): ReactElement => {
  return (
    <PageAside>
      <div className='flex items-center mb-6'>
        <HelpDisplayIcon size='lg' className='mr-4' />
        <div>
          <p className='type-caption'>{t('easypay.faq.title')}</p>
          <a
            className='text-link font-semibold type-default hover:underline'
            target='_blank'
            href='https://central.toasttab.com/s/article/Toast-Easy-Pay-FAQ'
            rel='noreferrer'
          >
            {t('easypay.faq.link')}
          </a>
        </div>
      </div>
      <div className='mt-auto type-caption text-gray-75 leading-4'>
        {t('easypay.disclosure')}
        <a
          className='font-bold pl-1 hover:underline'
          href='https://pos.toasttab.com/privacy'
          target='_blank'
          rel='noreferrer'
        >
          {t('common.privacy')}
        </a>
      </div>
    </PageAside>
  )
}
