import React, { ReactElement } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

interface Props {
  className?: string
}

export const Disclaimer = ({ className }: Props): ReactElement => {
  const { t } = useTranslation()
  return (
    <div className={cx('type-caption text-gray-75 leading-4', className)}>
      {t('financing.disclaimer')}
      <a
        className='font-bold pl-1 hover:underline'
        href='https://pos.toasttab.com/privacy'
        target='_blank'
        rel='noreferrer'
      >
        {t('common.privacy')}
      </a>
    </div>
  )
}
