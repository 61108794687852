import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useBanquetProps } from 'banquet-runtime-modules'
import { Currency, formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import useClassnames from '@local/use-classnames'
import { Tooltip } from './Tooltip'
import graphSectionStyles from './GraphSection.module.css'

interface Props {
  sales: number
  payment: number
}

export const BarTooltip = ({ sales, payment }: Props): ReactElement => {
  return (
    <Tooltip
      placement='right'
      className='h-full w-full'
      variant='arrowLight'
      content={() =>
        sales > 0 ? (
          <PaymentBreakdown sales={sales} payment={payment} />
        ) : (
          <NoPayments />
        )
      }
    >
      {false}
    </Tooltip>
  )
}

const PaymentBreakdown = ({ sales, payment }: Props) => {
  const cx = useClassnames(graphSectionStyles)
  const { t } = useTranslation()
  const { i18n } = useBanquetProps()
  const language = i18n?.language ?? 'en-US'

  return (
    <div className='p-3'>
      <div className='px-5'>
        <p className='font-semibold text-secondary type-overline uppercase'>
          {t('prospectLoans.marketingPage.graphSection.graphLabels.payments')}
        </p>
        <p className={cx('orange', 'font-semibold')}>${payment}</p>
      </div>
      <div className='my-2 border-b-2 border-gray-500' />
      <div className='px-5'>
        <p className='font-semibold text-secondary type-overline uppercase'>
          {t('prospectLoans.marketingPage.graphSection.graphLabels.sales')}
        </p>
        <p className='type-subhead font-semibold'>
          {formatCurrency({ amount: sales, currency: Currency.USD }, language, {
            precision: 0
          })}
        </p>
      </div>
    </div>
  )
}

const NoPayments = () => {
  const { t } = useTranslation()

  return (
    <div className='p-3'>
      <img
        alt='closed_for_business_sign'
        src='https://d2w1ef2ao9g8r9.cloudfront.net/images/svg/icons/closed-icon.svg'
      ></img>
      <p className='font-semibold text-secondary type-overline uppercase'>
        {t('prospectLoans.marketingPage.graphSection.graphLabels.noPayments')}
      </p>
    </div>
  )
}
