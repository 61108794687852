import React from 'react'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { InfoIcon } from '@toasttab/buffet-pui-icons'
import classNames from 'classnames/bind'
import styles from './InfoButton.module.css'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'
import TooltipContent from './TooltipContent'
const cx = classNames.bind(styles)

export const InfoButton = ({ infoTextParagraph1, infoTextParagraph2 = '' }) => {
  return (
    <div className={cx('info-button-container')}>
      <div className={cx('info-button-icon')}>
        <Tooltip
          content={() => (
            <TooltipContent
              infoTextParagraph1={infoTextParagraph1}
              infoTextParagraph2={infoTextParagraph2}
            />
          )}
        >
          <IconButton cropToIcon icon={<InfoIcon />} />
        </Tooltip>
      </div>
    </div>
  )
}
