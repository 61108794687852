import React, { useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import {
  PageBody,
  PageMain,
  ContentFooter,
  ContinueButton,
  BackButton
} from '@toasttab/buffet-pui-wizard-templates'
import { CardSelectorGroupField } from '@toasttab/buffet-pui-forms'
import { FlowProps } from '@local/use-flow-navigation'
import { ProspectLoanStepProps } from '../types'
import { LOAN_USE_OPTIONS } from './constants'
import { PageTitle } from '../shared'

export const LoanUse = ({
  isValid,
  validateForm,
  goToNextStep,
  goToPreviousStep
}: FlowProps<ProspectLoanStepProps>): ReactElement => {
  const { t } = useTranslation()

  useEffect(() => {
    validateForm()
  }, [validateForm])

  return (
    <PageBody testId='prospect-loans-loan-use-page'>
      <PageMain>
        <PageTitle
          title={t('prospectLoans.loanUse.title')}
          description={t('prospectLoans.loanUse.description')}
        />
        <div className='mt-4'>
          <CardSelectorGroupField
            name='loanUseCase'
            itemsContainerClassName='grid grid-cols-1 md:grid-cols-2 gap-2'
            options={LOAN_USE_OPTIONS.map((option) => ({
              value: option.value,
              contents: (
                <div
                  key={option.key}
                  className='flex items-center px-6 py-2'
                  data-testid={`loan-use-option-${option.key}`}
                >
                  <option.icon
                    size='md'
                    className='text-secondary mr-4'
                    accessibility='decorative'
                  />
                  <p className='text-secondary'>
                    {t(`prospectLoans.loanUse.options.${option.key}`)}
                  </p>
                </div>
              )
            }))}
          />
        </div>
        <ContentFooter className='mt-6 py-6 bg-white'>
          <div className='flex justify-end w-full'>
            <BackButton onClick={goToPreviousStep} />
            <ContinueButton
              onClick={goToNextStep}
              disabled={!isValid}
              className='mr-6'
              testId='loan-use'
            >
              {t('prospectLoans.shared.continue')}
            </ContinueButton>
          </div>
        </ContentFooter>
      </PageMain>
    </PageBody>
  )
}
