import { TFunction } from 'i18next'

export const stringToBoolean = (value?: string) => {
  return value === 'true'
}

export const booleanToYesNo = (value: boolean, t: TFunction) => {
  return value ? t('prospectLoans.shared.yes') : t('prospectLoans.shared.no')
}

export const convertIntToDate = (yyyymmdd: number) => {
  const dateString = yyyymmdd.toString()
  const year = parseInt(dateString.substring(0, 4))
  const month = parseInt(dateString.substring(4, 6))
  const day = parseInt(dateString.substring(6, 8))
  return new Date(year, month - 1, day)
}
