import React, { ReactElement } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import i18n from 'i18next'
import { CheckboxField } from '@toasttab/buffet-pui-forms'

export const CustomerAttestations = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <div className='pt-8'>
      <h4 className='type-headline-4 font-medium mb-6'>
        {t(
          'prospectLoans.review.contactForm.fields.termsAndConditionsConsent.title'
        )}
      </h4>
      <p className='text-default type-default font-semibold mb-4'>
        {t(
          'prospectLoans.review.contactForm.fields.termsAndConditionsConsent.description'
        )}
      </p>
      <p className='text-default type-default mb-2'>
        {t(
          'prospectLoans.review.contactForm.fields.termsAndConditionsConsent.promise'
        )}
      </p>
      <p className='text-default type-default mb-2'>
        {t(
          'prospectLoans.review.contactForm.fields.termsAndConditionsConsent.certify'
        )}
      </p>
      <p className='text-default type-default mb-2'>
        {t(
          'prospectLoans.review.contactForm.fields.termsAndConditionsConsent.agreeToBeContacted'
        )}
      </p>
      <p className='text-default type-default mb-2'>
        <Trans
          i18n={i18n}
          t={t}
          i18nKey='prospectLoans.review.contactForm.fields.termsAndConditionsConsent.acknowledge'
        >
          <a
            href='https://pos.toasttab.com/terms-of-use'
            target='_blank'
            rel='noreferrer'
            className='text-link underline'
          >
            0
          </a>
          <a
            href='https://pos.toasttab.com/privacy'
            target='_blank'
            rel='noreferrer'
            className='text-link underline'
          >
            1
          </a>
        </Trans>
      </p>
      <p className='text-default type-default mb-2'>
        {t(
          'prospectLoans.review.contactForm.fields.termsAndConditionsConsent.agreeThatTheBusiness'
        )}
      </p>
      <CheckboxField
        name='termsAndConditionsConsent'
        label={t(
          'prospectLoans.review.contactForm.fields.termsAndConditionsConsent.checkboxLabel'
        )}
        testId='termsAndConditionsConsent'
      />
    </div>
  )
}
