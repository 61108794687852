const currentYear = new Date().getFullYear()

export const thisYear = {
  label: currentYear.toString(),
  value: currentYear.toString()
}
export const lastYear = {
  label: (currentYear - 1).toString(),
  value: (currentYear - 1).toString()
}

export const projectedFilingYearOptions = [thisYear, lastYear]
export const actualFilingYearOptions = [lastYear]
