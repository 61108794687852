import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { ReviewSection } from './ReviewSection'
import { ReviewField } from './ReviewField'
import { formatAddress } from './utils'
import { ProspectLoanFormValues, ProspectLoanSteps } from '../types'

interface Props {
  values: ProspectLoanFormValues
  goToStep(step: ProspectLoanSteps): void
}

export const BusinessInfoReview = ({
  values,
  goToStep
}: Props): ReactElement => {
  const { t } = useTranslation()

  const { merchant, ownershipType } = values

  return (
    <ReviewSection
      title={t('prospectLoans.review.businessLegalInformation')}
      onEditClick={() => goToStep(ProspectLoanSteps.BUSINESS_INFO)}
    >
      <ReviewField
        label={t('common.business.fields.name.label')}
        testId='merchantName'
      >
        {merchant.name}
      </ReviewField>
      <ReviewField
        label={t('common.business.fields.businessAddressLabel')}
        testId='merchantAddress'
      >
        {formatAddress(merchant.address)}
      </ReviewField>
      <ReviewField
        label={t('prospectLoans.businessInfo.fields.ownershipType.label')}
        testId='ownershipType'
      >
        {t(
          `prospectLoans.businessInfo.fields.ownershipType.options.${ownershipType}`
        )}
      </ReviewField>
    </ReviewSection>
  )
}
