import { formatDateYyyyMmDd } from '../../src/components/DateField/dateFieldUtils'
import { FinanceApplicationPayload, FinancingApplicationForm } from './Types'

export const scrollToTop = () => {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'
  }
  window.scrollTo(0, 0)
}

export const formatRequestBody = (
  values: FinancingApplicationForm
): FinanceApplicationPayload => ({
  applicationType: 'loan',
  contact: {
    ...(!values.contact.isGuarantor
      ? (values.contact as FinanceApplicationPayload['contact'])
      : {
          firstName: values.owner.firstName,
          lastName: values.owner.lastName,
          phoneNumber: values.owner.phoneNumber,
          email: values.owner.email
        })
  },
  merchant: {
    ...values.merchant,
    address: {
      ...values.merchant.address,
      country: 'US'
    }
  },
  restaurant: {
    ...values.restaurant,
    address: {
      ...values.restaurant.address,
      country: 'US'
    }
  },
  owner: {
    ...values.owner,
    dob: formatDateYyyyMmDd(values.owner.dob),
    address: {
      ...values.owner.address,
      country: 'US'
    }
  }
})
