import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import useClassnames from '@local/use-classnames'
import graphSectionStyles from './GraphSection.module.css'

export const LabelSection = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <div className='flex border-b-2 border-gray-700'>
      <Label>
        {t('prospectLoans.marketingPage.graphSection.graphLabels.sunday')}
      </Label>
      <Label>
        {t('prospectLoans.marketingPage.graphSection.graphLabels.monday')}
      </Label>
      <Label>
        {t('prospectLoans.marketingPage.graphSection.graphLabels.tuesday')}
      </Label>
      <Label>
        {t('prospectLoans.marketingPage.graphSection.graphLabels.wednesday')}
      </Label>
      <Label>
        {t('prospectLoans.marketingPage.graphSection.graphLabels.thursday')}
      </Label>
      <Label>
        {t('prospectLoans.marketingPage.graphSection.graphLabels.friday')}
      </Label>
      <Label>
        {t('prospectLoans.marketingPage.graphSection.graphLabels.saturday')}
      </Label>
    </div>
  )
}

interface LabelProps {
  children: string
}

const Label = ({ children }: LabelProps) => {
  const cx = useClassnames(graphSectionStyles)
  return (
    <div
      className={cx(
        'label',
        'flex flex-1 m-2 text-center type-caption justify-center items-center rotate-90 md:rotate-0 h-20 md:h-5'
      )}
    >
      {children}
    </div>
  )
}
