import React from 'react'
import { createPortal } from 'react-dom'
import { useClickAway } from 'react-use'
import { usePopper } from 'react-popper'
import cx from 'classnames'
import { Arrow } from '@toasttab/buffet-pui-dropdown-arrow'
import { useUniqueId } from '@toasttab/buffet-utils'

import type { Placement } from '@popperjs/core'
import type { Modifier } from 'react-popper'

type Variants = 'dark' | 'arrowLight'

interface TooltipProps {
  children: React.ReactNode
  /** Render function to render tooltip content */
  content: () => React.ReactNode
  /** Unique id applied to the tooltip to describe the given content for accessibility purposes. */
  id?: string
  className?: string
  /** On mobile, how long the tooltip will be shown before disappearing, in milliseconds. Defaults to `5000`. */
  mobileTimeout?: number
  /** Popper2 modifiers to be added */
  modifiers?: ReadonlyArray<Modifier<any>>
  /** Popper2 placement options */
  placement?: Placement
  /** Element of which we want to render the tooltip inside */
  portalContainer?: Element
  /** Id used in tests, applied to the tooltip */
  testId?: string
  /** Theme used for the tooltip */
  variant?: Variants
}

const variants: { [key in Variants]: Variants } = {
  dark: 'dark',
  arrowLight: 'arrowLight'
}

const arrowLight =
  'z-10 p-4 overflow-auto type-subhead bg-white border-none rounded shadow focus:outline-none'
const dark =
  'px-4 py-2 overflow-auto type-subhead text-white border rounded bg-gray-125'

export const Tooltip = ({
  className,
  children,
  content,
  id,
  portalContainer,
  testId,
  mobileTimeout = 5000,
  modifiers = [],
  placement = 'auto',
  variant = 'dark'
}: TooltipProps) => {
  const tooltipId = useUniqueId(id, 'tooltip-')
  testId = useUniqueId(testId, 'text-input-')

  const [popperRef, setPopperRef] = React.useState<HTMLDivElement | null>(null)
  const [referenceElement, setReferenceElement] =
    React.useState<HTMLDivElement | null>(null)
  const [arrowElement, setArrowElement] = React.useState<HTMLDivElement | null>(
    null
  )
  const [isOpen, isOpenSet] = React.useState(false)
  const resolvedPortalContainer = portalContainer || referenceElement

  const { styles, attributes, state } = usePopper(referenceElement, popperRef, {
    modifiers: [
      {
        name: 'arrow',
        options: { element: arrowElement, padding: { left: 208 } }
      },
      ...modifiers
    ],
    placement: placement
  })

  const clickAwayRef = React.useRef<HTMLDivElement | null>(null)
  useClickAway(clickAwayRef, () => {
    isOpenSet(false)
  })

  const openOnTouch = React.useCallback(() => {
    if (isOpen) return
    isOpenSet(true)
    setTimeout(() => isOpenSet(false), mobileTimeout)
  }, [isOpen, mobileTimeout])

  return (
    <div className='contents' ref={clickAwayRef}>
      <div
        ref={setReferenceElement}
        onMouseOver={() => isOpenSet(true)}
        onMouseLeave={() => isOpenSet(false)}
        onTouchStart={openOnTouch}
        className={className}
        aria-describedby={tooltipId}
      >
        {children}
      </div>

      {resolvedPortalContainer &&
        createPortal(
          <div
            ref={setPopperRef}
            role='tooltip'
            id={tooltipId}
            style={styles.popper}
            aria-hidden={!isOpen}
            data-testid={testId}
            {...attributes.popper}
            className={cx(
              'inline-block z-50',
              isOpen ? 'visible' : 'invisible',
              variant === 'arrowLight' ? 'p-4' : 'p-2'
            )}
          >
            {state?.placement && variant === 'arrowLight' && (
              <Arrow
                ref={setArrowElement}
                style={styles.arrow}
                placement={state?.placement}
                // TODO: These props can be removed after upgrading to React 18 types
                nonce={undefined}
                onResize={undefined}
                onResizeCapture={undefined}
                placeholder={undefined}
              />
            )}
            <div className={variant === 'arrowLight' ? arrowLight : dark}>
              {content()}
            </div>
          </div>,
          resolvedPortalContainer
        )}
    </div>
  )
}

Tooltip.Variant = Object.freeze(variants)
