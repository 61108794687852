import React from 'react'
import classNames from 'classnames/bind'
import styles from './Error.module.css'
const cx = classNames.bind(styles)

export const Error = ({ isLease }) => {
  const email = isLease
    ? 'toastlease@toasttab.com'
    : 'toastfinancing@toasttab.com'

  return (
    <div className={cx('error-centered-div')}>
      <div className={cx('type-headline-2 font-bold', 'error-header')}>
        Oops, something went wrong!
      </div>
      <div className='type-large'>Please get in touch with us at {email}.</div>
    </div>
  )
}
