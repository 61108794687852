import React from 'react'

const ToastLogo = () => {
  return (
    <a href='/'>
      <img
        className='w-24 sm:w-32'
        src='https://cdn.toasttab.com/static/19af6b673996d663903a4cf872baa27dbc0e8f9d/images/toast_logo.png'
        alt='toast logo'
      />
    </a>
  )
}

export default ToastLogo
