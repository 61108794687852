export const commonTranslations = {
  common: {
    back: 'Back',
    next: 'Next',
    submit: 'Submit Application',
    validating: 'Validating...',
    processing: 'Your application is being processed...',
    privacy: 'Toast Privacy Statement',
    error: {
      title: 'Oops, something went wrong!',
      body: 'Please get in touch with us at'
    },
    welcome: {
      apply: {
        title: 'To apply, you’ll need to provide:',
        legalInfo: 'Restaurant Legal Information',
        contact: 'Contact Information',
        dealNumber: {
          label: 'Toast Deal Number',
          helperText: 'Unique 6 digit ID number Toast emailed you',
          error:
            'Please enter the unique 6 digit ID number that was shared with you by your Toast Sales Representative.'
        }
      },
      next: "Let's do it!"
    },
    restaurant: {
      title: 'Tell us about your restaurant.',
      subtitle:
        'Please fill out all required fields below. The restaurant location address below must be the location where your financed equipment will reside.',
      fields: {
        title: 'Restaurant Location Information',
        placeholder: 'Enter your restaurant’s name',
        helperText: 'Example: Mallory’s Cafe',
        name: {
          label: 'Restaurant Name (DBA)',
          error: 'Please enter the name of your restaurant',
          tooltip: `DBA stands for “Doing Business As” and is your business's assumed, trade or fictitious name`
        },
        restaurantAddressLabel: 'Restaurant Address',
        address1: {
          label: 'Address Line 1',
          helperText: 'Physical address of your restaurant',
          error: 'Please enter a valid US address'
        },
        address2: {
          label: 'Address Line 2 (Optional)'
        },
        city: {
          label: 'City',
          error: 'Please enter a city'
        },
        state: {
          label: 'State'
        },
        zip: {
          label: 'Zip code',
          error: 'Please enter a valid 5 digit zip code'
        },
        phone: {
          label: 'Phone number',
          error: 'Please enter a 10 digit restaurant phone number'
        },
        website: {
          label: 'Restaurant Website'
        }
      }
    },
    business: {
      title: 'Tell us about your business.',
      fields: {
        title: 'Business Legal Information',
        name: {
          label: 'Registered Legal Entity Name',
          placeholder: 'Enter your legal entity name',
          helperText: 'Example: Mallory’s Cafe LLC',
          error:
            "Please provide the exact registered name including punctuation. For example 'ABC Company, LLC' not 'ABC Company LLC'",
          tooltip:
            'A “legal entity name” is the name that appears on the formation document of a corporation, LLC, LP or other statutory business entity'
        },
        businessAddressLabel: 'Legal entity address',
        address1: {
          label: 'Address Line 1',
          helperText: 'Address of the registered legal entity',
          error: 'Please enter a valid US address'
        },
        address2: {
          label: 'Address Line 2 (Optional)'
        },
        city: {
          label: 'City',
          error: 'Please enter a city'
        },
        state: {
          label: 'State'
        },
        zip: {
          label: 'Zip',
          error: 'Please enter a valid 5 digit zip code'
        },
        phone: {
          label: 'Phone',
          error: 'Please enter a 10 digit restaurant phone number'
        },
        tinType: {
          label: 'Federal Tax ID Type',
          popup:
            'Most businesses have a federal Employer Identification Number (EIN), assigned by the IRS. If you are operating as a sole proprietor, you may have a different type of federal tax ID, such as a Social Security Number (SSN) or Individual Tax Identification Number (ITIN).'
        },
        tin: {
          label: 'Federal Tax ID number',
          error: 'Please enter a valid 9 digit Federal Tax ID'
        }
      }
    },
    review: {
      submit: 'Submit Application',
      attestations: {
        one: 'I acknowledge that I have read and agree to the ',
        two: 'Terms of Use',
        three: ' and the '
      }
    }
  }
}
