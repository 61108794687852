import React, { StrictMode } from 'react'
import * as FullStory from '@fullstory/browser'
import { QueryClientProvider, QueryClient } from 'react-query'
// @ts-ignore
import envFromHostname from '@toasttab/env-from-hostname'
import { BanquetFeatureFlagsProvider } from '@toasttab/banquet-feature-flags'
import ErrorBoundary from '../components/ErrorBoundary'
import App from './App'
import '@local/i18n'

FullStory.init({
  devMode: envFromHostname(window.location.hostname) !== 'prod',
  orgId: 'ZQEDZ'
})

const AppProvider = () => {
  const queryClient = new QueryClient()

  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary>
          <BanquetFeatureFlagsProvider
            launchDarklyClientSideIDs={{
              dev: '643edd8b7fb30512d01e9f7a',
              prod: '643edd1c67be3b12d67ac482',
              preprod: '643edd2aebc34313d0aa0fde',
              sandbox: '643edd83ad7e1812d3072bf7'
            }}
          >
            <App />
          </BanquetFeatureFlagsProvider>
        </ErrorBoundary>
      </QueryClientProvider>
    </StrictMode>
  )
}

export default AppProvider
