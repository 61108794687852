import React from 'react'
import { useTranslation } from 'react-i18next'

export const DisclosureList = ({ isLoan }) => {
  const { t } = useTranslation()
  return (
    <div className='type-default text-gray-100 ml-8 mr-4 mt-4 mb-10'>
      <ul className='list-disc'>
        {isLoan ? (
          <>
            <li>{t('financing.review.attestations.one')}</li>
            <li>{t('financing.review.attestations.two')}</li>
            <li>{t('financing.review.attestations.three')}</li>
            <li>{t('financing.review.attestations.four')}</li>
          </>
        ) : (
          <>
            <li>{t('easypay.review.attestations.one')}</li>
            <li>{t('easypay.review.attestations.two')}</li>
            <li>{t('easypay.review.attestations.three')}</li>
            <li>{t('easypay.review.attestations.four')}</li>
            <li>{t('easypay.review.attestations.five')}</li>
          </>
        )}
        <li>
          {t('common.review.attestations.one')}
          <a
            className='font-bold hover:underline'
            href='https://pos.toasttab.com/terms-of-use'
            // eslint-disable-next-line react/jsx-no-target-blank
            target='_blank'
            rel='noreferrer'
          >
            {t('common.review.attestations.two')}
          </a>
          {t('common.review.attestations.three')}
          <a
            className='font-bold hover:underline'
            href='https://pos.toasttab.com/privacy'
            // eslint-disable-next-line react/jsx-no-target-blank
            target='_blank'
            rel='noreferrer'
          >
            {t('common.privacy')}
          </a>
          .
        </li>
      </ul>
    </div>
  )
}
