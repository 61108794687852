import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { TestimonialCard } from './TestimonialCard'
import testimonialImage1 from '../assets/testimonial-1.png'
import testimonialImage2 from '../assets/testimonial-2.png'

export const Testimonials = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <div data-testid='testimonials-section' className='mt-14'>
      <h3 className='type-headline-3 text-center mb-6'>
        {t('prospectLoans.marketingPage.testimonials.title')}
      </h3>
      <div className='flex flex-col md:flex-row'>
        <TestimonialCard
          className='mr-4 w-full mb-6 md:mb-0 md:w-1/2'
          imageSrc={testimonialImage1}
          quote={t(
            'prospectLoans.marketingPage.testimonials.testimonial1.quote'
          )}
          name={t('prospectLoans.marketingPage.testimonials.testimonial1.name')}
          url='https://pos.toasttab.com/blog/jaybees-tenders'
        />
        <TestimonialCard
          className='w-full md:w-1/2'
          imageSrc={testimonialImage2}
          quote={t(
            'prospectLoans.marketingPage.testimonials.testimonial2.quote'
          )}
          name={t('prospectLoans.marketingPage.testimonials.testimonial2.name')}
          url='https://pos.toasttab.com/blog/the-crossing-steakhouse'
        />
      </div>
      <p className='text-secondary type-subhead text-center mt-6'>
        {t('prospectLoans.marketingPage.testimonials.disclaimer')}
      </p>
    </div>
  )
}
