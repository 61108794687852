import React, { ReactElement } from 'react'

interface Props {
  className?: string
}

export const QuoteIcon = ({ className }: Props): ReactElement => (
  <svg
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    width='46'
    height='46'
    fill='none'
    viewBox='0 0 46 46'
  >
    <circle
      cx='23'
      cy='23'
      r='21'
      fill='#FF4C00'
      stroke='#fff'
      strokeWidth='3'
    ></circle>
    <path
      fill='#fff'
      d='M18.929 15v1.271c-1.796.951-3.082 1.944-3.858 2.977-.775 1.034-1.163 2.16-1.163 3.38 0 .723.102 1.22.306 1.488.184.29.408.434.674.434.265 0 .622-.072 1.071-.217.45-.165.857-.248 1.225-.248.836 0 1.56.32 2.173.962.633.62.95 1.384.95 2.294 0 .992-.378 1.85-1.133 2.574-.756.703-1.694 1.054-2.817 1.054-1.367 0-2.602-.6-3.704-1.799C11.551 27.973 11 26.494 11 24.736c0-2.067.674-3.99 2.02-5.767 1.368-1.799 3.337-3.122 5.909-3.969zm14.602.093v1.178c-2.062 1.2-3.419 2.274-4.072 3.225-.653.951-.98 2.067-.98 3.349 0 .579.113 1.013.337 1.302.225.29.46.434.704.434.225 0 .562-.082 1.01-.248a3.876 3.876 0 011.348-.248c.836 0 1.56.31 2.173.93.633.6.949 1.344.949 2.233 0 1.013-.398 1.892-1.194 2.636-.775.744-1.735 1.116-2.877 1.116-1.347 0-2.562-.59-3.643-1.767-1.082-1.179-1.623-2.646-1.623-4.404 0-2.17.684-4.144 2.051-5.922 1.368-1.799 3.306-3.07 5.817-3.814z'
    ></path>
  </svg>
)
