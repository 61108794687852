import axios, { AxiosResponse } from 'axios'
// @ts-ignore
import envFromHostname from '@toasttab/env-from-hostname'
import {
  ProspectLoanApplicationPayload,
  ProspectLoanApplication,
  CodatCompany
} from '@local/prospect-loans'

class FinancingApi {
  static async validateBusinessInfo(dealNumber: string, body: object) {
    return axios.post(
      `${this.getDomain()}/financing/v1/financing/apply/validate/business?merchantExternalId=${dealNumber}`,
      body
    )
  }

  static async validateOwnershipInfo(dealNumber: string, body: object) {
    return axios.post(
      `${this.getDomain()}/financing/v1/financing/apply/validate/owner?merchantExternalId=${dealNumber}`,
      body
    )
  }

  static async submitApplication(dealNumber: string, body: object) {
    return axios.post(
      `${this.getDomain()}/financing/v1/financing/apply?merchantExternalId=${dealNumber}`,
      body
    )
  }

  static async submitProspectLoanApplication(
    body: ProspectLoanApplicationPayload
  ) {
    return axios.post(
      `${this.getDomain()}/financing/v1/welcome-loan/apply`,
      body
    )
  }

  static async getProspectLoanApplication(
    dealNumber: string
  ): Promise<AxiosResponse<ProspectLoanApplication>> {
    return axios.get(
      `${this.getDomain()}/financing/v1/welcome-loan/application/opportunity-external-id/${dealNumber}`
    )
  }

  static async getCodatCompany(
    dealNumber: string
  ): Promise<AxiosResponse<CodatCompany>> {
    return axios.post(
      `${this.getDomain()}/financing/v1/financing/apply/credit-info/location/opportunity-external-id`,
      { opportunityExternalId: dealNumber }
    )
  }

  static getDomain() {
    const env = envFromHostname(window.location.hostname)
    let domain
    if (env === 'dev') {
      domain = 'ws-dev.eng.toastteam.com'
    } else if (env === 'prod') {
      domain = 'ws-api.toasttab.com'
    } else {
      domain = 'ws-preprod-api.eng.toasttab.com'
    }

    return `https://${domain}`
  }
}

export default FinancingApi
