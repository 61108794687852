import React, { ReactElement } from 'react'
import { PageBody, PageMain } from '@toasttab/buffet-pui-wizard-templates'
import { DisclaimerAside } from './DisclaimerAside'
import { useTranslation } from 'react-i18next'

export const Error = (): ReactElement => {
  const { t } = useTranslation()
  return (
    <PageBody testId='error-page'>
      <PageMain>
        <div className='mt-28 text-center'>
          <div className='type-headline-2 font-bold mb-6'>
            {t('common.error.title')}
          </div>
          <div className='type-large mb-4'>
            {t('common.error.body')} toastfinancing@toasttab.com.
          </div>
        </div>
      </PageMain>
      <DisclaimerAside />
    </PageBody>
  )
}
