import React, { ReactElement } from 'react'
import { PageBody, PageMain } from '@toasttab/buffet-pui-wizard-templates'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { EasyPayAside } from './EasyPayAside'
import { useTranslation } from 'react-i18next'

export const Submitting = (): ReactElement => {
  const { t } = useTranslation()
  return (
    <PageBody>
      <PageMain>
        <div className='mt-28 text-center'>
          <h1 className='type-headline-2 mb-6'>{t('common.processing')}</h1>
          <div className='p-24 flex items-center justify-center'>
            <MerryGoRound size={MerryGoRound.Size.xl} />
          </div>
        </div>
      </PageMain>
      <EasyPayAside />
    </PageBody>
  )
}
