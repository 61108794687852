import React, { ReactElement } from 'react'
import { Alert } from '@toasttab/buffet-pui-alerts'

interface Props {
  errorMessage: string
  onDismiss(): void
}

export const PageError = ({ errorMessage, onDismiss }: Props): ReactElement => {
  return (
    <div className='mx-auto lg:mx-0 px-4 md:px-10 w-full -mb-4 md:-mb-10 py-8 md:py-6 md:mt-4'>
      <Alert
        onDismiss={onDismiss}
        showIcon
        variant='error'
        className='w-full !max-w-[868px]'
      >
        {errorMessage}
      </Alert>
    </div>
  )
}
