import {
  object,
  string,
  array,
  addMethod,
  number,
  mixed,
  boolean,
  bool
} from 'yup'
import { isYYYYMMDDNumberValid } from '@toasttab/buffet-pui-forms'
import get from 'lodash/get'
import { ProspectLoanSteps } from './types'
import { useTranslation } from 'react-i18next'

addMethod(array, 'unique', function (message, path, fieldName) {
  return this.test('unique', message, function (list = []) {
    // @ts-ignore
    const mapper = (x) => get(x, path)
    const set = [...new Set(list.map(mapper))]
    const isUnique = list.length === set.length
    if (isUnique) {
      return true
    }

    const idx = list.findIndex((l, i) => mapper(l) !== set[i])
    return this.createError({ path: `${fieldName}[${idx}].${path}`, message })
  })
})

addMethod(array, 'totalOwnershipStake', function (message) {
  return this.test('unique', message, function (owners = []) {
    const totalOwnershipStake = owners.reduce((total, currentOwner) => {
      total += currentOwner.ownershipStake ?? 0
      return total
    }, 0)

    if (totalOwnershipStake <= 100) {
      return true
    }

    return this.createError({
      path: `owners[${owners.length - 1}].ownershipStake`,
      message
    })
  })
})

const loanUseValidationSchema = () =>
  object().shape({
    loanUseCase: string().required()
  })

const addressFieldsSchema = (t: (key: string) => string) =>
  object().shape({
    street1: string().required(t('validation.address.street')),
    street2: string().nullable(),
    city: string().required(t('validation.address.city')),
    state: string().required(t('validation.address.state')),
    zip: string()
      .matches(/^[0-9]{5}$/, t('validation.address.zip'))
      .required(t('validation.address.zip'))
  })

const RestaurantInformationSchema = (t: (key: string) => string) =>
  object().shape({
    restaurant: object().shape({
      name: string().required(t('validation.restaurant.name')),
      address: addressFieldsSchema(t),
      website: string(),
      phoneNumber: string()
        .min(10, t('validation.restaurant.phone'))
        .required(t('validation.restaurant.phone'))
    }),
    socialScore: string().required()
  })

const BusinessInformationSchema = (t: (key: string) => string) =>
  object().shape({
    merchant: object().shape({
      name: string().required(t('validation.merchantNameDescription')),
      tin: string()
        .matches(/^[0-9]{9}$/, t('validation.taxIdError'))
        .required(t('validation.taxIdError')),
      tinType: string().required(),
      address: addressFieldsSchema(t)
    }),
    ownershipType: string().required()
  })

const OwnershipInformationSchema = (t: (key: string) => string) =>
  object().shape({
    owners: array()
      .of(
        object().shape({
          role: string().required(
            t('prospectLoans.ownership.fields.role.error')
          ),
          firstName: string().required(
            t('prospectLoans.ownership.fields.firstName.error')
          ),
          lastName: string().required(
            t('prospectLoans.ownership.fields.lastName.error')
          ),
          jobTitle: string().required(
            t('prospectLoans.ownership.fields.jobTitle.error')
          ),
          email: string()
            .email(t('prospectLoans.ownership.fields.email.error'))
            .required(t('prospectLoans.ownership.fields.email.error')),
          phoneNumber: string()
            .min(10, t('prospectLoans.ownership.fields.phoneNumber.error'))
            .required(t('prospectLoans.ownership.fields.phoneNumber.error')),
          dob: number()
            .required(t('prospectLoans.ownership.fields.dob.error'))
            .test(
              'isValidDate',
              t('prospectLoans.ownership.fields.dob.error'),
              isYYYYMMDDNumberValid
            ),
          ssn: string()
            .matches(
              /^[0-9]{9}$/,
              t('prospectLoans.ownership.fields.ssn.error')
            )
            .required(t('prospectLoans.ownership.fields.ssn.error')),
          ownershipStake: number()
            .min(
              25,
              t('prospectLoans.ownership.fields.ownershipStake.minError')
            )
            .max(
              100,
              t('prospectLoans.ownership.fields.ownershipStake.maxError')
            )
            .integer(
              t('prospectLoans.ownership.fields.ownershipStake.integerError')
            )
            .required(
              t('prospectLoans.ownership.fields.ownershipStake.minError')
            ),
          address: object()
            .shape({
              street1: string().required(
                t('prospectLoans.ownership.fields.street1.error')
              ),
              street2: string().nullable(),
              city: string().required(
                t('prospectLoans.ownership.fields.city.error')
              ),
              state: string().required(
                t('prospectLoans.ownership.fields.state.error')
              ),
              zip: string()
                .matches(
                  /^[0-9]{5}$/,
                  t('prospectLoans.ownership.fields.zip.error')
                )
                .required(t('prospectLoans.ownership.fields.zip.error'))
            })
            .required(),
          yearsAtAddress: number().nullable(),
          attestation: bool().oneOf([true])
        })
      )
      // @ts-ignore
      .unique(
        t('prospectLoans.ownership.fields.email.uniqueError'),
        'email',
        'owners'
      )
      .totalOwnershipStake(
        t('prospectLoans.ownership.fields.ownershipStake.maxError')
      )
      .min(1)
      .required()
  })

const isRequiredBooleanString = string().required().oneOf(['true', 'false'])

export const validateDayIsInFuture = (date?: number) => {
  if (!date) return false

  const dateString = date.toString()
  const year = parseInt(dateString.substring(0, 4))
  const month = parseInt(dateString.substring(4, 6))
  const day = parseInt(dateString.substring(6, 8))
  const dateObject = new Date(year, month - 1, day)

  return dateObject.getTime() > Date.now()
}

const FinancesSchema = (t: (key: string) => string) =>
  object().shape({
    threeMonthTotalSales: number()
      .positive(
        t(
          'prospectLoans.finances.businessRevenue.fields.threeMonthTotalSales.negativeError'
        )
      )
      .required(
        t(
          'prospectLoans.finances.businessRevenue.fields.threeMonthTotalSales.error'
        )
      ),
    hasFiledPriorYearTaxes: isRequiredBooleanString,
    netIncome: number().required(
      t('prospectLoans.finances.businessRevenue.fields.netIncome.error')
    ),
    filingYear: string().required(),
    currentDebt: number(),
    debtList: mixed().when('hasNoDebt', {
      is: false,
      then: array().of(
        object().shape({
          debtAmount: number()
            .typeError('Type error')
            .positive(
              t(
                'prospectLoans.finances.businessDebt.fields.debtAmount.negativeError'
              )
            )
            .required(
              t('prospectLoans.finances.businessDebt.fields.debtAmount.error')
            ),
          debtType: string().required(
            t('prospectLoans.finances.businessDebt.fields.debtType.error')
          )
        })
      )
    }),
    hasNoDebt: boolean().required(),
    isLeasing: isRequiredBooleanString,
    leaseEndDate: number().when('isLeasing', {
      is: 'true',
      then: number()
        .test(
          'future-date-test',
          t(
            'prospectLoans.finances.businessDeclarations.fields.leaseEndDate.error'
          ),
          validateDayIsInFuture
        )
        .required(
          t(
            'prospectLoans.finances.businessDeclarations.fields.leaseEndDate.error'
          )
        )
        .test(
          'isValidDate',
          t(
            'prospectLoans.finances.businessDeclarations.fields.leaseEndDate.error'
          ),
          isYYYYMMDDNumberValid
        )
    }),
    hasTaxLiens: isRequiredBooleanString,
    taxLienAmount: mixed().when('hasTaxLiens', {
      is: 'true',
      then: number()
        .positive()
        .required(
          t(
            'prospectLoans.finances.businessDeclarations.fields.taxLienAmount.error'
          )
        )
    }),
    taxLienFilingDate: string().when('hasTaxLiens', {
      is: 'true',
      then: string().required(
        t(
          'prospectLoans.finances.businessDeclarations.fields.taxLienFilingDate.error'
        )
      )
    }),
    hasJudgementsOrLawsuits: isRequiredBooleanString,
    hasBankruptcy: isRequiredBooleanString,
    hasOwnershipChanged: isRequiredBooleanString,
    hasHistoryOfClosure: isRequiredBooleanString,
    hasPlannedChangeOfOwnership: isRequiredBooleanString,
    hasPlannedClosures: isRequiredBooleanString,
    hasPlannedLocationChange: isRequiredBooleanString
  })

const ContactSchemaValidator = (t: (key: string) => string) =>
  object().shape({
    contactName: string().required(
      t('prospectLoans.review.contactForm.fields.contactName.error')
    ),
    contactPhoneNumber: number()
      .required(
        t('prospectLoans.review.contactForm.fields.contactPhoneNumber.error')
      )
      .test(
        'length',
        t('prospectLoans.review.contactForm.fields.contactPhoneNumber.error'),
        (phoneNumber?: number) => phoneNumber?.toString().length === 10
      ),
    contactEmail: string()
      .email(t('prospectLoans.review.contactForm.fields.contactEmail.error'))
      .required(
        t('prospectLoans.review.contactForm.fields.contactEmail.error')
      ),
    contactTimes: array()
      .of(string())
      .min(1, t('prospectLoans.review.contactForm.fields.contactTimes.error'))
      .required(
        t('prospectLoans.review.contactForm.fields.contactTimes.error')
      ),
    termsAndConditionsConsent: boolean().isTrue()
  })

export const useValidationSchema = (stepId: ProspectLoanSteps) => {
  const { t } = useTranslation()
  switch (stepId) {
    case ProspectLoanSteps.LOAN_USE:
      return loanUseValidationSchema()
    case ProspectLoanSteps.RESTAURANT_INFO:
      return RestaurantInformationSchema(t)
    case ProspectLoanSteps.BUSINESS_INFO:
      return BusinessInformationSchema(t)
    case ProspectLoanSteps.OWNERSHIP:
      return OwnershipInformationSchema(t)
    case ProspectLoanSteps.FINANCES:
      return FinancesSchema(t)
    case ProspectLoanSteps.REVIEW_TERMS:
      return ContactSchemaValidator(t)
    default:
      return undefined
  }
}
