import React from 'react'
import classNames from 'classnames/bind'
import styles from './InvalidInfoModal.module.css'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useTranslation } from 'react-i18next'
const cx = classNames.bind(styles)

export const InvalidInfoModal = ({
  buttonText,
  infoType,
  isOpen,
  setIsOpen
}) => {
  const { t } = useTranslation()
  return (
    <Modal
      testId='info-check-modal'
      appElement={document.body}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      className={cx('vertically-centered')}
    >
      <ModalHeader>
        <div className='pb-4'>
          {t(
            infoType === 'business'
              ? 'financing.businessValidationModal.title'
              : 'financing.guarantorValidationModal.title'
          )}
        </div>
      </ModalHeader>
      <ModalBody>
        <div>
          {t(
            infoType === 'business'
              ? 'financing.businessValidationModal.body'
              : 'financing.guarantorValidationModal.body'
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => setIsOpen(false)}
          data-testid='close-info-check-modal'
        >
          Got It
        </Button>
      </ModalFooter>
    </Modal>
  )
}
