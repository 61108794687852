import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { SentIllustration } from '@toasttab/buffet-pui-illustrations'

export const OfferPending = (): ReactElement => {
  const { t } = useTranslation()
  return (
    <div
      data-testid='offer-pending-card'
      className='flex flex-col items-center'
    >
      <SentIllustration className='h-[8.125rem] mt-8' resizeToContainer />
      <h4 className='type-headline-4 text-center mt-6'>
        {t('prospectLoans.applicationStatus.offerPending.thanks')}
      </h4>
      <h4 className='type-headline-4 text-center'>
        {t('prospectLoans.applicationStatus.offerPending.yourApplication')}
      </h4>
      <p className='max-w-[26.25rem] text-center mt-6 text-default type-default'>
        {t('prospectLoans.applicationStatus.offerPending.description')}
      </p>
    </div>
  )
}
