import {
  AdditionalOwnershipFields,
  OwnershipInfoFields
} from '@local/ownership-info-fields'
import {
  BackButton,
  ContentFooter,
  ContinueButton,
  PageBody,
  PageMain
} from '@toasttab/buffet-pui-wizard-templates'
import React, { ReactElement, useEffect, useState } from 'react'
import { CheckboxField } from '@toasttab/buffet-pui-forms'
import { FinancingApplicationForm, FinancingStepProps } from './Types'
import { FlowProps } from '@local/use-flow-navigation'
import { scrollToTop } from './helpers'
import { useParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import FinancingApi from '../../src/api/Financing'
import { useFormikContext } from 'formik'
import InvalidInfoModal from '../../src/components/InvalidInfoModal'
import { formatDateYyyyMmDd } from '../../src/components/DateField/dateFieldUtils'
import { DisclaimerAside } from './DisclaimerAside'
import { useTranslation } from 'react-i18next'

export const PersonalGuarantor = ({
  validateForm,
  isValid,
  goToNextStep,
  goToPreviousStep
}: FlowProps<FinancingStepProps>): ReactElement => {
  const { t } = useTranslation()
  const { values } = useFormikContext<FinancingApplicationForm>()
  const { dealNumber } = useParams<{ dealNumber: string }>()
  const [isValidationWarningModalOpen, setIsValidationWarningModalOpen] =
    useState(false)
  const {
    mutate: validateOwnerInfo,
    failureCount,
    isLoading
  } = useMutation(
    () =>
      FinancingApi.validateOwnershipInfo(dealNumber, {
        ...values.owner,
        address: {
          ...values.owner.address,
          country: 'US'
        },
        dob: formatDateYyyyMmDd(values.owner.dob)
      }),
    {
      onSuccess: goToNextStep,
      onError: () => setIsValidationWarningModalOpen(true)
    }
  )

  useEffect(() => {
    validateForm()
    scrollToTop()
  }, [validateForm])

  const onSubmit = async () => {
    // if failureCount > 0, we already tried to validate and failed
    if (failureCount > 0) {
      goToNextStep()
    } else {
      validateOwnerInfo()
    }
  }

  return (
    <>
      <InvalidInfoModal
        buttonText={t('common.next')}
        infoType='ownership'
        isOpen={isValidationWarningModalOpen}
        setIsOpen={setIsValidationWarningModalOpen}
      />
      <PageBody testId='personal-guarantor-page'>
        <PageMain>
          <h1 className='type-headline-4 md:type-headline-2 font-bold text-default mb-3'>
            {t('financing.personalGuarantor.title')}
          </h1>
          <p className='text-secondary type-default mb-4'>
            {t('financing.personalGuarantor.subtitle')}
          </p>
          <div className='grid grid-cols-2 gap-y-4 gap-x-8'>
            <OwnershipInfoFields />
            <AdditionalOwnershipFields />
            <CheckboxField
              name='contact.isGuarantor'
              label={t('financing.personalGuarantor.fields.isGuarantor')}
              data-testid='contact-checkbox'
              containerClassName='col-span-2'
            />
          </div>
          <ContentFooter className='py-6 bg-white'>
            <div className='flex justify-end w-full'>
              <BackButton onClick={goToPreviousStep} />
              <ContinueButton
                testId='guarantor'
                onClick={onSubmit}
                className='mr-6'
                disabled={isLoading || !isValid}
              >
                {isLoading ? t('common.validating') : t('common.next')}
              </ContinueButton>
            </div>
          </ContentFooter>
        </PageMain>
        <DisclaimerAside />
      </PageBody>
    </>
  )
}
