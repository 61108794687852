export const createTaxLienFiledOptions = (t: (key: string) => string) => [
  {
    label: t(
      'prospectLoans.finances.businessDeclarations.fields.taxLienFilingDate.options.lessThanThreeYears'
    ),
    value: 'Less than 3 years'
  },
  {
    label: t(
      'prospectLoans.finances.businessDeclarations.fields.taxLienFilingDate.options.threeToFiveYears'
    ),
    value: '3-5 years'
  },
  {
    label: t(
      'prospectLoans.finances.businessDeclarations.fields.taxLienFilingDate.options.fivePlusYears'
    ),
    value: '5+ years'
  }
]
