import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useBanquetProps } from 'banquet-runtime-modules'
import {
  CheckboxField,
  NumberInputField,
  SelectField,
  TelephoneField,
  TextInputField,
  DateInputField
} from '@toasttab/buffet-pui-forms'
import { listOfStates } from '@local/address-fields/constants'
import { LeadingZerosNumberInputField } from '@local/leading-zeros-number-input-field'
import { Owner } from '../types'
import { HiddenSsnField } from './HiddenSsnField'

interface Props {
  owners: Owner[]
}

export const OwnershipFields = ({ owners }: Props): ReactElement => {
  const { t } = useTranslation()
  const { i18n } = useBanquetProps()

  const currentIndex = owners.length - 1

  return (
    <div className={'grid grid-cols-2 gap-y-4 gap-x-8'}>
      <TextInputField
        className='fs-mask'
        containerClassName='col-span-2 md:col-span-1'
        autoComplete='false'
        name={`owners[${currentIndex}].firstName`}
        label={t('prospectLoans.ownership.fields.firstName.label')}
        data-testid='owner-first-name'
      />
      <TextInputField
        className='fs-mask'
        containerClassName='col-span-2 md:col-span-1'
        autoComplete='false'
        name={`owners[${currentIndex}].lastName`}
        label={t('prospectLoans.ownership.fields.lastName.label')}
        data-testid='owner-last-name'
      />
      <TextInputField
        className='fs-mask'
        containerClassName='col-span-2 md:col-span-1'
        autoComplete='false'
        name={`owners[${currentIndex}].jobTitle`}
        label={t('prospectLoans.ownership.fields.jobTitle.label')}
        data-testid='owner-job-title'
      />
      <TextInputField
        className='fs-mask'
        containerClassName='col-span-2 md:col-span-1'
        autoComplete='false'
        name={`owners[${currentIndex}].email`}
        label={t('prospectLoans.ownership.fields.email.label')}
        data-testid='owner-email'
      />
      <TelephoneField
        className='fs-mask'
        containerClassName='col-span-2 md:col-span-1'
        autoComplete='false'
        name={`owners[${currentIndex}].phoneNumber`}
        label={t('prospectLoans.ownership.fields.phoneNumber.label')}
        data-testid='owner-phone-number'
      />
      <DateInputField
        locale={i18n?.language || 'en-US'}
        name={`owners[${currentIndex}].dob`}
        label={t('prospectLoans.ownership.fields.dob.label')}
        data-testid='owner-dob'
        containerClassName='col-span-2 md:col-span-1'
      />
      <HiddenSsnField
        containerClassName='col-span-2 md:col-span-1'
        name={`owners[${currentIndex}].ssn`}
        label={t('prospectLoans.ownership.fields.ssn.label')}
        data-testid='owner-ssn'
      />
      <NumberInputField
        className='fs-mask'
        containerClassName='col-span-2 md:col-span-1'
        autoComplete='false'
        name={`owners[${currentIndex}].ownershipStake`}
        label={t('prospectLoans.ownership.fields.ownershipStake.label')}
        data-testid='owner-stake'
      />
      <TextInputField
        className='fs-mask'
        containerClassName='col-span-2'
        autoComplete='false'
        name={`owners[${currentIndex}].address.street1`}
        label={t('prospectLoans.ownership.fields.street1.label')}
        data-testid='owner-street1'
      />
      <TextInputField
        className='fs-mask'
        containerClassName='col-span-2'
        autoComplete='false'
        name={`owners[${currentIndex}].address.street2`}
        label={t('prospectLoans.ownership.fields.street2.label')}
        data-testid='owner-street2'
      />
      <TextInputField
        className='fs-mask'
        containerClassName='col-span-2 md:col-span-1'
        autoComplete='false'
        name={`owners[${currentIndex}].address.city`}
        label={t('prospectLoans.ownership.fields.city.label')}
        data-testid='owner-city'
      />
      <SelectField
        containerClassName='col-span-2 md:col-span-1'
        options={listOfStates}
        name={`owners[${currentIndex}].address.state`}
        label={t('prospectLoans.ownership.fields.state.label')}
        testId='owner-state'
      />
      <LeadingZerosNumberInputField
        className='fs-mask'
        containerClassName='col-span-2 md:col-span-1'
        autoComplete='false'
        name={`owners[${currentIndex}].address.zip`}
        label={t('prospectLoans.ownership.fields.zip.label')}
        format='#####'
        mask='_'
        data-testid='owner-zip'
      />
      <NumberInputField
        className='fs-mask'
        containerClassName='col-span-2 md:col-span-1'
        autoComplete='false'
        name={`owners[${currentIndex}].yearsAtAddress`}
        label={t('prospectLoans.ownership.fields.yearsAtAddress.label')}
        data-testid='owner-years-at-address'
      />
      <CheckboxField
        containerClassName='col-span-2'
        name={`owners[${currentIndex}].attestation`}
        label={
          <p className='!font-medium !text-default !type-default'>
            {t('prospectLoans.ownership.fields.attestation.label')}
          </p>
        }
        data-testid='owner-attestation'
      />
      <p className='col-span-2 text-secondary pl-8 -mt-4'>
        {t('prospectLoans.ownership.fields.attestation.description')}
      </p>
    </div>
  )
}
