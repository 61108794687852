import React, { PropsWithChildren, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@toasttab/buffet-pui-buttons'
import { EditIcon } from '@toasttab/buffet-pui-icons'
import cx from 'classnames'

interface Props {
  title?: string
  testId?: string
  hideBorder?: boolean
  onEditClick(): void
}

export const ReviewSection = ({
  onEditClick,
  title,
  testId,
  children,
  hideBorder
}: PropsWithChildren<Props>): ReactElement => {
  const { t } = useTranslation()

  return (
    <section
      className={cx('border-gray-30 relative mt-6', {
        'border-t': !hideBorder
      })}
    >
      <div
        className={cx('flex justify-between align-middle', {
          '-mb-14': !title,
          '!justify-end': !title
        })}
      >
        {title && <h3 className='type-large font-semibold mt-6'>{title}</h3>}
        <Button
          className='mt-3'
          iconLeft={<EditIcon accessibility='decorative' />}
          variant='link'
          onClick={onEditClick}
          testId={testId}
          data-web-analytics-id={testId}
        >
          {t('prospectLoans.review.edit')}
        </Button>
      </div>
      {children}
    </section>
  )
}
