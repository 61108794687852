import React, { ReactElement } from 'react'
import { createPortal } from 'react-dom'
import { CodatLink } from './CodatLink'

interface Props {
  isOpen: boolean
  codatGuid: string
  onClose(): void
  onFinish(): void
}

export const CodatModal = ({
  isOpen,
  codatGuid,
  onClose,
  onFinish
}: Props): ReactElement | null => {
  return isOpen
    ? createPortal(
        <div
          data-cap
          className='fixed bg-gray-100 z-50 top-0 left-0 bg-opacity-25 h-full w-full overflow-y-auto'
        >
          <div className='codat relative mx-auto w-80' style={{ top: 40 }}>
            <CodatLink
              companyId={codatGuid}
              onConnection={() => null}
              onClose={onClose} // Called when the user clicks 'X'
              onError={onClose}
              onFinish={onFinish} // Called when user completes the whole flow
              options={{
                showLandingPage: false,
                sourceTypes: {
                  accounting: { optional: true, enableIntegrations: true },
                  banking: {
                    optional: false,
                    enableIntegrations: true,
                    enableFileUpload: false
                  }
                }
              }}
            />
          </div>
        </div>,
        // @ts-ignore
        document.getElementById('single-spa-application:customer-financing-ui')
      )
    : null
}
