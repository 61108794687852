import React from 'react'
import classNames from 'classnames/bind'
import styles from './Loading.module.css'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
const cx = classNames.bind(styles)

export const Loading = () => {
  return (
    <div className={cx('loading-container')}>
      <div className={cx('loading-icon')}>
        <MerryGoRound size={MerryGoRound.Size.xl} />
      </div>
      <div className={cx('type-large text-default', 'loading-text')}>
        Your application is being processed...
      </div>
    </div>
  )
}
