import React, { ReactElement, ReactNode } from 'react'
import cx from 'classnames'
import {
  RadioGroupField,
  RadioGroupFieldProps
} from '@toasttab/buffet-pui-forms'

interface InlineLabelRadioGroupFieldProps extends RadioGroupFieldProps {
  labelText: ReactNode
  containerClassname?: string
}

export const InlineLabelRadioGroupField = (
  props: InlineLabelRadioGroupFieldProps
): ReactElement => {
  return (
    <div
      className={cx(
        'flex flex-col sm:flex-row sm:items-center',
        props.containerClassname
      )}
    >
      <label
        htmlFor={props.id}
        className='type-default font-semibold sm:mr-8 sm:w-[350px]'
      >
        {props.labelText}
      </label>
      <RadioGroupField inline {...props} />
    </div>
  )
}
