export const prospectLoansTranslations = {
  prospectLoans: {
    steps: {
      loanUse: 'Loan use',
      business: 'Business',
      ownership: 'Ownership',
      finances: 'Finances',
      review: 'Review'
    },
    welcome: {
      title:
        "Let's get started with your Toast Capital Welcome Loan application",
      description:
        'Contact information for the person in charge of setting up Toast and the person in charge of finance decisions at your restaurant is required.',
      needToApply: {
        heading: 'To apply, you’ll need to provide:',
        restaurantLegalInformation: 'Restaurant Legal information',
        contactInformation: 'Contact Information',
        toastDealNumber: 'Toast Deal Number'
      },
      aside: {
        takeYourTime: {
          heading: 'Take your time filling out this application',
          description:
            'This application won’t take you too long to complete; however, Toast will not save your progress. If you leave this experience before completing the application, you will have to start the application process over.'
        },
        creditScore: {
          heading: 'There is no credit score required'
        }
      }
    },
    loanUse: {
      title: 'How do you plan to use your loan?',
      description:
        "We're here to help you grow your business, today, tomorrow or whenever you need assistance, we have loan options to meet your needs.",
      options: {
        newLocation: 'Open a new location',
        renovations: 'Renovations',
        kitchenEquipment: 'New kitchen equipment',
        expansion: 'Expansion of existing space',
        employees: 'Pay my employees',
        debt: 'Refinance my debt',
        operations: 'Operational expenses',
        cashFlow: 'Short term cash flow needs'
      }
    },
    businessInfo: {
      stepOne: 'Step 1 of 2',
      stepTwo: 'Step 2 of 2',
      title: 'Tell us about your business',
      restaurantDescription:
        'Let’s confirm your restaurant location information. Please carefully review the information below and make sure it is accurate.',
      businessDescription:
        'Let’s confirm your business legal information. Please carefully review the information below and make sure it is accurate.',
      invalidBusinessInfo:
        "We couldn't verify your business information, but that's okay. Check what you've entered and correct any mistakes you might have made and try again.",
      validationLoading:
        'Please wait while we validate your business information.',
      fields: {
        socialScore: {
          label: 'Average social score',
          tooltip:
            'How you objectively rate your social presence across Yelp, Google My Business,<0>TM</0>, Facebook, etc.',
          options: {
            THREE_STARS: 'Less than 3 stars',
            THREE_TO_THREE_AND_A_HALF_STARS: '3 - 3.49 stars',
            THREE_AND_A_HALF_TO_FOUR_STARS: '3.5 - 3.99 stars',
            FOUR_TO_FOUR_AND_A_HALF_STARS: '4 - 4.49 stars',
            FOUR_AND_A_HALF_PLUS: '4.5+ stars'
          }
        },
        ownershipType: {
          label: 'Ownership type',
          options: {
            FINANCIAL_INSTITUTION: 'Financial Institution',
            FRANCHISE: 'Franchise',
            LLC: 'LLC',
            NON_PROFIT: 'Non-profit',
            PARTNERSHIP: 'Partnership',
            PRIVATE_CORPORATION: 'Private Corporation',
            PUBLIC_CORPORATION: 'Public Corporation',
            SEC_REGULATED_CORPORATION: 'SEC Regulated Corporation',
            SOLE_PROPRIETORSHIP: 'Sole Proprietorship',
            TRUST: 'Trust',
            OTHER: 'Other'
          }
        }
      }
    },
    ownership: {
      title: 'Tell us about your business ownership & control person',
      description:
        'When you apply for a loan, federal law requires you to provide information about your beneficial ownership and a control person. Please provide a control person as well as any owner that has 25% or more ownership in the business. If no individual owner has a 25% or greater share of ownership, please list the control person.',
      controlPersonError: 'Please add at least 1 control person.',
      invalidOwnershipInfo:
        "We couldn't verify your ownership information, but that's okay. Check what you've entered and correct any mistakes you might have made and try again.",
      validationLoading:
        'Please wait while we validate your ownership information.',
      ownerList: {
        emptyState: 'You haven’t added any owners or control person yet',
        description:
          'You can add a maximum of 4 owners. You must also add 1 control person.',
        addPerson: '+ Add person',
        role: 'Role:',
        owner: 'Owner ',
        controlPerson: 'Control person'
      },
      addOwnerModal: {
        title: 'Add owner or control person',
        businessSetup: 'Business setup',
        cancel: 'Cancel',
        add: 'Add',
        ownerRoleSection: {
          title: 'Who would you like to add?',
          description:
            'We can collect up to 4 business owners, and you must identify at least 1 control person.',
          owner: {
            title: 'Owner',
            description:
              'This is a person who, directly or indirectly, owns a 25% or greater interest in this business'
          },
          controlPerson: {
            title: 'Control person',
            description:
              'This is an individual with significant responsibility to control, manage or direct the merchant entity'
          }
        },
        formSection: {
          title: 'Please provide owner information below:',
          description:
            'If you have multiple owners with over 25% stake in the business, you’ll have the opportunity to add their information later on.'
        }
      },
      fields: {
        role: {
          error: 'Please select a role.'
        },
        firstName: {
          label: 'First name',
          error: 'Please enter a first name.'
        },
        lastName: {
          label: 'Last name',
          error: 'Please enter a last name.'
        },
        jobTitle: {
          label: 'Job title',
          error: 'Please enter a job title.'
        },
        email: {
          label: 'Email address',
          error: 'Please enter a valid email address.',
          uniqueError:
            'This email is already in use. Please use a different email address.'
        },
        phoneNumber: {
          label: 'Phone number',
          error: 'Please enter a valid phone number.'
        },
        dob: {
          label: 'Date of birth (DOB)',
          error: 'Please enter a valid date of birth.'
        },
        ssn: {
          label: 'SSN/ITIN',
          error: 'Please enter a valid SSN or ITIN.'
        },
        ownershipStake: {
          label: 'Ownership percentage',
          minError:
            'The owner you provide must have 25% or greater interest in this business.',
          integerError:
            'The ownership percentage needs to be a whole number. It cannot contain a decimal point.',
          maxError:
            'The total ownership percentage across all owners cannot exceed 100%.'
        },
        street1: {
          label: 'Address line 1',
          error: 'Please enter a valid US address'
        },
        street2: {
          label: 'Address line 2 (optional)'
        },
        city: {
          label: 'City',
          error: 'Please enter a city'
        },
        state: {
          label: 'State',
          error: 'Please select a state'
        },
        zip: {
          label: 'Zip code',
          error: 'Please enter a valid 5 digit zip code'
        },
        yearsAtAddress: {
          label: 'Years at address'
        },
        attestation: {
          label: 'Beneficial Owner Information Attestation',
          description:
            'I hereby represent that I am an owner or control person of the merchant and am authorized to execute this application on behalf of the merchant. I further represent that information disclosed by me herein, individually and otherwise on behalf of the merchant, business during this application is true, accurate and complete in all respects, but specifically as to the identity of owners and control persons of the merchant. I acknowledge that any misrepresentation in this regard can be the subject of criminal investigation.'
        }
      },
      aside: {
        beneficialOwner: {
          title: 'What is a beneficial owner?',
          description:
            'A beneficial owner is an individual who, directly or indirectly, owns 25% or more of the business.'
        },
        controlPerson: {
          title: 'What is a control person?',
          description:
            'A control person is someone who controls, manages, or directs your restaurant, such as an executive officer, senior manager, or someone who performs similar functions.'
        }
      }
    },
    finances: {
      title: 'Tell us about your finances',
      description:
        'Additional information is required to evaluate your application.',
      businessRevenue: {
        title: '1. Business revenue',
        fields: {
          threeMonthTotalSales: {
            label:
              'What is your total credit and debit card sales over the past 3 months?',
            error:
              'Please enter your total credit and debit card sales over the past 3 months.',
            negativeError: 'Please enter a positive amount.'
          },
          hasFiledPriorYearTaxes: {
            label: 'Have you filed your business taxes for {{year}}?',
            options: { yes: 'Yes', no: 'No' }
          },
          netIncome: {
            label: 'Reported business net income',
            error: 'Please enter your net income.'
          },
          projectedNetIncome: {
            label: 'Projected business net income',
            error: 'Please enter your net income.'
          },
          filingYear: {
            label: 'Filing year'
          },
          anticipatedFilingYear: {
            label: 'Anticipated filing year'
          }
        }
      },
      businessDebt: {
        title: '2. Business debt',
        description:
          'Providing business debt information will help make sure you have enough cash flow to cover your obligations, in addition to a Toast Capital loan.',
        add: 'Add another business debt',
        delete: 'Delete',
        fields: {
          debtType: {
            label: 'Type of debt',
            error: 'Please select a type of debt.'
          },
          debtAmount: {
            label: 'Current outstanding balance',
            error: 'Please enter your current outstanding balance.',
            negativeError: 'Please enter a positive amount.'
          }
        },
        debtTypes: {
          creditCard: 'Credit card / Line of credit',
          termLoan: 'Term loan',
          mortgage: 'Commercial mortgage',
          mca: 'Merchant cash advance',
          equipmentLease: 'Equipment lease or loan',
          other: 'Other loan'
        },
        noDebt: 'I currently do not have any business debt'
      },
      businessDeclarations: {
        title: '3. Other business information',
        description: 'Please answer these questions for your business only.',
        fields: {
          isLeasing: {
            label: 'Are you currently leasing your business location?'
          },
          leaseEndDate: {
            label: 'Lease end date',
            error: 'Please enter a lease end date in the future'
          },
          hasTaxLiens: {
            label: 'Does this legal entity have any outstanding tax liens?'
          },
          taxLienAmount: {
            label: 'Current outstanding balance',
            error: 'Please enter a total currently outstanding amount'
          },
          taxLienFilingDate: {
            label: 'Most recent lien filing date',
            error: 'Please select a most recent filing date',
            options: {
              lessThanThreeYears: 'Less than 3 years',
              threeToFiveYears: '3-5 years',
              fivePlusYears: '5+ years'
            }
          },
          hasJudgementsOrLawsuits: {
            label:
              'Does this legal entity (or, if you are a sole proprietor, do you) have any judgments or lawsuits?'
          },
          hasBankruptcy: {
            label:
              'Has this legal entity (or, if you are a sole proprietor, have you) applied for bankruptcy in the last two years?',
            tooltip: `If you've had a bankruptcy in the last two years that has not yet shown up on a credit report, please still mark "Yes".`
          },
          hasOwnershipChanged: {
            label:
              'Has any ownership of your restaurant changed in the last two years?'
          },
          hasHistoryOfClosure: {
            label:
              'Does this legal entity have a history of closure over last 360 days?'
          },
          hasPlannedChangeOfOwnership: {
            label:
              'Does this legal entity have a change of ownership planned over the next 150 days?'
          },
          hasPlannedClosures: {
            label:
              'Does this legal entity have any current or planned closures over next 150 days'
          },
          hasPlannedLocationChange: {
            label:
              'Does this legal entity have plans to change location over the next 150 days'
          }
        }
      },
      aside: {
        taxReturn:
          'Your most recent Business Federal Tax Return might be helpful to fill out this section',
        faqs: {
          title: 'Frequently asked questions:',
          close: 'Close',
          businessRevenue: {
            question:
              'Where can I find my business revenue off of my Business Federal Tax Return?',
            answer:
              'If you review Page 1 of your Business Federal Tax Return, all form types have an Income section.  Within this section there should be a line in bold at the bottom of this section entitled, “Total Income.”'
          },
          soleProprietor: {
            question:
              'I am a Sole Proprietor, how do I report my business income?',
            answer:
              'As a Sole Proprietor you should be filing a Schedule C as a part of your Personal Tax Return.  Please refer to the Income Section on this form, and you will use the value from the Gross Income line at the bottom of this section.'
          }
        },
        debt: {
          header: 'What types of debt should I report?',
          subheader:
            'Below are the valid types of debt you should report on your application:',
          creditCard: 'Business credit card or business line of credit',
          termLoan: 'Term loan',
          mortgage: 'Commercial mortgage',
          mca: 'Merchant cash advance',
          equipment: 'Equipment lease or loan',
          other: 'Other loan',
          footer:
            'You do not need to report any personal debt, operating expenses such as vendor bills, supplier payments, or rent.'
        },
        needMoreHelp: {
          headline: 'Need more help?',
          contactInfo:
            'Call us at <0>(617) 209-3198</0> or email us at <1>capital@toasttab.com</1>',
          capitalSupportHours: 'Capital support hours: Mon-Fri 9AM - 5PM ET'
        }
      }
    },
    linkAccounts: {
      title: 'Let’s connect your business accounting and bank information',
      description:
        "Provide additional information about your businesses' financial health, please connect your banking and accounting information.",
      connectTheBankAccounts:
        'Connect the bank accounts you use for deposits and accounting.',
      linkBusinessAccounts: 'Link business accounts',
      accountingAndBankingInfo: 'Accounting and banking information',
      connected: 'Connected',
      toProvideAdditionalInfo:
        'To provide additional information about your company’s finances, you can securely share your banking and accounting information with Toast, Inc., and its affiliates (“Toast”), and WebBank.  At a minimum, we require you to share your banking information to continue with your application for a Toast Capital Welcome Loan.',
      purpose:
        '<0>Purpose:</0> This information is used to determine your eligibility for loans and other financial products, service any products you obtain, and provide you with personalized financial insights and recommendations.',
      select: 'Select "Link business accounts" to connect your accounts.',
      toastCustomer:
        'If you become a customer, this connection will remain and the information will continue to be shared for the purposes listed above. If you do not become a  customer, your accounts will be disconnected no later than 100 days following the date of your application. However, in all cases, you can disconnect your linked accounts at any time through your banking or accounting partner.',
      aside: {
        secure: {
          title: 'Secure and private',
          description:
            'Your financial information is safe. Your account information is encrypted and your credentials will never be shared with Toast.'
        },
        questions: {
          title: 'Have questions?',
          description: 'Check out our <0>FAQ</0> page.'
        }
      }
    },
    review: {
      title: 'Review your application',
      description:
        'Accuracy of this information will help ensure you get a decision as quickly as possible.',
      edit: 'Edit',
      purposeOfLoan: 'Purpose of loan',
      restaurantLocationInformation: 'Restaurant location information',
      businessLegalInformation: 'Business legal information',
      ownershipTitle: 'Restaurant’s ownership & control persons',
      ownerLabel: 'Owner {{number}}:',
      ownership: 'Ownership:',
      address: 'Address:',
      controlPerson: 'Control person:',
      finances: 'Finances',
      hasLease: 'Yes, lease ends {{date}}',
      outstanding: 'outstanding, most recent filing',
      ago: 'ago',
      contactForm: {
        title: 'Contact information',
        description: 'Please share the primary contact for this loan request.',
        fields: {
          contactName: {
            label: 'Name',
            error: 'Please provide a contact name.'
          },
          contactPhoneNumber: {
            label: 'Business cell phone number',
            error: 'Please provide a valid business cell phone number.'
          },
          contactEmail: {
            label: 'Email',
            error: 'Please provide a valid contact email.'
          },
          contactTimes: {
            label:
              'In the event we need to reach out by phone, what time of day works best?',
            subLabel: 'Select all that apply.',
            morning: 'Morning (9AM - 12PM ET)',
            noon: 'Noon (12PM - 2PM ET)',
            afternoon: 'Afternoon (2PM - 5PM ET)',
            error: 'Please select at least one option.'
          },
          termsAndConditionsConsent: {
            title: 'Customer attestations',
            description:
              'Please review the following statements and accept by checking the box below:',
            promise:
              'I promise that everything disclosed by me or otherwise on behalf of the business during this application process, including all beneficial ownership and control person information, is, and will be, true, accurate and complete.',
            certify:
              'I certify that I am an authorized representative of this business who can apply for this loan.',
            agreeToBeContacted:
              'I agree to be contacted and to transact business by electronic means, including by email, and agree that facsimile and electronic signatures are acceptable for all purposes related to this transaction. I, on behalf of myself and the business applying for this loan, further agree that any document required by law to be delivered in writing may be delivered to the email address that I have provided to you and that the business will maintain the ability to receive such documents.',
            acknowledge:
              'I acknowledge that I have read and agree to the <0>Terms of Service</0> and the <1>Toast Privacy Statement</1>.',
            agreeThatTheBusiness:
              'I agree that the business applying for this loan will only use the proceeds of this loan for business working capital or for other business purposes and not for a personal, family, household, or other consumer purpose.',
            checkboxLabel:
              'I, on behalf of myself and the business applying for this loan, have read and agree with the statements above.'
          }
        }
      },
      submit: 'Submit loan application'
    },
    submitted: {
      thanks: 'Thanks for applying!',
      yourApplication: 'Your application is being reviewed.',
      pleaseExpect:
        'Please expect to hear about a decision in 1-2 business days.',
      youWillReceive: 'You will receive an email when a decision has been made.'
    },
    error: {
      error: 'There was an error submitting your application.',
      tryAgain: 'Please try again later.'
    },
    applicationStatus: {
      error: {
        title: 'No loan application found',
        description:
          'Unable to find a Toast Capital Welcome Loan application for the Toast Deal Number {{dealNumber}}'
      },
      offerApproved: {
        title:
          'Congratulations! Your Toast Capital Welcome Loan application was conditionally approved*. See below for your offer terms.',
        goLive: 'Go live with Toast',
        processSales: 'Process sales for 3 days',
        requestFunds: 'Request funds',
        ifYourOffer: 'If your offer is still available',
        approvedAmount: 'Conditionally approved amount',
        fixedFee: 'Fixed fee',
        dailySales: 'Daily sales withholding',
        repaymentTerm: 'Repayment term',
        days: '{{numberOfDays}} days',
        1: '1',
        2: '2',
        3: '3'
      },
      offerExpired: {
        title: 'Your approval window has expired',
        description:
          'If you begin processing on Toast, you may be eligible to receive additional pre-qualified* Toast Capital offers.',
        goLive: 'Go live with Toast'
      },
      offerDeclined: {
        title:
          'Unfortunately, your loan application could not be approved at this time.',
        description:
          'You can always check the Financial Products page at a later time to see if an offer is available for you.'
      },
      goToToastWeb: {
        title: `Go to Toast Web to see if you're pre-qualified*`,
        description:
          'Our records indicate you have begun processing. At this time you are unable to use this application and should instead navigate to your Financial Products page on Toast Web.',
        goToFPP: 'Go to my Financial Products page'
      },
      offerPending: {
        thanks: 'Thanks for applying!',
        yourApplication: 'Your application is being reviewed.',
        description:
          'Please expect to hear about a decision in 1-2 business days. You will receive an email when a decision has been made.'
      },
      legalDisclaimer:
        '*Toast Capital Loans are issued by WebBank. Loans are subject to credit approval. WebBank reserves the right to change or discontinue this program without notice at any time. This is not a line of credit and funds are disbursed as one lump sum. Borrower must successfully complete the Toast customer onboarding process, process at least three of seven days on the Toast platform, and continue to satisfy credit criteria at the time funding is requested. Registered business entities with less than one year of operations or located in FL, VA, CA, NY, UT, CT, KS, or GA are not currently eligible to apply for the Toast Capital Welcome Loan.'
    },
    shared: {
      legalDisclaimer:
        'Toast Capital Loans are issued by WebBank. Loans are subject to credit approval. WebBank reserves the right to change or discontinue this program without notice at any time. This is not a line of credit and funds are disbursed as one lump sum. Borrower must successfully complete the Toast customer onboarding process, process at least three of seven days on the Toast platform, and continue to satisfy credit criteria at the time funding is requested. Registered business entities with less than one year of operations or located in FL, VA, CA, NY, UT, CT, KS, or GA are not currently eligible to apply for the Toast Capital Welcome Loan.',
      shortLegalDisclaimer:
        'Toast Capital Loans are issued by WebBank. Loans are subject to credit approval and may not be available to borrowers in certain jurisdictions. WebBank reserves the right to change or discontinue this program without notice.',
      continue: 'Continue',
      loading: 'Loading...',
      yes: 'Yes',
      no: 'No',
      toastCapital: 'Toast Capital',
      getPreApproved: 'Get pre-approved for a Toast Capital Welcome Loan',
      sorry: "We're sorry",
      gotIt: 'Got it',
      exceededMaxAttempts:
        'You have exceeded the maximum attempts allowed. Please try again later.'
    },
    marketingPage: {
      title: 'Toast Capital Welcome Loan',
      upgrading:
        'Upgrading to Toast? Upgrade your restaurant too with a Welcome Loan<0>1</0>!',
      eligible: "See if you're eligible for funding  from $5k to $30k",
      requestFunding:
        'Request funding shortly after you start processing on Toast',
      noPersonalGuarantee:
        'No personal guarantee and no obligation to borrow if approved',
      applyNow: 'Apply now',
      creditScore: "Won't affect your credit score",
      whyChooseToastCapital: 'Why choose Toast Capital?',
      quickApplication: {
        title: 'Quick application',
        description:
          'Applying takes just a few minutes with no personal guarantee, no credit report required, and no credit score impact.'
      },
      receiveFundsQuickly: {
        title: 'Receive funds quickly',
        description:
          'Request funds after processing cards on Toast for 3 days. Funding is disbursed as soon as the next business day after signing your credit agreement.'
      },
      oneFixedFee: {
        title: 'One fixed fee',
        description:
          'One simple fixed loan fee repaid over the life of the loan — no compounding interest, application fees, or late fees.'
      },
      automatedRepayment: {
        title: 'Automated repayment',
        description:
          'Daily automated repayments are made as a fixed percentage of card transactions processed through Toast.'
      },
      testimonials: {
        title: 'What our customers say',
        disclaimer:
          'Testimonials reflect the experience of the merchant. Results may vary. Responses may have been edited for clarity.',
        viewCaseStudy: 'View case study',
        testimonial1: {
          quote:
            'I love how easy Toast Capital’s process is, how quick it is, and how there’s no long, drawn-out application process. Toast Capital is a great option for us to have at our fingertips.',
          name: 'Casual dining restaurant in Decatur, GA'
        },
        testimonial2: {
          quote:
            'We recently took out a Toast Capital Loan to finance a kitchen renovation during Thanksgiving. We were done and fully repaid by January 2023 — nice and easy.',
          name: 'A steakhouse restaurant in Norcross, GA'
        }
      },
      howDoesItWork: {
        title: 'How does it work?',
        step1: {
          title: 'Step 1',
          label: 'Submit Application',
          description:
            'The application takes just a few minutes. Your Toast sales representative will provide your Deal Number.'
        },
        step2: {
          title: 'Step 2',
          label: 'Review & Approval',
          description:
            "If conditionally approved, you'll have up to 90 days* to start processing and request funds through a Welcome Loan up to your approved amount."
        },
        step3: {
          title: 'Step 3',
          label: 'Request Funds',
          description:
            'After processing card transactions through Toast for 3 out of 7 days, you can request your funding, subject to a quick eligibility check.'
        },
        step4: {
          title: 'Step 4',
          label: 'Receive Funding',
          description:
            'Funding is typically disbursed within 1-2 business days after signing your credit agreement.'
        }
      },
      graphSection: {
        title: 'See what your Daily Repayment could look like?',
        subhead:
          'Automatically repay your loan every time a guest pays with a card at your restaurant.<0>2</0> It’s that easy. Hover over the image below to learn more.',
        disclaimer:
          'This payment example is based on 6% daily repayment rate. Numbers are for illustrative purpose only.',
        gotItButton: 'Got it',
        graphLabels: {
          sunday: 'Sunday',
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday',
          dailyCardSales: 'Daily card sales',
          dailyLoanRepayment: 'Daily loan repayment',
          noPayments: 'No Payments',
          payments: 'Payments',
          sales: 'sales'
        }
      },
      faqs: {
        title: 'Frequently Asked Questions',
        viewMore: 'Still have questions? <0>View more FAQs</0>',
        dealNumber: {
          question: 'What is my Deal Number?',
          answer:
            'Your Deal Number is a unique identifier for your Welcome Loan application. Your can get your Deal Number from your Toast sales representative.'
        },
        bankingInformation: {
          question: 'Why do I need to connect my banking information?',
          answer:
            'We need to collect and verify additional information about your business because you have not onboarded and processed through Toast. The information you provide will be processed in accordance with Toast’s <0>Privacy Statement</0>.'
        },
        funding: {
          question:
            'When can I get my funding? Can I get funding before I onboard?',
          answer:
            'You can request funds after going live with Toast and processing card transactions for three days.  You must request funds the earlier of 90 days after being approved or 29 days after you begin processing on Toast.'
        },
        apply: {
          question:
            "Can I apply for another Welcome Loan if I don't request my funds in time?",
          answer:
            'No.  However, we regularly determine whether Toast Customers may be eligible to apply for a Toast Capital Loan and you may see an offer to apply if you begin processing on Toast and satisfy eligibility requirements.'
        }
      },
      disclaimers: {
        webBankDisclaimer:
          '<0>1</0>Toast Capital Loans are issued by WebBank. Loans are subject to credit approval. WebBank reserves the right to change or discontinue this program without notice at any time. This is not a line of credit and funds are disbursed as one lump sum. Borrower must successfully complete the Toast customer onboarding process, process at least three of seven days on the Toast platform, and continue to satisfy credit criteria at the time funding is requested. Registered business entities with less than one year of operations or located in FL, VA, CA, NY, UT, CT, KS, or GA are not currently eligible to apply for the Toast Capital Welcome Loan.',
        repaymentDisclaimer:
          '<0>2</0>Toast Capital Welcome Loans have a target repayment term of 90 days, and a maximum repayment term of 150 days. Any outstanding balance due at the end of the maximum term will be collected automatically via ACH.',
        approvedDisclaimer:
          '*If approved, you must request funds the earlier of 90 days from date of approval notification or 29 days after you begin processing on Toast.'
      }
    }
  }
}
