import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FieldArray, useFormikContext } from 'formik'
import { Button } from '@toasttab/buffet-pui-buttons'
import { AddIcon } from '@toasttab/buffet-pui-icons'
import { CheckboxField } from '@toasttab/buffet-pui-forms'
import { ProspectLoanFormValues } from '../types'
import { DebtItem } from './DebtItem'

export const BusinessDebt = (): ReactElement => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<ProspectLoanFormValues>()

  useEffect(() => {
    if (values.hasNoDebt) {
      setFieldValue('debtList', [{ debtType: '', debtAmount: '' }])
    }
  }, [setFieldValue, values.hasNoDebt])

  return (
    <div>
      <p className='type-large font-semibold mt-6 mb-6'>
        {t('prospectLoans.finances.businessDebt.title')}
      </p>
      <p className='type-default mb-6'>
        {t('prospectLoans.finances.businessDebt.description')}
      </p>
      <FieldArray name='debtList'>
        {({ push, remove, form }) => {
          const { debtList, hasNoDebt } = form.values

          return (
            <div>
              <div>
                {/* @ts-ignore*/}
                {form.values.debtList.map((value, index: number) => (
                  <DebtItem
                    key={`debtList[${index}]`}
                    onDelete={() => remove(index)}
                    index={index}
                    isLastEntry={index === debtList.length - 1}
                    showDeleteButton={debtList.length > 1}
                    disabled={hasNoDebt}
                  />
                ))}
                <Button
                  disabled={hasNoDebt}
                  className='mt-4'
                  variant='text-link'
                  iconLeft={<AddIcon accessibility='decorative' />}
                  onClick={() => push({ debtType: '', debtAmount: '' })}
                  testId='add-another-debt'
                >
                  {t('prospectLoans.finances.businessDebt.add')}
                </Button>
              </div>
              <CheckboxField
                name='hasNoDebt'
                label={t('prospectLoans.finances.businessDebt.noDebt')}
                containerClassName='mt-6'
              />
            </div>
          )
        }}
      </FieldArray>
    </div>
  )
}
