import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export const Legend = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <div className='flex  py-10 flex-col items-center md:flex-row md:justify-around'>
      <LegendItem color='#ffa98e'>
        {t(
          'prospectLoans.marketingPage.graphSection.graphLabels.dailyCardSales'
        )}
      </LegendItem>
      <LegendItem color='#ff4c00'>
        {t(
          'prospectLoans.marketingPage.graphSection.graphLabels.dailyLoanRepayment'
        )}
      </LegendItem>
    </div>
  )
}

interface LegendItemProps {
  children: string
  color: string
}

const LegendItem = ({ children, color }: LegendItemProps) => {
  return (
    <p className='flex items-center text-secondary font-semibold type-caption uppercase'>
      <div
        style={{ minWidth: 8, backgroundColor: color }}
        className='rounded-full m-2 h-2 w-2'
      />
      {children}
    </p>
  )
}
