import React, { ReactElement } from 'react'
import cx from 'classnames'
import { ContentHeader } from '@toasttab/buffet-pui-wizard-templates'

interface Props {
  title: string
  description: string
  fullWidth?: boolean
}

export const PageTitle = ({
  title,
  description,
  fullWidth
}: Props): ReactElement => {
  return (
    <>
      <div
        className={cx('pb-6', {
          'max-w-[360px]': !fullWidth,
          'w-full': fullWidth
        })}
      >
        <ContentHeader title={title} className='!pb-0' />
        <p className='text-default type-default'>{description}</p>
      </div>
      <div className='bg-black h-0.5 w-full block'>&nbsp;</div>
    </>
  )
}
