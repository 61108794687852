import React from 'react'
import classNames from 'classnames/bind'
import styles from './ThankYou.module.css'
import { FINANCING_METHODS } from '../../utils/utils'
const cx = classNames.bind(styles)

export const ThankYou = ({ financingMethod }) => {
  const isLoan = financingMethod === FINANCING_METHODS.LOAN
  const message = isLoan ? 'Toast Financing' : 'Toast Easy Pay'
  return (
    <div className={cx('thank-you-centered-div')}>
      <div
        className={cx('type-headline-2 font-bold', 'thank-you-heading')}
        data-testid='thank-you'
      >
        Thank you for applying for {message}!
      </div>
      <div className={cx('type-large', 'thank-you-text')}>
        Our team will be in touch shortly with next steps.
      </div>
    </div>
  )
}
