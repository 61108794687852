import { BaseStepProps } from '@toasttab/buffet-pui-stepper'
import { ProspectLoanSteps } from '../../types'
import { DISPLAY_STEPS, displayStepsConfig } from './constants'

export const getDisplayStepState = (
  currentStep: ProspectLoanSteps,
  displayStep: DISPLAY_STEPS
): BaseStepProps['state'] => {
  const activeSteps = displayStepsConfig[displayStep]

  if (activeSteps.includes(currentStep)) {
    return 'active'
  }
  const orderedSteps = Object.keys(ProspectLoanSteps)
  const activeIndices = activeSteps.map((step) => orderedSteps.indexOf(step))
  const currentIndex = orderedSteps.indexOf(currentStep)

  if (currentIndex < Math.min(...activeIndices)) {
    return 'inactive'
  }

  return 'complete'
}
