import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BackButton,
  ContentFooter,
  ContinueButton,
  PageBody,
  PageMain
} from '@toasttab/buffet-pui-wizard-templates'
import { FlowProps } from '@local/use-flow-navigation'
import { ProspectLoanStepProps } from '../types'
import { PageTitle } from '../shared'
import { FinancesAside } from './FinancesAside'
import { BusinessRevenue } from './BusinessRevenue'
import { BusinessDebt } from './BusinessDebt'
import { BusinessDeclarations } from './BusinessDeclarations'

export const Finances = ({
  isValid,
  validateForm,
  goToNextStep,
  goToPreviousStep
}: FlowProps<ProspectLoanStepProps>): ReactElement => {
  const { t } = useTranslation()

  useEffect(() => {
    validateForm()
  }, [validateForm])

  return (
    <PageBody testId='prospect-loans-finances-page'>
      <PageMain>
        <PageTitle
          title={t('prospectLoans.finances.title')}
          description={t('prospectLoans.finances.description')}
        />
        <BusinessRevenue />
        <BusinessDebt />
        <BusinessDeclarations />
        <ContentFooter className='mt-6 py-6 bg-white'>
          <div className='flex justify-end w-full'>
            <BackButton onClick={goToPreviousStep} />
            <ContinueButton
              onClick={goToNextStep}
              disabled={!isValid}
              className='mr-6'
              testId='finances'
            >
              {t('prospectLoans.shared.continue')}
            </ContinueButton>
          </div>
        </ContentFooter>
      </PageMain>
      <FinancesAside />
    </PageBody>
  )
}
