import React, { ReactElement } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import i18n from 'i18next'
import { FAQItem } from './FAQItem'

export const FAQs = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <div
      data-testid='faqs-section'
      className='flex flex-col items-center mt-12'
    >
      <h3 className='type-headline-3 text-center'>
        {t('prospectLoans.marketingPage.faqs.title')}
      </h3>
      <div className='mt-8'>
        <FAQItem
          hasBorderTop
          question={t('prospectLoans.marketingPage.faqs.dealNumber.question')}
          answer={
            <p className='text-secondary type-subhead'>
              {t('prospectLoans.marketingPage.faqs.dealNumber.answer')}
            </p>
          }
        />
        <FAQItem
          question={t(
            'prospectLoans.marketingPage.faqs.bankingInformation.question'
          )}
          answer={
            <p className='text-secondary type-subhead'>
              <Trans
                i18nKey='prospectLoans.marketingPage.faqs.bankingInformation.answer'
                t={t}
                i18n={(i18n as any).i18next}
              >
                <a
                  href='https://pos.toasttab.com/toast/privacy'
                  rel='noreferrer noopener'
                  target='_blank'
                  className='underline text-secondary font-semibold'
                >
                  0
                </a>
              </Trans>
            </p>
          }
        />
        <FAQItem
          question={t('prospectLoans.marketingPage.faqs.funding.question')}
          answer={
            <p className='text-secondary type-subhead'>
              {t('prospectLoans.marketingPage.faqs.funding.answer')}
            </p>
          }
        />
        <FAQItem
          question={t('prospectLoans.marketingPage.faqs.apply.question')}
          answer={
            <p className='text-secondary type-subhead'>
              {t('prospectLoans.marketingPage.faqs.apply.answer')}
            </p>
          }
        />
      </div>
      <p className='text-center type-default mt-6'>
        <Trans
          i18nKey='prospectLoans.marketingPage.faqs.viewMore'
          t={t}
          i18n={(i18n as any).i18next}
        >
          <a
            href='https://pos.toasttab.com/toast-capital-welcome-loan/faqs'
            rel='noreferrer noopener'
            target='_blank'
            className='underline text-link font-semibold'
          >
            0
          </a>
        </Trans>
      </p>
    </div>
  )
}
