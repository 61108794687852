import React from 'react'
import { useFormikContext } from 'formik'
import { CheckboxField } from '@toasttab/buffet-pui-forms'
import { PersonalInfoFields } from './PersonalInfoFields'

export const GuarantorFields = () => {
  const { values } = useFormikContext<{ contact: { isGuarantor: boolean } }>()

  return (
    <>
      <CheckboxField
        name='contact.isGuarantor'
        label='I am the personal guarantor entered above.'
        data-testid='contact-checkbox'
        containerClassName='col-span-2'
      />

      {!values.contact?.isGuarantor && <PersonalInfoFields />}
    </>
  )
}
