import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Panel } from '@toasttab/buffet-pui-config-templates'
import { ErrorHeading, ErrorSubheading } from '@toasttab/buffet-pui-error-pages'
import { Error500DesktopIllustration } from '@toasttab/buffet-pui-illustrations'
import { SubmitStatusContainer } from './SubmitStatusContainer'

export const Error = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <SubmitStatusContainer testId='submitted-error-page'>
      <Panel>
        <div className='flex flex-col items-center'>
          <Error500DesktopIllustration className='my-6' />
          <ErrorHeading>{t('prospectLoans.error.error')}</ErrorHeading>
          <ErrorSubheading>{t('prospectLoans.error.tryAgain')}</ErrorSubheading>
        </div>
      </Panel>
    </SubmitStatusContainer>
  )
}
