import { useEffect, useState } from 'react'
import { isEqual } from 'lodash'

export const useDeepMemo = <T extends unknown>(
  callback: () => T,
  deps: any[]
) => {
  const [cachedDeps, setCachedDeps] = useState(deps)
  const [value, setValue] = useState(callback)

  const depsAreSame = isEqual(cachedDeps, deps)

  useEffect(() => {
    if (!depsAreSame) {
      setCachedDeps(deps)
      setValue(callback)
    }
    // deps should not be a dependency,
    // we're manually checking if they changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depsAreSame])

  return value
}
