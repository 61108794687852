import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { getToastWebUrl } from '@local/get-toast-web-url'
import { SentIllustration } from '@toasttab/buffet-pui-illustrations'
import { Button } from '@toasttab/buffet-pui-buttons'

export const GoToToastWeb = (): ReactElement => {
  const { t } = useTranslation()
  const toastWebUrl = getToastWebUrl()

  return (
    <div
      data-testid='go-to-toast-web-card'
      className='flex flex-col items-center'
    >
      <SentIllustration className='h-[8.125rem] mt-8' resizeToContainer />
      <h4 className='type-headline-4 text-center mt-6'>
        {t('prospectLoans.applicationStatus.goToToastWeb.title')}
      </h4>
      <p className='max-w-[34.5rem] text-center mt-6 text-default type-default'>
        {t('prospectLoans.applicationStatus.goToToastWeb.description')}
      </p>
      <Button
        as='a'
        href={`${toastWebUrl}/financial-products`}
        target='_self'
        size='lg'
        className='mt-8 w-[21rem]'
      >
        {t('prospectLoans.applicationStatus.goToToastWeb.goToFPP')}
      </Button>
    </div>
  )
}
