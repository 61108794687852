import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { object, string, number, bool } from 'yup'

import { SubmitButton } from '@toasttab/buffet-pui-forms'

import { addressFieldsSchema } from '../AddressFields/addressFieldsSchema'
import { dateFieldSchema } from '../DateField/dateFieldSchema'
import { CheckboxField } from '@toasttab/buffet-pui-forms'
import DisclosureList from '../DisclosureList'
import InvalidInfoModal from '../InvalidInfoModal'
import FinancingApi from '../../api/Financing'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { formatDateYyyyMmDd } from '../DateField/dateFieldUtils'
import { FINANCING_METHODS } from '../../utils/utils'
import {
  OwnershipInfoFields,
  AdditionalOwnershipFields,
  GuarantorFields
} from '@local/ownership-info-fields'
import classNames from 'classnames/bind'
import styles from './OwnershipInformation.module.css'
const cx = classNames.bind(styles)

const ssnError = 'Please enter a valid 9 digit SSN/ITIN'

const OwnershipInformationSchema = object().shape({
  owner: object().shape({
    firstName: string().required('Please enter your first name'),
    lastName: string().required('Please enter your last name'),
    address: addressFieldsSchema,
    phoneNumber: string().required('Please enter your phone number'),
    email: string()
      .email('Not a valid email address')
      .required('Please enter your email'),
    disclosureCheckbox: bool().oneOf([true])
  })
})

const OwnershipInformationWithFinancingSchema = object().shape({
  owner: object().shape({
    firstName: string().required('Please enter your first name'),
    lastName: string().required('Please enter your last name'),
    ssn: string()
      .matches(/^[0-9]{9}$/, ssnError)
      .required(ssnError),
    address: addressFieldsSchema,
    dob: dateFieldSchema,
    phoneNumber: string().required('Please enter your phone number'),
    email: string()
      .email('Not a valid email address')
      .required('Please enter your email'),
    ownershipStake: number()
      .min(1, 'Must be between 1-100%')
      .max(100, 'Must be between 1-100%')
      .integer('Needs to be a whole number, cannot contain a decimal point')
      .required('Must be between 1-100%'),
    disclosureCheckbox: bool().oneOf([true])
  }),
  contact: object().shape({
    isGuarantor: bool(),
    firstName: string().when('isGuarantor', {
      is: false,
      then: string().required('Please enter your first name')
    }),
    lastName: string().when('isGuarantor', {
      is: false,
      then: string().required('Please enter your last name')
    }),
    phoneNumber: string().when('isGuarantor', {
      is: false,
      then: string().required('Please enter your phone number')
    }),
    email: string().when('isGuarantor', {
      is: false,
      then: string()
        .email('Not a valid email address')
        .required('Please enter your email')
    })
  })
})

const OwnershipInformation = ({
  contact,
  dealNumber,
  owner,
  handleOwnershipInformationSubmit,
  financingMethod
}) => {
  const [isValidationModalOpen, setIsValidationModalOpen] = useState(false)
  const isLoan = financingMethod === FINANCING_METHODS.LOAN

  const agreement = isLoan
    ? 'By checking this box:'
    : 'By checking this box, you (the individual submitting this application for a lease on behalf of the business), on behalf of yourself and the business applying for this lease:'

  return (
    <>
      <div className={cx('type-headline-2 font-bold', 'ownership-heading')}>
        {isLoan ? 'Who is your personal guarantor?' : 'Tell us about yourself.'}
      </div>

      <div
        className={cx('type-default text-secondary', 'ownership-description')}
      >
        {isLoan ? (
          <>
            Most transactions require a personal guarantor. Please provide the
            owner who can serve as the personal guarantor. If you are providing
            information on behalf of another person, you must have already
            obtained that person’s written approval to submit the information in
            connection with this credit application, and be able to provide
            Toast with a copy of that written authorization upon request.
          </>
        ) : (
          'Please fill out all required fields below. This will not affect your credit score.'
        )}
      </div>
      <InvalidInfoModal
        buttonText={'"Submit Application"'}
        infoType='ownership'
        isOpen={isValidationModalOpen}
        setIsOpen={setIsValidationModalOpen}
      />
      <OwnershipInformationForm
        agreement={agreement}
        contact={contact}
        dealNumber={dealNumber}
        handleOwnershipInformationSubmit={handleOwnershipInformationSubmit}
        isLoan={isLoan}
        openInvalidModal={setIsValidationModalOpen}
        owner={owner}
      />
    </>
  )
}

const OwnershipInformationForm = ({
  agreement,
  contact,
  dealNumber,
  handleOwnershipInformationSubmit,
  isLoan,
  openInvalidModal,
  owner
}) => {
  const [hasAttempted, setHasAttempted] = useState(false)

  return (
    <Formik
      initialValues={{ contact, owner }}
      validationSchema={
        isLoan
          ? OwnershipInformationWithFinancingSchema
          : OwnershipInformationSchema
      }
      onSubmit={(values, { setSubmitting }) => {
        const saveValues = () => {
          setSubmitting(false)
          handleOwnershipInformationSubmit(values)
        }

        if (hasAttempted || !isLoan) {
          saveValues()
        } else {
          FinancingApi.validateOwnershipInfo(dealNumber, {
            ...values.owner,
            dob: formatDateYyyyMmDd(values.owner.dob)
          })
            .then(() => saveValues())
            .catch(() => {
              setHasAttempted(true)
              openInvalidModal(true)
              setSubmitting(false)
            })
        }
      }}
    >
      {({ dirty, handleSubmit, isSubmitting }) => (
        <form
          data-testid='ownership-form'
          autoComplete='false'
          onSubmit={handleSubmit}
        >
          <div className='grid grid-cols-2 gap-y-4 gap-x-8 mb-4'>
            <OwnershipInfoFields />
            {isLoan && (
              <>
                <AdditionalOwnershipFields />
                <GuarantorFields />
              </>
            )}
          </div>

          <div className={cx('ownership-checkbox-field')}>
            <CheckboxField
              name='owner.disclosureCheckbox'
              label={agreement}
              data-testid={'owner-authorization'}
            />
            <DisclosureList isLoan={isLoan} />
          </div>

          <div className='float-left flex flex-wrap items-center'>
            <SubmitButton
              disabled={!dirty || isSubmitting}
              data-testid={'application-submit'}
            >
              Submit Application
            </SubmitButton>
            <div className='text-gray-100 type-large mx-6'>2 / 2</div>
            {isSubmitting && !hasAttempted && (
              <div className='flex items-center py-8 md:py-0'>
                <MerryGoRound size={MerryGoRound.Size.sm} />
                <div className='ml-4'>Verifying your owner info</div>
              </div>
            )}
          </div>
        </form>
      )}
    </Formik>
  )
}

OwnershipInformation.propTypes = {
  handleOwnershipInformationSubmit: PropTypes.func
}

export default OwnershipInformation
