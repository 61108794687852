export interface FinancingApplicationForm {
  merchant: {
    name: string
    tin: string
    tinType: string
    phoneNumber: number
    address: {
      street1: string
      street2: string
      city: string
      state: string
      zip: string
      country: 'US'
    }
  }
  restaurant: {
    name: string
    address: {
      street1: string
      street2: string
      city: string
      state: string
      zip: string
      country: 'US'
    }
    phoneNumber: number
    website: string
  }
  owner: {
    firstName: string
    lastName: string
    address: {
      street1: string
      street2: string
      city: string
      state: string
      zip: string
      country: 'US'
    }
    dob: string
    ssn: string
    phoneNumber: number
    ownershipStake: number
    email: string
    disclosureCheckbox: boolean
  }
  contact: {
    isGuarantor: boolean
    firstName?: string
    lastName?: string
    phoneNumber?: number
    email?: string
  }
}

export enum FinancingSteps {
  WELCOME = 'WELCOME',
  RESTAURANT_INFO = 'RESTAURANT_INFO',
  BUSINESS_INFO = 'BUSINESS_INFO',
  GUARANTOR_INFO = 'GUARANTOR_INFO',
  REVIEW_TERMS = 'REVIEW_TERMS',
  SUBMITTING = 'SUBMITTING',
  THANK_YOU = 'THANK_YOU',
  ERROR = 'ERROR'
}

export type FinancingStepProps = {
  isValid: boolean
  validateForm(): void
}

interface Address {
  street1: string
  street2: string
  city: string
  state: string
  zip: string
  country: string
}

export interface FinanceApplicationPayload {
  applicationType: 'loan'
  merchant: {
    name: string
    tin: string
    tinType: string
    address: Address
    phoneNumber: number
  }
  restaurant: {
    name: string
    address: Address
    website?: string
    phoneNumber: number
  }
  owner: {
    firstName: string
    lastName: string
    address: Address
    dob: number
    ssn: string
    phoneNumber: number
    ownershipStake: number
    email: string
    disclosureCheckbox: boolean
  }
  contact: {
    firstName: string
    lastName: string
    email: string
  }
}
