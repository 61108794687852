export const TIN_TYPES = {
  EIN: 'EIN',
  SSN: 'SSN',
  ITIN: 'ITIN'
} as Record<string, string>

export const TIN_FIELD_CONFIG = {
  EIN: {
    format: '##-#######',
    placeholder: '__-_______'
  },
  SSN: {
    format: '###-##-####',
    placeholder: '___-__-____'
  },
  ITIN: {
    format: '###-##-####',
    placeholder: '___-__-____'
  }
} as Record<string, { format: string; placeholder: string }>
