import React, { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { Panel, PageBody, Page } from '@toasttab/buffet-pui-config-templates'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { LookupChecksIcon } from '@toasttab/buffet-pui-icons'
import FinancingApi from '../../../src/api/Financing'
import { ApplicationStatusCard } from './ApplicationStatusCard'

export const ProspectLoanApplicationStatus = (): ReactElement => {
  const { t } = useTranslation()

  const { dealNumber } = useParams<{ dealNumber: string }>()

  const {
    isLoading,
    data: response,
    isError
  } = useQuery(
    ['get-prospect-loan-application', dealNumber],
    () => FinancingApi.getProspectLoanApplication(dealNumber),
    { retry: false, refetchOnWindowFocus: false }
  )

  let content

  if (isLoading) {
    content = <MerryGoRound className='pin-center' size='lg' />
  }

  if (isError) {
    content = (
      <EmptyState
        testId='empty-state'
        className='pin-center'
        icon={<LookupChecksIcon accessibility='decorative' />}
      >
        <p className='font-bold'>
          {t('prospectLoans.applicationStatus.error.title')}
        </p>
        <p>
          {t('prospectLoans.applicationStatus.error.description', {
            dealNumber
          })}
        </p>
      </EmptyState>
    )
  }

  if (response && response.data) {
    content = <ApplicationStatusCard application={response.data} />
  }

  return (
    <Page>
      <PageBody className='py-12 h-screen'>
        <Panel className='min-h-[500px] relative'>{content}</Panel>
        <p className='text-secondary type-subhead md:px-0 px-2'>
          {t('prospectLoans.applicationStatus.legalDisclaimer')}
        </p>
      </PageBody>
    </Page>
  )
}
