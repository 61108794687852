import React, { ReactElement } from 'react'
import useClassnames from '@local/use-classnames'
import { BarTooltip } from './BarTooltip'
import graphSectionStyles from './GraphSection.module.css'

export const BarContainerNoSales = (): ReactElement => {
  const cx = useClassnames(graphSectionStyles)
  return (
    <div
      className={cx(
        'bar-container',
        'flex flex-col justify-end items-center h-full w-7 md:w-16'
      )}
    >
      <div className={cx('not-available', 'h-full w-7 md:w-16')}>
        <BarTooltip sales={0} payment={0} />
      </div>
    </div>
  )
}
