export const easypayTranslations = {
  easypay: {
    welcome: {
      title: 'Welcome to Toast EasyPay',
      valueProps: {
        minimizeCosts: 'Minimize the upfront cost of Toast and pay as you go.',
        applyInMinutes: 'Apply in minutes with no credit check.',
        noEligibilityCheck:
          'The only eligibility requirement is that you become a Toast customer.'
      }
    },
    owner: {
      title: 'Tell us about yourself.',
      subtitle:
        'Please fill out all required fields below. This will not affect your credit score.'
    },
    faq: {
      title: 'Have questions about Toast EasyPay?',
      link: 'View FAQs'
    },
    review: {
      checkbox:
        'By checking this box, you (the individual submitting this application for a lease on behalf of the business), on behalf of yourself and the business applying for this lease:',
      attestations: {
        one: 'Represent that you are authorized to submit this application and to act as an agent of the business.',
        two: 'Understand that this product is a lease, subject to the terms and conditions of a Lease Agreement.',
        three:
          'Promise that everything disclosed on behalf of the business in connection with this lease is and will be true, accurate, and complete.',
        four: 'Agree to be contacted and to transact business by electronic means, including by electronic mail, and agree that PDF and electronic signatures are acceptable for all purposes in connection with this transaction. You further agree that any document required by law to be delivered to the business in writing, including the Lease agreement may be delivered to the email address on file with Toast at the time which it is delivered.',
        five: 'Understand and agree that Toast Easy Pay is a 180-day lease offered by Toast, Inc. with an option to purchase or renew. Terms and conditions apply. Toast Easy Pay may not be available in all jurisdictions. Toast, Inc. reserves the right to change or discontinue this product at any time.'
      }
    },
    thankYou: {
      title: 'Thank you for applying for Toast EasyPay!',
      body: 'Our team will be in touch shortly with next steps.'
    },
    disclosure:
      'Toast Easy Pay is a 180-day lease offered by Toast, Inc. with an option to purchase. Terms and conditions apply. Toast Easy Pay may not be available in all jurisdictions. Toast, Inc. reserves the right to change or discontinue this product at any time.'
  }
}
