import React from 'react'
import { TextInputField, SelectField } from '@toasttab/buffet-pui-forms'
import { LeadingZerosNumberInputField } from '@local/leading-zeros-number-input-field'
import { listOfStates } from './constants'
import { useTranslation } from 'react-i18next'

interface Props {
  addressLine1FieldName: string
  addressLine1FieldLabel?: string
  addressLine2FieldName: string
  addressLine2FieldLabel?: string
  cityFieldName: string
  stateFieldName: string
  zipCodeFieldName: string
  addressHelperText?: string
  dataTestIdPrefix: string
  hideLabels?: boolean
}

export const AddressFields = ({
  addressLine1FieldName,
  addressLine1FieldLabel = 'Address Line 1',
  addressLine2FieldName,
  addressLine2FieldLabel = 'Address Line 2 (Optional)',
  cityFieldName,
  stateFieldName,
  zipCodeFieldName,
  addressHelperText,
  dataTestIdPrefix,
  hideLabels
}: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <TextInputField
        className='fs-mask'
        containerClassName='col-span-2'
        autoComplete='false'
        name={addressLine1FieldName}
        label={hideLabels ? undefined : addressLine1FieldLabel}
        placeholder={hideLabels ? addressLine1FieldLabel : undefined}
        aria-label={addressLine1FieldLabel}
        helperText={addressHelperText}
        data-testid={`${dataTestIdPrefix}-address-line1`}
      />

      <TextInputField
        className='fs-mask'
        containerClassName='col-span-2'
        autoComplete='false'
        name={addressLine2FieldName}
        label={hideLabels ? undefined : addressLine2FieldLabel}
        placeholder={hideLabels ? addressLine2FieldLabel : undefined}
        aria-label={addressLine2FieldLabel}
        data-testid={`${dataTestIdPrefix}-address-line2`}
      />

      <TextInputField
        className='fs-mask'
        containerClassName='col-span-2'
        autoComplete='false'
        name={cityFieldName}
        label={
          hideLabels ? undefined : t('common.restaurant.fields.city.label')
        }
        placeholder={
          hideLabels ? t('common.restaurant.fields.city.label') : undefined
        }
        aria-label={t('common.restaurant.fields.city.label')}
        data-testid={`${dataTestIdPrefix}-city`}
      />

      {/* @ts-ignore */}
      <SelectField
        containerClassName='col-span-2 md:col-span-1'
        options={listOfStates}
        name={stateFieldName}
        label={
          hideLabels ? undefined : t('common.restaurant.fields.state.label')
        }
        placeholder={
          hideLabels ? t('common.restaurant.fields.state.label') : undefined
        }
        aria-label={t('common.restaurant.fields.state.label')}
        testId={`${dataTestIdPrefix}-state`}
      />

      <LeadingZerosNumberInputField
        className='fs-mask'
        containerClassName='col-span-2 md:col-span-1'
        autoComplete='false'
        name={zipCodeFieldName}
        label={hideLabels ? undefined : t('common.restaurant.fields.zip.label')}
        placeholder={
          hideLabels ? t('common.restaurant.fields.zip.label') : '_____'
        }
        aria-label={t('common.restaurant.fields.zip.label')}
        format='#####'
        mask='_'
        data-testid={`${dataTestIdPrefix}-zip`}
      />
    </>
  )
}
