import React, { ReactElement, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import i18n from 'i18next'
import { PageAside } from '@toasttab/buffet-pui-wizard-templates'
import {
  BackOfficeDisplayIcon,
  QuestionAnswerDisplayIcon
} from '@toasttab/buffet-pui-icons'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'

interface ModalContent {
  title: string
  description: string
}

export const FinancesAside = (): ReactElement => {
  const { t } = useTranslation()
  const [faqModalContent, setFaqModalContent] = useState<ModalContent>()

  return (
    <>
      <PageAside>
        <div className='flex items-start'>
          <BackOfficeDisplayIcon accessibility='decorative' className='mr-6' />
          <div>
            <p className='font-semibold type-default mb-4'>
              {t('prospectLoans.finances.aside.taxReturn')}
            </p>
            <p className='text-secondary type-subhead mb-4'>
              {t('prospectLoans.finances.aside.faqs.title')}
            </p>
            <Button
              className='font-normal !text-left !h-auto mb-4'
              variant='text-link'
              onClick={() =>
                setFaqModalContent({
                  title: t(
                    'prospectLoans.finances.aside.faqs.businessRevenue.question'
                  ),
                  description: t(
                    'prospectLoans.finances.aside.faqs.businessRevenue.answer'
                  )
                })
              }
            >
              <span className='text-wrap font-normal'>
                {t(
                  'prospectLoans.finances.aside.faqs.businessRevenue.question'
                )}
              </span>
            </Button>
            <Button
              className='font-normal !text-left !h-auto'
              variant='text-link'
              onClick={() =>
                setFaqModalContent({
                  title: t(
                    'prospectLoans.finances.aside.faqs.soleProprietor.question'
                  ),
                  description: t(
                    'prospectLoans.finances.aside.faqs.soleProprietor.answer'
                  )
                })
              }
            >
              <span className='text-wrap font-normal'>
                {t('prospectLoans.finances.aside.faqs.soleProprietor.question')}
              </span>
            </Button>
          </div>
        </div>
        <div className='flex items-start mt-8'>
          <QuestionAnswerDisplayIcon
            accessibility='decorative'
            className='mr-6'
          />
          <div>
            <p className='font-semibold type-default mb-4'>
              {t('prospectLoans.finances.aside.debt.header')}
            </p>
            <p className='text-secondary type-subhead mb-2'>
              {t('prospectLoans.finances.aside.debt.subheader')}
            </p>
            <ul className='list-disc pl-6 my-2 text-secondary type-subhead'>
              <li>{t('prospectLoans.finances.aside.debt.creditCard')}</li>
              <li>{t('prospectLoans.finances.aside.debt.termLoan')}</li>
              <li>{t('prospectLoans.finances.aside.debt.mortgage')}</li>
              <li>{t('prospectLoans.finances.aside.debt.mca')}</li>
              <li>{t('prospectLoans.finances.aside.debt.equipment')}</li>
              <li>{t('prospectLoans.finances.aside.debt.other')}</li>
            </ul>
            <p className='text-secondary type-subhead mb-2'>
              {t('prospectLoans.finances.aside.debt.footer')}
            </p>
          </div>
        </div>
        <div className='mt-auto'>
          <p className='type-subhead font-semibold'>
            {t('prospectLoans.finances.aside.needMoreHelp.headline')}
          </p>
          <p className='type-subhead'>
            <Trans
              i18nKey='prospectLoans.finances.aside.needMoreHelp.contactInfo'
              t={t}
              i18n={(i18n as any).i18next}
            >
              <a
                href='tel:6172093198'
                className='text-link underline font-semibold'
              >
                0
              </a>
              <a
                className='text-link underline font-semibold'
                href='mailto:capital@toasttab.com'
              >
                1
              </a>
            </Trans>
          </p>
          <p className='type-subhead text-secondary'>
            {t('prospectLoans.finances.aside.needMoreHelp.capitalSupportHours')}
          </p>
        </div>
      </PageAside>
      <Modal
        isOpen={!!faqModalContent}
        onRequestClose={() => setFaqModalContent(undefined)}
      >
        <ModalHeader>{faqModalContent?.title}</ModalHeader>
        <ModalBody>{faqModalContent?.description}</ModalBody>
        <ModalFooter>
          <Button onClick={() => setFaqModalContent(undefined)}>
            {t('prospectLoans.finances.aside.faqs.close')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
