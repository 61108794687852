import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import i18n from 'i18next'
import { ToastLogoLink } from '@toasttab/buffet-pui-toast-logo'
import {
  PaySummaryIllustration,
  SentIllustration
} from '@toasttab/buffet-pui-illustrations'
import {
  CheckCircleSelectedIcon,
  LockLockedIcon,
  PrepTimeDisplayIcon,
  ToastDeliveryServicesDisplayIcon,
  PriceIncreaseDisplayIcon,
  CafeBakeryDisplayIcon
} from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { DisclaimerLink } from './DisclaimerLink'
import { Testimonials } from './Testimonials'
import { HowDoesItWork } from './HowDoesItWork'
import { InteractiveRepaymentGraph } from './InteractiveRepaymentGraph'
import { FAQs } from './FAQs'
import { Disclaimers } from './Disclaimers'

interface Props {
  thankYou?: React.ReactElement<{}>
  disclaimer?: React.ReactElement<{}>
}

export const MarketingPage = ({
  thankYou,
  disclaimer
}: Props): ReactElement => {
  const { t } = useTranslation()
  const history = useHistory()

  const handleClickApplyNow = () => {
    history.push(`/welcome-loan`)
  }

  return (
    <div className='bg-white w-full' data-testid='wl-marketing-page'>
      <ToastLogoLink href='/' size='lg' />
      <div className='px-4 md:px-12 lg:px-24 xl:px-48'>
        {thankYou && (
          <div className='space-y-4 justify-items-center border-b-2'>
            <SentIllustration resizeToContainer className='h-[8rem] mr-4' />
            {thankYou}
            {disclaimer}
          </div>
        )}
        <div className='flex flex-col-reverse lg:flex-row justify-between mt-14'>
          <div className='lg:max-w-[35.25rem]'>
            <h2 className='type-overline font-bold text-link mb-4'>
              {t('prospectLoans.marketingPage.title')}
            </h2>
            <h2 className='text-center lg:text-start type-headline-2 font-medium'>
              <Trans
                i18nKey='prospectLoans.marketingPage.upgrading'
                t={t}
                i18n={(i18n as any).i18next}
              >
                <DisclaimerLink className='!type-large'>0</DisclaimerLink>
              </Trans>
            </h2>
            <div className='flex items-center mt-8'>
              <CheckCircleSelectedIcon
                className='text-secondary mr-4'
                accessibility='decorative'
              />
              <p className='text-secondary'>
                {t('prospectLoans.marketingPage.eligible')}
              </p>
            </div>
            <div className='flex items-center mt-4'>
              <CheckCircleSelectedIcon
                className='text-secondary mr-4'
                accessibility='decorative'
              />
              <p className='text-secondary'>
                {t('prospectLoans.marketingPage.requestFunding')}
              </p>
            </div>
            <div className='flex items-center mt-4'>
              <CheckCircleSelectedIcon
                className='text-secondary mr-4'
                accessibility='decorative'
              />
              <p className='text-secondary'>
                {t('prospectLoans.marketingPage.noPersonalGuarantee')}
              </p>
            </div>
            <Button
              testId='primary-apply-now-button'
              onClick={handleClickApplyNow}
              className='w-full lg:w-auto min-w-[14.5rem] mt-6'
              size='lg'
            >
              {t('prospectLoans.marketingPage.applyNow')}
            </Button>
            <div className='flex items-center mt-4'>
              <LockLockedIcon
                className='text-secondary mr-4'
                accessibility='decorative'
              />
              <p className='text-secondary'>
                {t('prospectLoans.marketingPage.creditScore')}
              </p>
            </div>
          </div>
          <PaySummaryIllustration
            resizeToContainer
            className='m-auto w-full lg:m-0 mb-6 xxl:mb-0 sm:w-[31.25rem] xxl:w-[35rem]'
          />
        </div>
        <div data-testid='why-choose-toast-capital-section' className='mt-12'>
          <h3 className='type-headline-3 text-center mb-8'>
            {t('prospectLoans.marketingPage.whyChooseToastCapital')}
          </h3>
          <div className='flex justify-between flex-wrap lg:flex-nowrap'>
            <div className='w-full sm:w-5/12 lg:w-[13.625rem] mb-8 lg:mb-0'>
              <PrepTimeDisplayIcon
                className='mb-4'
                accessibility='decorative'
              />
              <p className='type-large font-bold mb-4'>
                {t('prospectLoans.marketingPage.quickApplication.title')}
              </p>
              <p className='type-subhead'>
                {t('prospectLoans.marketingPage.quickApplication.description')}
              </p>
            </div>
            <div className='w-full sm:w-5/12 lg:w-[13.625rem] mb-8 lg:mb-0'>
              <ToastDeliveryServicesDisplayIcon
                className='mb-4'
                accessibility='decorative'
              />
              <p className='type-large font-bold mb-4'>
                {t('prospectLoans.marketingPage.receiveFundsQuickly.title')}
              </p>
              <p className='type-subhead'>
                {t(
                  'prospectLoans.marketingPage.receiveFundsQuickly.description'
                )}
              </p>
            </div>
            <div className='w-full sm:w-5/12 lg:w-[13.625rem] mb-8 lg:mb-0'>
              <PriceIncreaseDisplayIcon
                className='mb-4'
                accessibility='decorative'
              />
              <p className='type-large font-bold mb-4'>
                {t('prospectLoans.marketingPage.oneFixedFee.title')}
              </p>
              <p className='type-subhead'>
                {t('prospectLoans.marketingPage.oneFixedFee.description')}
              </p>
            </div>
            <div className='w-full sm:w-5/12 lg:w-[13.625rem] mb-8 lg:mb-0'>
              <CafeBakeryDisplayIcon
                className='mb-4'
                accessibility='decorative'
              />
              <p className='type-large font-bold mb-4'>
                {t('prospectLoans.marketingPage.automatedRepayment.title')}
              </p>
              <p className='type-subhead'>
                {t(
                  'prospectLoans.marketingPage.automatedRepayment.description'
                )}
              </p>
            </div>
          </div>
        </div>
        <Testimonials />
        <HowDoesItWork />
        <InteractiveRepaymentGraph handleClickApplyNow={handleClickApplyNow} />
        <FAQs />
        <Disclaimers />
      </div>
    </div>
  )
}
