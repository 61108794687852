import React, { PropsWithChildren, ReactElement } from 'react'

interface Props {
  label: string | ReactElement
  testId?: string
}
export const ReviewField = ({
  label,
  children,
  testId
}: PropsWithChildren<Props>): ReactElement => {
  return (
    <>
      <h4
        data-testid={testId && `${testId}-review-field-label`}
        className='type-default font-semibold mt-6 pb-1'
      >
        {label}
      </h4>
      <span
        data-testid={testId && `${testId}-review-field-value`}
        className='type-default'
      >
        {children}
      </span>
    </>
  )
}
