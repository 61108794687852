import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { PageAside } from '@toasttab/buffet-pui-wizard-templates'
import { HelpDisplayIcon } from '@toasttab/buffet-pui-icons'

export const OwnershipAside = (): ReactElement => {
  const { t } = useTranslation()
  return (
    <PageAside>
      <div className='flex mb-12'>
        <HelpDisplayIcon className='mr-6' accessibility='decorative' />
        <div>
          <p className='font-medium text-default mb-2'>
            {t('prospectLoans.ownership.aside.beneficialOwner.title')}
          </p>
          <p className='text-secondary type-subhead'>
            {t('prospectLoans.ownership.aside.beneficialOwner.description')}
          </p>
        </div>
      </div>
      <div className='flex'>
        <HelpDisplayIcon className='mr-6' accessibility='decorative' />
        <div>
          <p className='font-medium text-default mb-2'>
            {t('prospectLoans.ownership.aside.controlPerson.title')}
          </p>
          <p className='text-secondary type-subhead'>
            {t('prospectLoans.ownership.aside.controlPerson.description')}
          </p>
        </div>
      </div>
    </PageAside>
  )
}
