import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useBanquetProps } from 'banquet-runtime-modules'
import { Currency, formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import { format, Formats } from '@toasttab/buffet-pui-date-utilities'
import { ReviewSection } from './ReviewSection'
import { ReviewField } from './ReviewField'
import { lastYear } from '../Finances/constants'
import { booleanToYesNo, stringToBoolean, convertIntToDate } from '../utils'
import { ProspectLoanFormValues, ProspectLoanSteps } from '../types'
import { DebtReview } from './DebtReview'

interface Props {
  values: ProspectLoanFormValues
  goToStep(step: ProspectLoanSteps): void
}

export const FinancesReview = ({ values, goToStep }: Props): ReactElement => {
  const { t } = useTranslation()
  const { i18n } = useBanquetProps()

  return (
    <ReviewSection
      title={t('prospectLoans.review.finances')}
      onEditClick={() => goToStep(ProspectLoanSteps.FINANCES)}
    >
      <div className='mt-6'>
        <p className='font-semibold'>
          {t('prospectLoans.finances.businessRevenue.title')}
        </p>
        <ReviewField
          testId='threeMonthTotalSales'
          label={t(
            'prospectLoans.finances.businessRevenue.fields.threeMonthTotalSales.label'
          )}
        >
          {formatCurrency(
            {
              amount: values.threeMonthTotalSales,
              currency: i18n?.currency ?? Currency.USD
            },
            i18n?.language ?? 'en-US',
            {
              precision: 0
            }
          )}
        </ReviewField>
        <ReviewField
          testId='hasFiledPriorYearTaxes'
          label={t(
            'prospectLoans.finances.businessRevenue.fields.hasFiledPriorYearTaxes.label',
            { year: lastYear.value }
          )}
        >
          {booleanToYesNo(stringToBoolean(values.hasFiledPriorYearTaxes), t)}
        </ReviewField>
        <ReviewField
          testId='netIncome'
          label={`${t(
            `prospectLoans.finances.businessRevenue.fields.${
              values.hasFiledPriorYearTaxes ? 'netIncome' : 'projectedNetIncome'
            }.label`
          )} (${t(
            `prospectLoans.finances.businessRevenue.fields.${
              values.hasFiledPriorYearTaxes
                ? 'filingYear'
                : 'anticipatedFilingYear'
            }.label`
          )})`}
        >
          {formatCurrency(
            {
              amount: values.netIncome || 0,
              currency: i18n?.currency ?? Currency.USD
            },
            i18n?.language ?? 'en-US',
            {
              precision: 0
            }
          )}{' '}
          ({values.filingYear})
        </ReviewField>
      </div>
      <div className='mt-8'>
        <p className='font-semibold'>
          {t('prospectLoans.finances.businessDebt.title')}
        </p>
        <DebtReview values={values} />
      </div>
      <div className='mt-8'>
        <p className='font-semibold'>
          {t('prospectLoans.finances.businessDeclarations.title')}
        </p>
        <ReviewField
          label={t(
            'prospectLoans.finances.businessDeclarations.fields.isLeasing.label'
          )}
          testId='isLeasing'
        >
          {stringToBoolean(values.isLeasing)
            ? `${t('prospectLoans.review.hasLease', {
                date: format(
                  convertIntToDate(values.leaseEndDate!),
                  Formats.date.short,
                  i18n?.language ?? 'en-US'
                )
              })}`
            : t('prospectLoans.shared.no')}
        </ReviewField>
        <ReviewField
          label={t(
            'prospectLoans.finances.businessDeclarations.fields.hasTaxLiens.label'
          )}
        >
          {booleanToYesNo(stringToBoolean(values.hasTaxLiens), t)}
          {stringToBoolean(values.hasTaxLiens) && (
            <>
              .{' ('}
              {formatCurrency(
                {
                  amount: values.taxLienAmount || 0,
                  currency: i18n?.currency ?? Currency.USD
                },
                i18n?.language ?? 'en-US',
                {
                  precision: 0
                }
              )}{' '}
              {t('prospectLoans.review.outstanding')}{' '}
              {values.taxLienFilingDate?.toLocaleLowerCase()}{' '}
              {t('prospectLoans.review.ago')})
            </>
          )}
        </ReviewField>
        <ReviewField
          testId='hasJudgementsOrLawsuits'
          label={t(
            'prospectLoans.finances.businessDeclarations.fields.hasJudgementsOrLawsuits.label'
          )}
        >
          {booleanToYesNo(stringToBoolean(values.hasJudgementsOrLawsuits), t)}
        </ReviewField>
        <ReviewField
          label={t(
            'prospectLoans.finances.businessDeclarations.fields.hasBankruptcy.label'
          )}
        >
          {booleanToYesNo(stringToBoolean(values.hasBankruptcy), t)}
        </ReviewField>
        <ReviewField
          testId='hasOwnershipChanged'
          label={t(
            'prospectLoans.finances.businessDeclarations.fields.hasOwnershipChanged.label'
          )}
        >
          {booleanToYesNo(stringToBoolean(values.hasOwnershipChanged), t)}
        </ReviewField>
        <ReviewField
          testId='hasHistoryOfClosure'
          label={t(
            'prospectLoans.finances.businessDeclarations.fields.hasHistoryOfClosure.label'
          )}
        >
          {booleanToYesNo(stringToBoolean(values.hasHistoryOfClosure), t)}
        </ReviewField>
        <ReviewField
          testId='hasPlannedChangeOfOwnership'
          label={t(
            'prospectLoans.finances.businessDeclarations.fields.hasPlannedChangeOfOwnership.label'
          )}
        >
          {booleanToYesNo(
            stringToBoolean(values.hasPlannedChangeOfOwnership),
            t
          )}
        </ReviewField>
        <ReviewField
          testId='hasPlannedLocationChange'
          label={t(
            'prospectLoans.finances.businessDeclarations.fields.hasPlannedLocationChange.label'
          )}
        >
          {booleanToYesNo(stringToBoolean(values.hasPlannedLocationChange), t)}
        </ReviewField>
      </div>
    </ReviewSection>
  )
}
