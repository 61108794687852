import React, { ReactElement } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import i18n from 'i18next'
import { Button } from '@toasttab/buffet-pui-buttons'
import { DisclaimerLink } from '../DisclaimerLink'
import { GraphSection } from './GraphSection'

interface Props {
  handleClickApplyNow: () => void
}

export const InteractiveRepaymentGraph = ({
  handleClickApplyNow
}: Props): ReactElement => {
  const { t } = useTranslation()

  return (
    <div
      data-testid='interactive-repayment-graph'
      className='mt-14 flex flex-col items-center'
    >
      <h3 className='type-headline-3 text-center'>
        {t('prospectLoans.marketingPage.graphSection.title')}
      </h3>
      <p className='text-center max-w-[41rem] mt-6'>
        <Trans
          i18nKey='prospectLoans.marketingPage.graphSection.subhead'
          t={t}
          i18n={(i18n as any).i18next}
        >
          <DisclaimerLink className='!type-caption'>0</DisclaimerLink>
        </Trans>
      </p>
      <div className='w-full mt-8'>
        <GraphSection />
      </div>
      <p className='text-secondary text-center type-caption'>
        {t('prospectLoans.marketingPage.graphSection.disclaimer')}
      </p>
      <Button
        onClick={handleClickApplyNow}
        className='w-full lg:w-auto min-w-[14.5rem] mt-8'
        size='lg'
        variant='secondary'
      >
        {t('prospectLoans.marketingPage.applyNow')}
      </Button>
    </div>
  )
}
