import React, { ReactElement, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import i18n from 'i18next'
import {
  PageBody,
  PageMain,
  ContentFooter,
  ContinueButton,
  BackButton
} from '@toasttab/buffet-pui-wizard-templates'
import { SelectField } from '@toasttab/buffet-pui-forms'
import { FlowProps } from '@local/use-flow-navigation'
import { RestaurantInfoFields } from '@local/restaurant-info-fields'
import { ProspectLoanStepProps } from './types'
import { PageTitle } from './shared'
import { IconButtonWithTooltip } from '@toasttab/buffet-pui-tooltip'
import { InfoIcon } from '@toasttab/buffet-pui-icons'

export const RestaurantInfo = ({
  isValid,
  validateForm,
  goToNextStep,
  goToPreviousStep
}: FlowProps<ProspectLoanStepProps>): ReactElement => {
  const { t } = useTranslation()

  useEffect(() => {
    validateForm()
  }, [validateForm])

  return (
    <PageBody testId='prospect-loans-restaurant-info-page'>
      <PageMain>
        <p className='uppercase font-semibold type-overline text-secondary mb-6'>
          {t('prospectLoans.businessInfo.stepOne')}
        </p>
        <PageTitle
          title={t('prospectLoans.businessInfo.title')}
          description={t('prospectLoans.businessInfo.restaurantDescription')}
        />
        <p className='type-large text-default font-medium mt-6 mb-4'>
          {t('common.restaurant.fields.title')}
        </p>
        <RestaurantInfoFields showRequiredLabel className='mb-6' />
        <div className='grid grid-cols-2 gap-y-4 gap-x-8'>
          <div className='col-span-2 md:col-span-1'>
            <label
              className='flex w-full mb-1 max-h-6 items-center type-default font-semibold text-default'
              htmlFor='social-score-select-field'
            >
              <span>
                <span className='text-error'>*&nbsp;</span>
                {t('prospectLoans.businessInfo.fields.socialScore.label')}
              </span>
              <IconButtonWithTooltip
                icon={
                  <InfoIcon
                    accessibility='decorative'
                    className='-mt-1 -mb-1'
                  />
                }
                tooltipContent={
                  <Trans
                    i18nKey='prospectLoans.businessInfo.fields.socialScore.tooltip'
                    t={t}
                    i18n={(i18n as any).i18next}
                  >
                    <sup>0</sup>
                  </Trans>
                }
              />
            </label>
            <SelectField
              id='social-score-select-field'
              aria-label='socialScore'
              placement='top'
              testId='socialScore'
              name='socialScore'
              options={[
                {
                  label: t(
                    'prospectLoans.businessInfo.fields.socialScore.options.THREE_STARS'
                  ),
                  value: 'THREE_STARS'
                },
                {
                  label: t(
                    'prospectLoans.businessInfo.fields.socialScore.options.THREE_TO_THREE_AND_A_HALF_STARS'
                  ),
                  value: 'THREE_TO_THREE_AND_A_HALF_STARS'
                },
                {
                  label: t(
                    'prospectLoans.businessInfo.fields.socialScore.options.THREE_AND_A_HALF_TO_FOUR_STARS'
                  ),
                  value: 'THREE_AND_A_HALF_TO_FOUR_STARS'
                },
                {
                  label: t(
                    'prospectLoans.businessInfo.fields.socialScore.options.FOUR_TO_FOUR_AND_A_HALF_STARS'
                  ),
                  value: 'FOUR_TO_FOUR_AND_A_HALF_STARS'
                },
                {
                  label: t(
                    'prospectLoans.businessInfo.fields.socialScore.options.FOUR_AND_A_HALF_PLUS'
                  ),
                  value: 'FOUR_AND_A_HALF_PLUS'
                }
              ]}
            />
          </div>
        </div>
        <ContentFooter className='mt-6 py-6 bg-white'>
          <div className='flex justify-end w-full'>
            <BackButton onClick={goToPreviousStep} />
            <ContinueButton
              onClick={goToNextStep}
              disabled={!isValid}
              className='mr-6'
              testId='restaurant-info'
            >
              {t('prospectLoans.shared.continue')}
            </ContinueButton>
          </div>
        </ContentFooter>
      </PageMain>
    </PageBody>
  )
}
