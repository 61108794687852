import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@toasttab/buffet-pui-buttons'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import { CurrencyField, SelectField } from '@toasttab/buffet-pui-forms'
import { DeleteIcon } from '@toasttab/buffet-pui-icons'
import { createDebtTypes } from './debtTypes'
import { Currency } from '@toasttab/buffet-pui-types'

interface Props {
  onDelete(): void

  index: number
  showDeleteButton: boolean
  isLastEntry?: boolean
  disabled?: boolean
}

export const DebtItem = ({
  index,
  onDelete,
  showDeleteButton,
  isLastEntry = false,
  disabled = false
}: Props): ReactElement => {
  const { t } = useTranslation()
  return (
    <div>
      <div className='flex space-x-6 items-end'>
        <SelectField
          preserveHelpSpace
          disabled={disabled}
          options={createDebtTypes(t)}
          containerClassName='flex-1 w-1/2'
          name={`debtList[${index}].debtType`}
          label={t('prospectLoans.finances.businessDebt.fields.debtType.label')}
          testId={`debtList[${index}].debtType`}
        />
        <CurrencyField
          currency={Currency.USD}
          preserveHelpSpace
          disabled={disabled}
          containerClassName='flex-1'
          name={`debtList[${index}].debtAmount`}
          label={t(
            'prospectLoans.finances.businessDebt.fields.debtAmount.label'
          )}
          thousandSeparator
          decimalScale={0}
          placeholder='0.00'
          testId={`debtList[${index}].debtAmount`}
        />
      </div>
      {showDeleteButton && (
        <Button
          disabled={disabled}
          variant='text-link'
          className='!text-error mt-4'
          iconLeft={<DeleteIcon accessibility='decorative' />}
          onClick={onDelete}
          testId={`debtList[${index}]-delete-button`}
        >
          {t('prospectLoans.finances.businessDebt.delete')}
        </Button>
      )}
      {!isLastEntry && <DividingLine />}
    </div>
  )
}
