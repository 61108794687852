import React, { ReactElement } from 'react'

interface Props {
  infoTextParagraph1: string
  infoTextParagraph2?: string
}

const TooltipContent = ({
  infoTextParagraph1,
  infoTextParagraph2
}: Props): ReactElement => {
  return (
    <div className='type-subhead w-52 text-gray-25 bg-gray-125'>
      <div>{infoTextParagraph1}</div>
      {infoTextParagraph2 && (
        <div>
          <br />
          {infoTextParagraph2}
        </div>
      )}
    </div>
  )
}
export default TooltipContent
