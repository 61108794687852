import React, { ReactElement } from 'react'
import { PageBody, PageMain } from '@toasttab/buffet-pui-wizard-templates'
import { EasyPayAside } from './EasyPayAside'
import { EasyPayThankYouMessage } from './EasyPayThankYouMessage'

export const EasyPayThankYou = (): ReactElement => {
  return (
    <>
      <PageBody testId='thank-you-page'>
        <PageMain>
          <EasyPayThankYouMessage />
        </PageMain>
        <EasyPayAside />
      </PageBody>
    </>
  )
}
