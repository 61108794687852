import './FinancingApplication.module.css'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import BusinessInformation from '../BusinessInformation'
import OwnershipInformation from '../OwnershipInformation'
import ToastLogo from '../ToastLogo'
import ThankYou from '../ThankYou'
import { formatDateYyyyMmDd } from '../DateField/dateFieldUtils'
import { Loading } from '../Loading/Loading'
import { Error } from '../Error/Error'
import {
  FINANCING_METHODS,
  FINANCING_DISCLOSURE,
  LEASE_DISCLOSURE,
  TIN_TYPES,
  DEAL_NUMBER_REGEX
} from '../../utils/utils'
import * as Sentry from '@sentry/browser'

import {
  BUSINESS_INFORMATION_SCREEN,
  OWNERSHIP_INFORMATION_SCREEN,
  THANK_YOU_SCREEN,
  LOADING_SCREEN,
  ERROR_SCREEN
} from '../../constants/Screens'
import FinancingApi from '../../api/Financing'

const initialState = {
  merchant: {
    name: '',
    tin: '',
    tinType: TIN_TYPES.EIN,
    address: {
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
      country: 'US'
    }
  },
  restaurant: {
    name: '',
    address: {
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
      country: 'US'
    },
    website: ''
  },
  owner: {
    firstName: '',
    lastName: '',
    address: {
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
      country: 'US'
    },
    dob: '',
    ssn: '',
    phoneNumber: '',
    ownershipStake: '',
    email: '',
    disclosureCheckbox: false
  },
  contact: {
    isGuarantor: true,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: ''
  }
}

const FinancingApplication = ({ financingMethod }) => {
  const history = useHistory()
  let { number } = useParams()
  const dealNumber = number || ''
  const isLease = financingMethod === FINANCING_METHODS.LEASE
  const isLoan = financingMethod === FINANCING_METHODS.LOAN

  // react router has a regex to validate, but it allows capital letters, so we check again here
  // if invalid, redirect them to welcome page to re-enter
  if (!dealNumber.match(DEAL_NUMBER_REGEX)) {
    if (isLoan) {
      history.push('/financing')
    } else {
      history.push('/easypay')
    }
  }

  const [currentScreen, setCurrentScreen] = useState(
    BUSINESS_INFORMATION_SCREEN
  )
  const [merchant, setMerchant] = useState(initialState.merchant)
  const [restaurant, setRestaurant] = useState(initialState.restaurant)
  const [owner, setOwner] = useState(initialState.owner)
  const [contact, setContact] = useState(initialState.contact)

  const advanceCurrentScreen = () => {
    switch (currentScreen) {
      case BUSINESS_INFORMATION_SCREEN:
        setCurrentScreen(OWNERSHIP_INFORMATION_SCREEN)
        break

      case OWNERSHIP_INFORMATION_SCREEN:
        setCurrentScreen(LOADING_SCREEN)
        break

      default:
        setCurrentScreen(BUSINESS_INFORMATION_SCREEN)
    }
  }

  const onClickScrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  const onBusinessInformationSubmit = (businessInformation) => {
    businessInformation.merchant.address.state = formatState(
      businessInformation.merchant.address.state
    )
    businessInformation.restaurant.address.state = formatState(
      businessInformation.restaurant.address.state
    )

    setMerchant(businessInformation.merchant)
    setRestaurant(businessInformation.restaurant)
    advanceCurrentScreen()
    onClickScrollToTop()
  }

  const formatOwnerInfo = (owner) => {
    const formattedOwner = { ...owner }
    const formattedAddress = { ...owner.address }
    formattedOwner.dob = formatDateYyyyMmDd(formattedOwner.dob)

    formattedAddress.state = formatState(formattedOwner.address.state)

    formattedOwner.address = formattedAddress

    // setting these to null for lease applications
    if (isLease) {
      formattedOwner.dob = null
      formattedOwner.ssn = null
      formattedOwner.ownershipStake = null
    }

    return formattedOwner
  }

  const onOwnershipInformationSubmit = (ownerInformation) => {
    setOwner(ownerInformation.owner)
    setContact(ownerInformation.contact)
    const formattedOwnerInfo = formatOwnerInfo(ownerInformation.owner)

    const payload = {
      merchant: merchant,
      restaurant: restaurant,
      owner: formattedOwnerInfo,
      applicationType: financingMethod
    }

    if (isLoan) {
      const info = ownerInformation
      const isGuarantor = info.contact.isGuarantor

      payload.contact = {
        firstName: isGuarantor ? info.owner.firstName : info.contact.firstName,
        lastName: isGuarantor ? info.owner.lastName : info.contact.lastName,
        phoneNumber: (isGuarantor
          ? info.owner.phoneNumber
          : info.contact.phoneNumber
        ).toString(),
        email: isGuarantor ? info.owner.email : info.contact.email
      }
    }

    advanceCurrentScreen()
    FinancingApi.submitApplication(dealNumber, payload).then(
      (res) => {
        setCurrentScreen(THANK_YOU_SCREEN)
      },
      (error) => {
        Sentry.captureException(error)
        setCurrentScreen(ERROR_SCREEN)
      }
    )
  }

  const renderCurrentPage = (financingMethod) => {
    switch (currentScreen) {
      case OWNERSHIP_INFORMATION_SCREEN:
        return (
          <OwnershipInformation
            dealNumber={dealNumber}
            contact={contact}
            owner={owner}
            handleOwnershipInformationSubmit={onOwnershipInformationSubmit}
            financingMethod={financingMethod}
          />
        )

      case THANK_YOU_SCREEN:
        return <ThankYou financingMethod={financingMethod} />

      case BUSINESS_INFORMATION_SCREEN:
      default:
        return (
          <BusinessInformation
            dealNumber={dealNumber}
            restaurant={restaurant}
            merchant={merchant}
            handleBusinessInformationSubmit={onBusinessInformationSubmit}
            financingMethod={financingMethod}
          />
        )

      case LOADING_SCREEN:
        return <Loading />

      case ERROR_SCREEN:
        return <Error isLease={isLease} />
    }
  }

  return (
    <div className='sm:container relative p-4 sm:px-12 sm:py-10 lg:px-40 text-default'>
      <ToastLogo />
      <div className='flex md:ml-6'>
        <div>{renderCurrentPage(financingMethod)}</div>
      </div>
      <div className='flex mt-12 md:ml-6'>
        <div className='type-caption text-gray-75 leading-4'>
          {isLease ? LEASE_DISCLOSURE : FINANCING_DISCLOSURE}
          <a
            className='font-bold pl-1 hover:underline'
            href='https://pos.toasttab.com/privacy'
            // eslint-disable-next-line react/jsx-no-target-blank
            target='_blank'
            rel='noreferrer'
          >
            Privacy Statement
          </a>
        </div>
      </div>
    </div>
  )
}

const formatState = (state) => {
  return state.value ? state.value : state
}

export default FinancingApplication
