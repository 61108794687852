import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useBanquetProps } from 'banquet-runtime-modules'
import { CelebrationIllustration } from '@toasttab/buffet-pui-illustrations'
import {
  Currency,
  formatCurrency,
  formatPercent
} from '@toasttab/buffet-pui-number-utilities'
import { APPLICATION_STAGE, ProspectLoanApplication } from '../types'
import { Button } from '@toasttab/buffet-pui-buttons'
import { getToastWebUrl } from '@local/get-toast-web-url'

interface Props {
  application: ProspectLoanApplication
}

export const OfferApproved = ({ application }: Props): ReactElement => {
  const { t } = useTranslation()
  const { i18n } = useBanquetProps()
  const language = i18n?.language ?? 'en-US'
  const currency = i18n?.currency ?? Currency.USD
  const isPreApproved = application.stage === APPLICATION_STAGE.PREAPPROVED
  const toastWebUrl = getToastWebUrl()

  return (
    <div
      data-testid='offer-approved-card'
      className='flex flex-col items-center'
    >
      <CelebrationIllustration resizeToContainer className='h-[8.125rem]' />
      <h4 className='type-headline-4 text-center max-w-[43.75rem] mt-8'>
        {t('prospectLoans.applicationStatus.offerApproved.title')}
      </h4>
      <div className='mt-6 flex flex-col sm:flex-row justify-center items-center sm:items-start'>
        <div className='flex flex-col items-center sm:mr-8 mb-6 sm:mb-0'>
          <div className='flex items-center'>
            <div className='bg-gray-30 h-[0.125rem] w-[2.188rem]' />
            <div className='bg-gray-100 h-[1.5rem] w-[1.5rem] rounded-full flex items-center justify-center mx-2'>
              <span className='font-medium type-default text-white mb-0.5 ml-px'>
                {t('prospectLoans.applicationStatus.offerApproved.1')}
              </span>
            </div>
            <div className='bg-gray-30 h-[0.125rem] w-[2.188rem]' />
          </div>
          <p className='type-subhead font-medium text-center mt-1'>
            {t('prospectLoans.applicationStatus.offerApproved.goLive')}
          </p>
        </div>
        <div className='flex flex-col items-center sm:mr-8 mb-6 sm:mb-0'>
          <div className='flex items-center'>
            <div className='bg-gray-30 h-[0.125rem] w-[3.313rem]' />
            <div className='bg-gray-100 h-[1.5rem] w-[1.5rem] rounded-full flex items-center justify-center mx-2'>
              <span className='font-medium type-default text-white mb-0.5 ml-px'>
                {t('prospectLoans.applicationStatus.offerApproved.2')}
              </span>
            </div>
            <div className='bg-gray-30 h-[0.125rem] w-[3.313rem]' />
          </div>
          <p className='type-subhead font-medium text-center mt-1'>
            {t('prospectLoans.applicationStatus.offerApproved.processSales')}
          </p>
        </div>
        <div className='flex flex-col items-center'>
          <div className='flex items-center'>
            <div className='bg-gray-30 h-[0.125rem] w-[3.438rem]' />
            <div className='bg-gray-100 h-[1.5rem] w-[1.5rem] rounded-full flex items-center justify-center mx-2'>
              <span className='font-medium type-default text-white mb-0.5 ml-px'>
                {t('prospectLoans.applicationStatus.offerApproved.3')}
              </span>
            </div>
            <div className='bg-gray-30 h-[0.125rem] w-[3.438rem]' />
          </div>
          <div className='flex flex-col items-center'>
            <p className='type-subhead font-medium text-center mt-1'>
              {t('prospectLoans.applicationStatus.offerApproved.requestFunds')}
            </p>
            <p className='type-caption text-secondary text-center'>
              {t('prospectLoans.applicationStatus.offerApproved.ifYourOffer')}
            </p>
          </div>
        </div>
      </div>
      <div className='flex flex-col sm:flex-row justify-center items-center mt-6 rounded bg-gray-0 border p-6'>
        <div className='sm:mr-12 mb-6 sm:mb-0 flex flex-col items-center sm:items-start'>
          <p className='type-large medium'>
            {formatCurrency(
              {
                amount: application.originationAmount,
                currency
              },
              language,
              { abbreviated: true }
            )}
          </p>
          <p className='type-subhead text-secondary mt-1'>
            {t('prospectLoans.applicationStatus.offerApproved.approvedAmount')}
          </p>
        </div>
        <div className='sm:mr-12 mb-6 sm:mb-0 flex flex-col items-center sm:items-start'>
          <p className='type-large medium'>
            {formatCurrency(
              {
                amount: application.fixedFeeAmount,
                currency
              },
              language,
              { abbreviated: true }
            )}
          </p>
          <p className='type-subhead text-secondary mt-1'>
            {t('prospectLoans.applicationStatus.offerApproved.fixedFee')}
          </p>
        </div>
        <div className='sm:mr-12 mb-6 sm:mb-0 flex flex-col items-center sm:items-start'>
          <p className='type-large medium'>
            {formatPercent(application.repaymentRate, language, {
              precision: 2
            })}
          </p>
          <p className='type-subhead text-secondary mt-1'>
            {t('prospectLoans.applicationStatus.offerApproved.dailySales')}
          </p>
        </div>
        <div className='flex flex-col items-center sm:items-start'>
          <p className='type-large medium'>
            {t('prospectLoans.applicationStatus.offerApproved.days', {
              numberOfDays: application.term
            })}
          </p>
          <p className='type-subhead text-secondary mt-1'>
            {t('prospectLoans.applicationStatus.offerApproved.repaymentTerm')}
          </p>
        </div>
      </div>
      {isPreApproved && (
        <Button
          as='a'
          href={`${toastWebUrl}/capital/request-funds`}
          target='_self'
          size='lg'
          className='mt-8 w-[21rem]'
          testId='request-funds-button'
        >
          {t('prospectLoans.applicationStatus.offerApproved.requestFunds')}
        </Button>
      )}
    </div>
  )
}
