import { useEffect } from 'react'
import { useHeap } from '@toasttab/use-heap'
import { usePrevious } from '@local/use-previous'
import { APPLICATION_NAME, EVENT_TYPE } from './types'

export const useTrackApplicationProgress = (currentStepId: string) => {
  const { track } = useHeap()
  const prevStepId = usePrevious(currentStepId)

  useEffect(() => {
    if (prevStepId !== currentStepId) {
      track(
        `${APPLICATION_NAME} - ${EVENT_TYPE.NAVIGATE_TO_STEP} - ${currentStepId}`
      )
    }
  }, [currentStepId, prevStepId, track])
}

export const useTrackProspectLoanApplication = () => {
  const { track, identify } = useHeap()

  return {
    identify: (dealNumber: string) => {
      identify(dealNumber)
    },
    trackApplicationSubmitted: () => {
      track(`${APPLICATION_NAME} - ${EVENT_TYPE.SUBMIT_APPLICATION}`)
    },
    trackApplicationStarted: () => {
      track(`${APPLICATION_NAME} - ${EVENT_TYPE.START_APPLICATION}`)
    }
  }
}
