import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { formatPhone } from '@toasttab/buffet-pui-phone-utilities'
import { ReviewSection } from './ReviewSection'
import { ReviewField } from './ReviewField'
import { formatAddress } from './utils'
import { ProspectLoanFormValues, ProspectLoanSteps } from '../types'

interface Props {
  values: ProspectLoanFormValues
  goToStep(step: ProspectLoanSteps): void
}

export const RestaurantInfoReview = ({
  values,
  goToStep
}: Props): ReactElement => {
  const { t } = useTranslation()

  const { restaurant, socialScore } = values

  return (
    <ReviewSection
      title={t('prospectLoans.review.restaurantLocationInformation')}
      onEditClick={() => goToStep(ProspectLoanSteps.RESTAURANT_INFO)}
    >
      <ReviewField
        label={t('common.restaurant.fields.name.label')}
        testId='restaurantName'
      >
        {restaurant.name}
      </ReviewField>
      <ReviewField
        label={t('common.restaurant.fields.restaurantAddressLabel')}
        testId='restaurantAddress'
      >
        {formatAddress(restaurant.address)}
      </ReviewField>
      <ReviewField
        label={t('common.restaurant.fields.phone.label')}
        testId='restaurantPhoneNumber'
      >
        {formatPhone(restaurant.phoneNumber.toString(), 'US')}
      </ReviewField>
      {restaurant.website && (
        <ReviewField label={t('common.restaurant.fields.website.label')}>
          {restaurant.website}
        </ReviewField>
      )}
      <ReviewField
        label={t('prospectLoans.businessInfo.fields.socialScore.label')}
        testId='socialScore'
      >
        {t(
          `prospectLoans.businessInfo.fields.socialScore.options.${socialScore}`
        )}
      </ReviewField>
    </ReviewSection>
  )
}
