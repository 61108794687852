import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { AxiosError } from 'axios'
import {
  PageBody,
  PageMain,
  PageAside,
  ContentHeader,
  ContentFooter,
  ContinueButton
} from '@toasttab/buffet-pui-wizard-templates'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import {
  InvoicingIcon,
  CommunicationIcon,
  IdNumberIcon,
  InfoDisplayIcon,
  LockLockedDisplayIcon
} from '@toasttab/buffet-pui-icons'
import { DEAL_NUMBER_LENGTH, DEAL_NUMBER_REGEX } from '../../src/utils/utils'
import { useHistory, useParams } from 'react-router-dom'
import * as FullStory from '@fullstory/browser'
import { ProspectLoanStepProps } from './types'
import { FlowProps } from '@local/use-flow-navigation'
import FinancingApi from '../../src/api/Financing'
import { useTrackProspectLoanApplication } from './Analytics'

export const Welcome = ({
  goToNextStep,
  getCodatCompany
}: FlowProps<ProspectLoanStepProps>): ReactElement => {
  const { t } = useTranslation()
  const { dealNumber: dealNumberInURL } = useParams<{ dealNumber: string }>()
  const [dealNumber, setDealNumber] = useState(dealNumberInURL || '')
  const [invalid, setInvalid] = useState(false)

  const history = useHistory()
  const { identify, trackApplicationStarted } =
    useTrackProspectLoanApplication()

  const { mutate: getProspectLoanApplication, isLoading } = useMutation(
    ['get-prospect-loan-application', dealNumber],
    () => FinancingApi.getProspectLoanApplication(dealNumber),
    {
      retry: false,
      onError: (error: AxiosError) => {
        // 404 status indicates that deal number is valid and there is no existing loan application.
        // We navigate to the next step only in this circumstance.
        if (error?.response?.status === 404) {
          onNavigateToNextStep()
        } else {
          // Any other error indicates an invalid deal number.
          setInvalid(true)
        }
      },
      onSuccess: () => {
        const modifiedDealNumber = dealNumber?.toLowerCase()
        // Navigate to the loan application status page if they have an existing application.
        history.push(`/welcome-loan-status/${modifiedDealNumber}/`)
      }
    }
  )

  const onNavigateToNextStep = () => {
    const modifiedDealNumber = dealNumber?.toLowerCase()
    if (modifiedDealNumber.match(DEAL_NUMBER_REGEX)) {
      setInvalid(false)
    } else {
      return setInvalid(true)
    }

    FullStory.identify(modifiedDealNumber)
    history.push(`/welcome-loan/${modifiedDealNumber}/apply`)

    // Get the Codat company only after the customer has entered a valid deal number.
    getCodatCompany(modifiedDealNumber)

    identify(modifiedDealNumber)
    trackApplicationStarted()

    goToNextStep()
  }

  return (
    <PageBody testId='prospect-loans-welcome-page'>
      <PageMain>
        <div className='max-w-[460px] mt-8'>
          <ContentHeader
            title={t('prospectLoans.welcome.title')}
            className='pb-0'
          />
          <p className='text-default type-default'>
            {t('prospectLoans.welcome.description')}
          </p>
        </div>
        <div className='mt-12'>
          <p className='type-default font-semibold mb-4'>
            {t('prospectLoans.welcome.needToApply.heading')}
          </p>
          <div className='flex flex-row items-center mb-2'>
            <InvoicingIcon
              className='text-secondary mr-6'
              accessibility='decorative'
            />
            <p className='type-subhead text-default'>
              {t(
                'prospectLoans.welcome.needToApply.restaurantLegalInformation'
              )}
            </p>
          </div>
          <div className='flex flex-row items-center mb-2'>
            <CommunicationIcon
              className='text-secondary mr-6'
              accessibility='decorative'
            />
            <p className='type-subhead text-default'>
              {t('prospectLoans.welcome.needToApply.contactInformation')}
            </p>
          </div>
          <div className='flex flex-row items-center mb-2'>
            <IdNumberIcon
              className='text-secondary mr-6'
              accessibility='decorative'
            />
            <p className='type-subhead text-default'>
              {t('prospectLoans.welcome.needToApply.toastDealNumber')}
            </p>
          </div>
          <TextInput
            testId='deal-number-input'
            value={dealNumber}
            onChange={(e) => {
              setDealNumber(e.target.value)
              setInvalid(false)
            }}
            autoComplete='false'
            maxLength={DEAL_NUMBER_LENGTH}
            name='opportunity'
            helperText={t('common.welcome.apply.dealNumber.helperText')}
            data-testid='deal-number-input'
            invalid={invalid}
            errorText={t('common.welcome.apply.dealNumber.error')}
            placeholder='XXXXXX'
            size='lg'
            containerClassName='pl-12 max-w-[340px]'
          />
        </div>
        <ContentFooter className='py-6 bg-white'>
          <div className='flex justify-end w-full'>
            <ContinueButton
              testId='welcome'
              disabled={isLoading || dealNumber.length !== DEAL_NUMBER_LENGTH}
              onClick={() => getProspectLoanApplication()}
              className='mr-6'
            >
              {isLoading
                ? t('prospectLoans.shared.loading')
                : t('common.welcome.next')}
            </ContinueButton>
          </div>
        </ContentFooter>
      </PageMain>
      <PageAside>
        <div className='flex items-start mb-8'>
          <InfoDisplayIcon
            size='2xl'
            className='mr-4'
            accessibility='decorative'
          />
          <div>
            <p className='type-default text-default font-medium mb-4'>
              {t('prospectLoans.welcome.aside.takeYourTime.heading')}
            </p>
            <p className='type-subhead text-secondary'>
              {t('prospectLoans.welcome.aside.takeYourTime.description')}
            </p>
          </div>
        </div>
        <div className='flex items-center mb-8'>
          <LockLockedDisplayIcon
            size='2xl'
            className='mr-4'
            accessibility='decorative'
          />
          <p className='type-default text-default font-medium'>
            {t('prospectLoans.welcome.aside.creditScore.heading')}
          </p>
        </div>
        <p className='text-secondary type-caption mt-auto'>
          {t('prospectLoans.shared.legalDisclaimer')}
        </p>
      </PageAside>
    </PageBody>
  )
}
