import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { Image } from '@toasttab/buffet-pui-image'
import step1Image from './assets/how-does-it-work-step-1.png'
import step2Image from './assets/how-does-it-work-step-2.png'
import step3Image from './assets/how-does-it-work-step-3.png'
import step4Image from './assets/how-does-it-work-step-4.png'
import backgroundImage from './assets/how-does-it-work-background.png'

export const HowDoesItWork = (): ReactElement => {
  const { t } = useTranslation()
  const [selectedStepIndex, setSelectedStepIndex] = React.useState(0)

  const stepImages = [step1Image, step2Image, step3Image, step4Image]

  return (
    <div data-testid='how-does-it-work-section' className='mt-12'>
      <h3 className='type-headline-3 text-center mb-8'>
        {t('prospectLoans.marketingPage.howDoesItWork.title')}
      </h3>
      <div className='relative flex justify-center items-center'>
        <div className='z-10'>
          <Image
            src={stepImages[selectedStepIndex]}
            alt='how does it work'
            className='w-[32.5rem] h-[21.25rem]'
          />
        </div>
        <div className='absolute center'>
          <Image
            src={backgroundImage}
            position='center'
            className='z-0'
            alt=''
            role='presentation'
          />
        </div>
      </div>
      <div className='flex flex-col md:flex-row md:justify-between mt-8'>
        <div
          onClick={() => setSelectedStepIndex(0)}
          className='mb-8 md:mb-0 md:max-w-[13.625rem] md:mr-4 cursor-pointer'
        >
          <p
            className={cx('type-overline', {
              'text-success': selectedStepIndex === 0
            })}
          >
            {t('prospectLoans.marketingPage.howDoesItWork.step1.title')}
          </p>
          <p
            className={cx('type-large font-bold mb-4', {
              'text-success': selectedStepIndex === 0
            })}
          >
            {t('prospectLoans.marketingPage.howDoesItWork.step1.label')}
          </p>
          <p className='type-subhead'>
            {t('prospectLoans.marketingPage.howDoesItWork.step1.description')}
          </p>
        </div>
        <div
          onClick={() => setSelectedStepIndex(1)}
          className='mb-8 md:mb-0 md:max-w-[13.625rem] md:mr-4 cursor-pointer'
        >
          <p
            className={cx('type-overline', {
              'text-success': selectedStepIndex === 1
            })}
          >
            {t('prospectLoans.marketingPage.howDoesItWork.step2.title')}
          </p>
          <p
            className={cx('type-large font-bold mb-4', {
              'text-success': selectedStepIndex === 1
            })}
          >
            {t('prospectLoans.marketingPage.howDoesItWork.step2.label')}
          </p>
          <p className='type-subhead'>
            {t('prospectLoans.marketingPage.howDoesItWork.step2.description')}
          </p>
        </div>
        <div
          onClick={() => setSelectedStepIndex(2)}
          className='mb-8 md:mb-0 md:max-w-[13.625rem] md:mr-4 cursor-pointer'
        >
          <p
            className={cx('type-overline', {
              'text-success': selectedStepIndex === 2
            })}
          >
            {t('prospectLoans.marketingPage.howDoesItWork.step3.title')}
          </p>
          <p
            className={cx('type-large font-bold mb-4', {
              'text-success': selectedStepIndex === 2
            })}
          >
            {t('prospectLoans.marketingPage.howDoesItWork.step3.label')}
          </p>
          <p className='type-subhead'>
            {t('prospectLoans.marketingPage.howDoesItWork.step3.description')}
          </p>
        </div>
        <div
          onClick={() => setSelectedStepIndex(3)}
          className='md:max-w-[13.625rem] cursor-pointer'
        >
          <p
            className={cx('type-overline', {
              'text-success': selectedStepIndex === 3
            })}
          >
            {t('prospectLoans.marketingPage.howDoesItWork.step4.title')}
          </p>
          <p
            className={cx('type-large font-bold mb-4', {
              'text-success': selectedStepIndex === 3
            })}
          >
            {t('prospectLoans.marketingPage.howDoesItWork.step4.label')}
          </p>
          <p className='type-subhead'>
            {t('prospectLoans.marketingPage.howDoesItWork.step4.description')}
          </p>
        </div>
      </div>
    </div>
  )
}
