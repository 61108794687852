import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { ReadonlyText, ReadonlyTextProps } from '@toasttab/buffet-pui-readonly'
import {
  CurrencyField,
  RadioGroupField,
  SelectField,
  SelectFieldProps
} from '@toasttab/buffet-pui-forms'
import { Currency } from '@toasttab/buffet-pui-types'
import {
  actualFilingYearOptions,
  lastYear,
  projectedFilingYearOptions
} from './constants'
import { stringToBoolean } from '../utils'
import { ProspectLoanFormValues } from '../types'

export const BusinessRevenue = (): ReactElement => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<ProspectLoanFormValues>()

  useEffect(() => {
    if (stringToBoolean(values.hasFiledPriorYearTaxes)) {
      setFieldValue('filingYear', lastYear.value)
    }
  }, [setFieldValue, values.hasFiledPriorYearTaxes])

  const netIncomeLabelKey = `prospectLoans.finances.businessRevenue.fields.${
    stringToBoolean(values.hasFiledPriorYearTaxes)
      ? 'netIncome'
      : 'projectedNetIncome'
  }.label`

  const netIncomeFilingYearLabelKey = `prospectLoans.finances.businessRevenue.fields.${
    stringToBoolean(values.hasFiledPriorYearTaxes)
      ? 'filingYear'
      : 'anticipatedFilingYear'
  }.label`

  const filingYearOptions = stringToBoolean(values.hasFiledPriorYearTaxes)
    ? actualFilingYearOptions
    : projectedFilingYearOptions

  return (
    <>
      <p className='type-large font-semibold mt-6 mb-6'>
        {t('prospectLoans.finances.businessRevenue.title')}
      </p>
      <label
        className='type-default font-semibold mb-1'
        id='three-month-total-sales-field-label'
        htmlFor='three-month-total-sales-field'
      >
        {t(
          'prospectLoans.finances.businessRevenue.fields.threeMonthTotalSales.label'
        )}
      </label>
      <CurrencyField
        id='three-month-total-sales-field'
        testId='threeMonthTotalSales'
        name='threeMonthTotalSales'
        currency={Currency.USD}
        aria-labelledby='three-month-total-sales-field'
        containerClassName='max-w-[250px]'
        decimalScale={0}
        thousandSeparator
      />
      <RadioGroupField
        className='mt-6'
        name='hasFiledPriorYearTaxes'
        testId='hasFiledPriorYearTaxes'
        label={t(
          'prospectLoans.finances.businessRevenue.fields.hasFiledPriorYearTaxes.label',
          { year: lastYear.value }
        )}
        options={[
          {
            value: 'true',
            label: t(
              'prospectLoans.finances.businessRevenue.fields.hasFiledPriorYearTaxes.options.yes'
            )
          },
          {
            value: 'false',
            label: t(
              'prospectLoans.finances.businessRevenue.fields.hasFiledPriorYearTaxes.options.no'
            )
          }
        ]}
      />
      {values?.hasFiledPriorYearTaxes !== '' && (
        <div className='flex space-x-6 mt-4 items-center'>
          <CurrencyField
            containerClassName='flex-1'
            name='netIncome'
            label={t(netIncomeLabelKey)}
            thousandSeparator
            decimalScale={0}
            testId='netIncome'
            errorText={t(
              'application.steps.finance.section.revenue.validationMessage.netIncome'
            )}
            preserveHelpSpace
          />
          {/* @ts-ignore*/}
          <Field
            name='filingYear'
            label={t(netIncomeFilingYearLabelKey)}
            containerClassName='flex-1 mb-5'
            options={filingYearOptions}
            readOnly={stringToBoolean(values.hasFiledPriorYearTaxes)}
            testId='filingYear'
          />
        </div>
      )}
    </>
  )
}

type FieldProps =
  | ({
      readOnly: true
    } & ReadonlyTextProps)
  | ({ readOnly: false } & SelectFieldProps)

const Field = ({ readOnly = false, ...props }: FieldProps) => {
  const { values } = useFormikContext<{ filingYear: string }>()
  return readOnly ? (
    // @ts-ignore
    <ReadonlyText className='pt-2 mb-2' value={values.filingYear} {...props} />
  ) : (
    // @ts-ignore
    <SelectField name={props.name} options={props.options} {...props} />
  )
}
