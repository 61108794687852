import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { object, string } from 'yup'

import { SubmitButton } from '@toasttab/buffet-pui-forms'
import { addressFieldsSchema } from '../AddressFields/addressFieldsSchema'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import FinancingApi from '../../api/Financing'
import InvalidInfoModal from '../InvalidInfoModal'
import { FINANCING_METHODS } from '../../utils/utils'
import classNames from 'classnames/bind'
import styles from './BusinessInformation.module.css'
import { RestaurantInfoFields } from '@local/restaurant-info-fields'
import { RestaurantLegalInfoFields } from '@local/restaurant-legal-info-fields'
const cx = classNames.bind(styles)

const merchantNameDescription =
  "Please provide the exact registered name including punctuation. For example 'ABC Company, LLC' not 'ABC Company LLC'"

const taxIdError = 'Please enter a valid 9 digit Federal Tax ID'

const BusinessInformationSchema = object().shape({
  restaurant: object().shape({
    name: string().required('Please enter the name of your restaurant'),
    address: addressFieldsSchema,
    website: string(),
    phoneNumber: string().required('Please enter your restaurant phone number')
  }),
  merchant: object().shape({
    name: string().required(merchantNameDescription),
    tin: string()
      .matches(/^[0-9]{9}$/, taxIdError)
      .required(taxIdError),
    tinType: string().required(),
    address: addressFieldsSchema,
    phoneNumber: string().required('Please enter your business phone number')
  })
})

const BusinessInformation = ({
  dealNumber,
  restaurant,
  merchant,
  handleBusinessInformationSubmit,
  financingMethod
}) => {
  const [isValidationModalOpen, setIsValidationModalOpen] = useState(false)
  const isLoan = financingMethod === FINANCING_METHODS.LOAN

  return (
    <>
      <div className={cx('type-headline-2 font-bold', 'business-heading')}>
        Tell us about your business.
      </div>

      <div
        className={cx('type-default text-secondary', 'business-description')}
      >
        Please fill out all required fields below. The restaurant location
        address below must be the location where your
        {isLoan ? ' financed' : ' leased'} equipment will reside.
      </div>

      <BusinessInformationForm
        dealNumber={dealNumber}
        initialValues={{ merchant, restaurant }}
        handleBusinessInformationSubmit={handleBusinessInformationSubmit}
        isLoan={isLoan}
        openInvalidModal={setIsValidationModalOpen}
      />
      <InvalidInfoModal
        buttonText={'"Next"'}
        infoType='business'
        isOpen={isValidationModalOpen}
        setIsOpen={setIsValidationModalOpen}
      />
    </>
  )
}

const BusinessInformationForm = ({
  dealNumber,
  handleBusinessInformationSubmit,
  initialValues,
  isLoan,
  openInvalidModal
}) => {
  const [hasAttempted, setHasAttempted] = useState(false)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={BusinessInformationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const saveValues = () => {
          setSubmitting(false)
          handleBusinessInformationSubmit(values)
        }

        if (hasAttempted || !isLoan) {
          saveValues()
        } else {
          FinancingApi.validateBusinessInfo(dealNumber, {
            name: values.merchant.name,
            tin: values.merchant.tin
          })
            .then(() => saveValues())
            .catch(() => {
              setHasAttempted(true)
              openInvalidModal(true)
              setSubmitting(false)
            })
        }
      }}
    >
      {({ dirty, handleSubmit, isSubmitting, values }) => (
        <form
          data-testid='business-info-form'
          autoComplete='false'
          onSubmit={handleSubmit}
        >
          <div className={cx('type-headline-3', 'business-heading-2')}>
            Restaurant Location Information
          </div>

          <RestaurantInfoFields />

          <div className={cx('mt-10 type-headline-3', 'business-heading-2')}>
            Business Legal Information
          </div>

          <RestaurantLegalInfoFields />

          <div className='float-left flex flex-wrap items-center mt-8'>
            <SubmitButton
              disabled={!dirty || isSubmitting}
              data-testid={'next'}
            >
              Next
            </SubmitButton>
            <div className='text-gray-100 type-large mx-6'>1 / 2</div>
            {isSubmitting && !hasAttempted && (
              <div className='flex items-center py-8 md:py-0'>
                <MerryGoRound size={MerryGoRound.Size.sm} />
                <div className='ml-4'>Verifying your business info</div>
              </div>
            )}
          </div>
        </form>
      )}
    </Formik>
  )
}

BusinessInformation.propTypes = {
  handleBusinessInformationSubmit: PropTypes.func,
  dealNumber: PropTypes.string,
  restaurant: PropTypes.object,
  merchant: PropTypes.object,
  financingMethod: PropTypes.string
}

export default BusinessInformation
