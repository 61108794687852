import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { useBanquetProps } from 'banquet-runtime-modules'
import {
  DateInputField,
  SelectField,
  CurrencyField
} from '@toasttab/buffet-pui-forms'
import { IconButtonWithTooltip } from '@toasttab/buffet-pui-tooltip'
import { InfoIcon } from '@toasttab/buffet-pui-icons'
import { stringToBoolean } from '../utils'
import { ProspectLoanFormValues } from '../types'
import { InlineLabelRadioGroupField } from './InlineLabelRadioGroupField'
import { createTaxLienFiledOptions } from './taxLienFiledOptions'

export const BusinessDeclarations = (): ReactElement => {
  const { t } = useTranslation()
  const { i18n } = useBanquetProps()
  const { values } = useFormikContext<ProspectLoanFormValues>()

  return (
    <div>
      <p className='type-large font-semibold mt-6 mb-6'>
        {t('prospectLoans.finances.businessDeclarations.title')}
      </p>
      <p className='type-default mb-6'>
        {t('prospectLoans.finances.businessDeclarations.description')}
      </p>
      <InlineLabelRadioGroupField
        labelText={t(
          'prospectLoans.finances.businessDeclarations.fields.isLeasing.label'
        )}
        id='is-leasing-input'
        testId='isLeasing'
        name='isLeasing'
        options={[
          {
            value: 'true',
            label: t('prospectLoans.shared.yes')
          },
          {
            value: 'false',
            label: t('prospectLoans.shared.no')
          }
        ]}
      />
      {stringToBoolean(values.isLeasing) && (
        <DateInputField
          locale={i18n?.language || 'en-US'}
          testId='leaseEndDate'
          containerClassName='mb-6 max-w-[350px]'
          name='leaseEndDate'
          label={t(
            'prospectLoans.finances.businessDeclarations.fields.leaseEndDate.label'
          )}
          mask='mask_short'
        />
      )}
      <InlineLabelRadioGroupField
        labelText={t(
          'prospectLoans.finances.businessDeclarations.fields.hasTaxLiens.label'
        )}
        id='has-tax-liens-input'
        testId='hasTaxLiens'
        name='hasTaxLiens'
        options={[
          {
            value: 'true',
            label: t('prospectLoans.shared.yes')
          },
          {
            value: 'false',
            label: t('prospectLoans.shared.no')
          }
        ]}
      />
      {stringToBoolean(values.hasTaxLiens) && (
        <>
          <div className='flex space-x-3 mt-3 mb-6 items-start'>
            <CurrencyField
              preserveHelpSpace
              testId='taxLienAmount'
              containerClassName='flex-1'
              name='taxLienAmount'
              label={t(
                'prospectLoans.finances.businessDeclarations.fields.taxLienAmount.label'
              )}
              thousandSeparator
              decimalScale={0}
            />
            <SelectField
              preserveHelpSpace
              testId='taxLienFilingDate'
              containerClassName='flex-1'
              name='taxLienFilingDate'
              options={createTaxLienFiledOptions(t)}
              label={t(
                'prospectLoans.finances.businessDeclarations.fields.taxLienFilingDate.label'
              )}
            />
          </div>
        </>
      )}
      <InlineLabelRadioGroupField
        containerClassname='mt-6'
        labelText={t(
          'prospectLoans.finances.businessDeclarations.fields.hasJudgementsOrLawsuits.label'
        )}
        id='has-judgements-input'
        testId='hasJudgementsOrLawsuits'
        name='hasJudgementsOrLawsuits'
        options={[
          {
            value: 'true',
            label: t('prospectLoans.shared.yes')
          },
          {
            value: 'false',
            label: t('prospectLoans.shared.no')
          }
        ]}
      />
      <InlineLabelRadioGroupField
        containerClassname='mt-6'
        labelText={
          <span>
            {t(
              'prospectLoans.finances.businessDeclarations.fields.hasBankruptcy.label'
            )}
            <IconButtonWithTooltip
              icon={<InfoIcon accessibility='decorative' className='-mt-1' />}
              tooltipContent={t(
                'prospectLoans.finances.businessDeclarations.fields.hasBankruptcy.tooltip'
              )}
            />
          </span>
        }
        id='has-bankruptcy-input'
        testId='hasBankruptcy'
        name='hasBankruptcy'
        options={[
          {
            value: 'true',
            label: t('prospectLoans.shared.yes')
          },
          {
            value: 'false',
            label: t('prospectLoans.shared.no')
          }
        ]}
      />
      <InlineLabelRadioGroupField
        containerClassname='mt-6'
        labelText={t(
          'prospectLoans.finances.businessDeclarations.fields.hasOwnershipChanged.label'
        )}
        id='ownership-changed-input'
        testId='hasOwnershipChanged'
        name='hasOwnershipChanged'
        options={[
          {
            value: 'true',
            label: t('prospectLoans.shared.yes')
          },
          {
            value: 'false',
            label: t('prospectLoans.shared.no')
          }
        ]}
      />
      <InlineLabelRadioGroupField
        containerClassname='mt-6'
        labelText={t(
          'prospectLoans.finances.businessDeclarations.fields.hasHistoryOfClosure.label'
        )}
        id='has-history-of-closure-input'
        testId='hasHistoryOfClosure'
        name='hasHistoryOfClosure'
        options={[
          {
            value: 'true',
            label: t('prospectLoans.shared.yes')
          },
          {
            value: 'false',
            label: t('prospectLoans.shared.no')
          }
        ]}
      />
      <InlineLabelRadioGroupField
        containerClassname='mt-6'
        labelText={t(
          'prospectLoans.finances.businessDeclarations.fields.hasPlannedChangeOfOwnership.label'
        )}
        id='has-planned-change-of-ownership-input'
        testId='hasPlannedChangeOfOwnership'
        name='hasPlannedChangeOfOwnership'
        options={[
          {
            value: 'true',
            label: t('prospectLoans.shared.yes')
          },
          {
            value: 'false',
            label: t('prospectLoans.shared.no')
          }
        ]}
      />
      <InlineLabelRadioGroupField
        containerClassname='mt-6'
        labelText={t(
          'prospectLoans.finances.businessDeclarations.fields.hasPlannedClosures.label'
        )}
        id='has-planned-closures-input'
        testId='hasPlannedClosures'
        name='hasPlannedClosures'
        options={[
          {
            value: 'true',
            label: t('prospectLoans.shared.yes')
          },
          {
            value: 'false',
            label: t('prospectLoans.shared.no')
          }
        ]}
      />
      <InlineLabelRadioGroupField
        containerClassname='mt-6'
        labelText={t(
          'prospectLoans.finances.businessDeclarations.fields.hasPlannedLocationChange.label'
        )}
        id='has-planned-location-change-input'
        testId='hasPlannedLocationChange'
        name='hasPlannedLocationChange'
        options={[
          {
            value: 'true',
            label: t('prospectLoans.shared.yes')
          },
          {
            value: 'false',
            label: t('prospectLoans.shared.no')
          }
        ]}
      />
    </div>
  )
}
