import { RestaurantInfoFields } from '@local/restaurant-info-fields'
import {
  BackButton,
  ContentFooter,
  ContinueButton,
  PageBody,
  PageMain
} from '@toasttab/buffet-pui-wizard-templates'
import React, { ReactElement, useEffect } from 'react'
import { EasyPayStepProps } from './types'
import { FlowProps } from '@local/use-flow-navigation'
import { scrollToTop } from './helpers'
import { EasyPayAside } from './EasyPayAside'
import { useTranslation } from 'react-i18next'

export const RestaurantInfo = ({
  isValid,
  validateForm,
  goToNextStep,
  goToPreviousStep
}: FlowProps<EasyPayStepProps>): ReactElement => {
  const { t } = useTranslation()
  useEffect(() => {
    validateForm()
    scrollToTop()
  }, [validateForm])

  return (
    <PageBody testId='restaurant-info-page'>
      <PageMain>
        <h1 className='type-headline-4 md:type-headline-2 font-bold text-default mb-3'>
          {t('common.restaurant.title')}
        </h1>
        <p className='text-secondary type-default mb-4'>
          {t('common.restaurant.subtitle')}
        </p>
        <h2 className='type-headline-3 mb-4'>
          {t('common.restaurant.fields.title')}
        </h2>
        <RestaurantInfoFields className='mb-6' />
        <ContentFooter className='mt-6 py-6 bg-white'>
          <div className='flex justify-end w-full'>
            <BackButton onClick={goToPreviousStep} />
            <ContinueButton
              onClick={goToNextStep}
              disabled={!isValid}
              className='mr-6'
              testId='restaurant-info'
            >
              {t('common.next')}
            </ContinueButton>
          </div>
        </ContentFooter>
      </PageMain>
      <EasyPayAside />
    </PageBody>
  )
}
