import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import classNames from 'classnames/bind'

import { Button } from '@toasttab/buffet-pui-buttons'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import {
  CheckCircleSelectedIcon,
  IdNumberIcon,
  SpecialRequestIcon
} from '@toasttab/buffet-pui-icons'

import ToastLogo from '../ToastLogo'
import {
  DEAL_NUMBER_LENGTH,
  DEAL_NUMBER_REGEX,
  FINANCING_DISCLOSURE,
  FINANCING_METHODS,
  LEASE_DISCLOSURE
} from '../../utils/utils'
import * as FullStory from '@fullstory/browser'
import styles from './Welcome.module.css'
const cx = classNames.bind(styles)

const Welcome = ({ financingMethod }) => {
  let { number } = useParams()
  const [dealNumber, setDealNumber] = useState(number || '')
  const [invalid, setInvalid] = useState(false)

  const history = useHistory()

  const isLease = financingMethod === FINANCING_METHODS.LEASE
  const isLoan = financingMethod === FINANCING_METHODS.LOAN

  const heading = isLease
    ? 'Welcome to Toast EasyPay'
    : 'Welcome to Toast Financing'

  const subBullet2 = isLease ? 'Contact Information' : 'Owner Information'

  const onDealNumberSubmit = () => {
    const modifiedDealNumber = dealNumber?.toLowerCase()
    if (modifiedDealNumber.match(DEAL_NUMBER_REGEX)) {
      setInvalid(false)
    } else {
      return setInvalid(true)
    }

    FullStory.identify(modifiedDealNumber)
    if (isLoan) {
      history.push(`/financing/${modifiedDealNumber}/apply`)
    } else {
      history.push(`/easypay/${modifiedDealNumber}/apply`)
    }
  }

  return (
    <div className='grid grid-cols-12 text-default'>
      <div className='relative col-span-12 lg:col-span-7 p-4 sm:px-12 sm:py-10'>
        <ToastLogo />
        <div className='pt-12 sm:pt-24 sm:px-12'>
          <div className='type-headline-1 font-bold'>{heading}</div>
          <div className='type-large leading-loose text-gray-75 mt-3 mb-8 sm:mb-16'>
            {isLease ? (
              <>
                Minimize the upfront cost of Toast and pay as you go.
                <br />
                Apply in minutes with no credit check.
                <br />
                The only eligibility requirement is that you become a Toast
                customer.
              </>
            ) : (
              'Only current or prospective Toast customers that are purchasing new hardware which has not yet shipped are eligible for financing. If you are unsure of whether your hardware has shipped, please contact your sales rep before completing this credit application.'
            )}
          </div>
          <div className='type-large font-bold'>
            To apply, you’ll need to provide:
          </div>
          <div className=''>
            <div className='my-4 '>
              <IdNumberIcon />
              <span className='ml-5 type-default text-gray-75'>
                Restaurant Legal Information
              </span>
            </div>
            <div className='my-4'>
              <CheckCircleSelectedIcon />
              <span className='ml-5 type-default text-gray-75'>
                {subBullet2}
              </span>
            </div>
            <div className='my-4'>
              <SpecialRequestIcon />
              <span className='ml-5 type-default font-sans font-semibold'>
                Toast Deal Number
              </span>
              <div
                className={cx('box-content pt-1 pl-10', 'id-input-container')}
              >
                <TextInput
                  value={dealNumber}
                  onChange={(e) => {
                    setDealNumber(e.target.value)
                    setInvalid(false)
                  }}
                  autoComplete='false'
                  maxLength={DEAL_NUMBER_LENGTH}
                  name='opportunity'
                  helperText='The unique deal number Toast emailed you '
                  data-testid={'opportunity-input'}
                  invalid={invalid}
                  errorText='Deal number is invalid'
                />
              </div>
            </div>
          </div>
          <Button
            onClick={() => onDealNumberSubmit()}
            className='mx-10 mt-6 lg:!mb-16 sm:!mt-10'
            disabled={dealNumber.length !== DEAL_NUMBER_LENGTH}
            type='submit'
            data-testid={'opportunity-submit'}
          >
            Get Started
          </Button>
          <div className='type-caption text-gray-75 text-left pb-2 mt-6 ml-10 lg:!m-0 lg:!pl-24 lg:!pr-2 lg:!absolute lg:!bottom-0 lg:!left-0 lg:!right-0 leading-4'>
            {isLease ? LEASE_DISCLOSURE : FINANCING_DISCLOSURE}
            <a
              className='font-bold pl-1 hover:underline'
              href='https://pos.toasttab.com/privacy'
              // eslint-disable-next-line react/jsx-no-target-blank
              target='_blank'
              rel='noreferrer'
            >
              Privacy Statement
            </a>
          </div>
        </div>
      </div>
      <div
        className={cx(
          'hidden lg:block col-span-5 bg-cover bg-top min-h-screen',
          'hero'
        )}
      />
    </div>
  )
}

export default Welcome
