import React, { ReactElement, useState } from 'react'
import { AccordionItem } from '@toasttab/buffet-pui-accordion'

interface Props {
  question: string
  answer: ReactElement
  hasBorderTop?: boolean
}

export const FAQItem = ({
  question,
  answer,
  hasBorderTop = false
}: Props): ReactElement => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className='flex flex-col items-center bg-white'>
      <div className='max-w-[42.5rem]'>
        <div className={hasBorderTop ? 'border-t' : 'border-none'}>
          <AccordionItem
            id={question}
            // @ts-ignore
            title={
              <div className='py-6'>
                <p className='font-semibold type-default'>{question}</p>
              </div>
            }
            subtitle={''}
            isOpen={isOpen}
            onToggle={() => setIsOpen((cur) => !cur)}
          >
            {answer}
          </AccordionItem>
        </div>
      </div>
    </div>
  )
}
