import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useBanquetProps } from 'banquet-runtime-modules'
import { Currency, formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import { ProspectLoanFormValues } from '../types'
import { createDebtTypes } from '../Finances/debtTypes'

interface Props {
  values: ProspectLoanFormValues
}

export const DebtReview = ({ values }: Props): ReactElement => {
  const { t } = useTranslation()
  const { i18n } = useBanquetProps()

  if (values.hasNoDebt) {
    return (
      <p className='type-default mt-3'>
        {t('prospectLoans.finances.businessDebt.noDebt')}
      </p>
    )
  }

  const debtTypeMap = Object.fromEntries(
    createDebtTypes(t).map(({ label, value }) => [value, label])
  )

  return (
    <ul className='type-default mt-4 -mb-2'>
      {values.debtList.map(({ debtAmount, debtType }) => (
        <li key={`${debtAmount}-${debtType}`} className='mb-2'>
          <div className='mb-1 font-semibold'>{debtTypeMap[debtType]}</div>
          {formatCurrency(
            {
              amount: debtAmount,
              currency: i18n?.currency ?? Currency.USD
            },
            i18n?.language ?? 'en-US',
            {
              precision: 0
            }
          )}
        </li>
      ))}
    </ul>
  )
}
