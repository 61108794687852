export interface EasyPayApplicationForm {
  merchant: {
    name: string
    tin: string
    tinType: string
    address: {
      street1: string
      street2?: string
      city: string
      state: string
      zip: string
      country: 'US'
    }
  }
  restaurant: {
    name: string
    address: {
      street1: string
      street2?: string
      city: string
      state: string
      zip: string
      country: 'US'
    }
    website: string
  }
  owner: {
    firstName: string
    lastName: string
    address: {
      street1: string
      street2?: string
      city: string
      state: string
      zip: string
      country: 'US'
    }
    phoneNumber: string
    email: string
    disclosureCheckbox: boolean
  }
}

export enum EasyPaySteps {
  WELCOME = 'WELCOME',
  RESTAURANT_INFO = 'RESTAURANT_INFO',
  BUSINESS_INFO = 'BUSINESS_INFO',
  REVIEW_TERMS = 'REVIEW_TERMS',
  SUBMITTING = 'SUBMITTING',
  THANK_YOU = 'THANK_YOU',
  ERROR = 'ERROR'
}

export type EasyPayStepProps = {
  isValid: boolean
  validateForm(): void
}
