import React, { ReactElement } from 'react'
import useClassnames from '@local/use-classnames'
import { BarTooltip } from './BarTooltip'
import graphSectionStyles from './GraphSection.module.css'

interface Props {
  top: number
  bottom: number
  payment: number
  sales: number
}

export const BarContainer = ({
  top,
  bottom,
  payment,
  sales
}: Props): ReactElement => {
  const cx = useClassnames(graphSectionStyles)
  return (
    <div
      className={cx(
        'bar-container',
        'flex flex-col justify-end items-center w-7 h-full'
      )}
    >
      <div
        className={cx('bar-top', 'rounded-t', 'w-7 md:w-16')}
        style={{ height: `${top}%` }}
      />
      <div
        className={cx('bar-bottom', 'w-7 md:w-16')}
        style={{ height: `${bottom}%` }}
      >
        <BarTooltip payment={payment} sales={sales} />
      </div>
    </div>
  )
}
