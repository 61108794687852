import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Panel } from '@toasttab/buffet-pui-config-templates'
import { SentIllustration } from '@toasttab/buffet-pui-illustrations'
import { SubmitStatusContainer } from './SubmitStatusContainer'

export const Submitted = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <SubmitStatusContainer testId='submitted-success-page'>
      <Panel className='flex flex-col items-center'>
        <SentIllustration resizeToContainer className='h-[130px] mt-6 mb-6' />
        <p className='font-medium type-headline-4 text-center'>
          {t('prospectLoans.submitted.thanks')}
        </p>
        <p className='font-medium type-headline-4 text-center mb-6'>
          {t('prospectLoans.submitted.yourApplication')}
        </p>
        <p className='type-default'>
          {t('prospectLoans.submitted.pleaseExpect')}
        </p>
        <p className='type-default'>
          {t('prospectLoans.submitted.youWillReceive')}
        </p>
      </Panel>
    </SubmitStatusContainer>
  )
}
