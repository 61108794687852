import React, { useEffect } from 'react'
import { ProspectLoanSteps } from './types'

const PreventNavigateAway = () => {
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault()
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  return null
}

const STEPS_WITHOUT_PREVENT_NAVIGATE_AWAY = [
  ProspectLoanSteps.WELCOME,
  ProspectLoanSteps.SUBMITTED,
  ProspectLoanSteps.ERROR
]

interface Props {
  currentStepId: string
}

const PreventNavigateAwayContainer = ({ currentStepId }: Props) => {
  if (
    STEPS_WITHOUT_PREVENT_NAVIGATE_AWAY.includes(
      currentStepId as ProspectLoanSteps
    )
  ) {
    return null
  }
  return <PreventNavigateAway />
}

export default PreventNavigateAwayContainer
