import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import {
  PageBody,
  PageMain,
  ContentHeader,
  ContentFooter,
  ContinueButton
} from '@toasttab/buffet-pui-wizard-templates'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import {
  CheckCircleSelectedIcon,
  IdNumberIcon,
  SpecialRequestIcon
} from '@toasttab/buffet-pui-icons'

import * as FullStory from '@fullstory/browser'
import { DEAL_NUMBER_LENGTH, DEAL_NUMBER_REGEX } from '../../src/utils/utils'
import { EasyPayStepProps } from './types'
import { FlowProps } from '@local/use-flow-navigation'
import { EasyPayAside } from './EasyPayAside'
import { useTranslation } from 'react-i18next'

export const EasyPayWelcome = ({
  goToNextStep
}: FlowProps<EasyPayStepProps>) => {
  const { t } = useTranslation()
  const { dealNumber: dealNumberInURL } = useParams<{ dealNumber: string }>()
  const [dealNumber, setDealNumber] = useState(dealNumberInURL || '')
  const [invalid, setInvalid] = useState(false)

  const history = useHistory()

  const onDealNumberSubmit = () => {
    const modifiedDealNumber = dealNumber?.toLowerCase()
    if (modifiedDealNumber.match(DEAL_NUMBER_REGEX)) {
      setInvalid(false)
    } else {
      return setInvalid(true)
    }

    FullStory.identify(modifiedDealNumber)
    history.push(`/easypay/${modifiedDealNumber}/apply`)
    goToNextStep()
  }

  return (
    <PageBody>
      <PageMain>
        <ContentHeader title={t('easypay.welcome.title')} />
        <p className='type-default leading-loose mt-3 mb-8 sm:mb-16'>
          {t('easypay.welcome.valueProps.minimizeCosts')}
          <br />
          {t('easypay.welcome.valueProps.applyInMinutes')}
          <br />
          {t('easypay.welcome.valueProps.noEligibilityCheck')}
        </p>
        <p className='type-default font-bold'>
          {t('common.welcome.apply.title')}
        </p>
        <div>
          <div className='my-4 '>
            <IdNumberIcon />
            <span className='ml-5 type-default text-gray-75'>
              {t('common.welcome.apply.legalInfo')}
            </span>
          </div>
          <div className='my-4'>
            <CheckCircleSelectedIcon />
            <span className='ml-5 type-default text-gray-75'>
              {t('common.welcome.apply.contact')}
            </span>
          </div>
          <div className='my-4'>
            <SpecialRequestIcon />
            <span className='ml-5 type-default font-sans font-semibold'>
              {t('common.welcome.apply.dealNumber.label')}
            </span>
            <div className='box-content pt-1 pl-10 max-w-xs'>
              <TextInput
                value={dealNumber}
                onChange={(e) => {
                  setDealNumber(e.target.value)
                  setInvalid(false)
                }}
                autoComplete='false'
                maxLength={DEAL_NUMBER_LENGTH}
                name='opportunity'
                helperText={t('common.welcome.apply.dealNumber.helperText')}
                data-testid='opportunity-input'
                invalid={invalid}
                errorText={t('common.welcome.apply.dealNumber.error')}
              />
            </div>
          </div>
        </div>
        <ContentFooter className='py-6'>
          <div className='flex justify-end w-full'>
            <ContinueButton
              testId='welcome'
              disabled={dealNumber.length !== DEAL_NUMBER_LENGTH}
              onClick={onDealNumberSubmit}
              className='mr-6'
            >
              {t('common.welcome.next')}
            </ContinueButton>
          </div>
        </ContentFooter>
      </PageMain>
      <EasyPayAside />
    </PageBody>
  )
}
