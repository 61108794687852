import React, { ReactElement } from 'react'
import { useFormikContext } from 'formik'
import { PasswordField, PasswordFieldProps } from '@toasttab/buffet-pui-forms'

export const HiddenSsnField = (props: PasswordFieldProps): ReactElement => {
  const { setFieldValue } = useFormikContext()

  return (
    <PasswordField
      {...props}
      className='fs-mask'
      autoComplete='false'
      placeholder=''
      pattern='[0-9]{9}'
      inputMode='numeric'
      maxLength={9}
      onChange={(event) =>
        // Prevent user from entering non-digit characters
        setFieldValue(props.name, event.target.value.replace(/\D/g, ''))
      }
    />
  )
}
