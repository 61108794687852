import React from 'react'
import { useField } from 'formik'
import { NumberInput, NumberInputProps } from '@toasttab/buffet-pui-text-input'

export const LeadingZerosNumberInputField = (
  props: Omit<NumberInputProps, 'onChange'>
) => {
  const [{ ...field }, meta, helpers] = useField(props as any)
  const errorText = meta.error || meta.initialError
  const invalid = meta.touched && !!errorText
  return (
    <NumberInput
      allowLeadingZeros={true}
      invalid={invalid}
      errorText={errorText}
      {...field}
      {...props}
      onChange={(value) => helpers.setValue(value.value)}
    />
  )
}
