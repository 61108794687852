export const validateDayAgainstMonth = (date) => {
  if (!date) return false

  const day = date.substring(2, 4)
  const month = date.substring(0, 2)
  const year = date.substring(4, 8)

  let monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
    monthLength[1] = 29

  return day <= monthLength[month - 1]
}

export const formatDateYyyyMmDd = (date) => {
  const day = date.substring(2, 4)
  const month = date.substring(0, 2)
  const year = date.substring(4, 8)

  return parseInt(year + month + day)
}
