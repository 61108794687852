import React, { PropsWithChildren, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FocusBody,
  FocusHeader,
  FocusView,
  HeadingGroup,
  Title
} from '@toasttab/buffet-pui-config-templates'

interface Props {
  testId?: string
}

export const SubmitStatusContainer = ({
  children,
  testId
}: PropsWithChildren<Props>): ReactElement => {
  const { t } = useTranslation()

  return (
    <FocusView testId={testId}>
      <FocusHeader>
        <HeadingGroup subTitle={t('prospectLoans.shared.toastCapital')}>
          <Title>{t('prospectLoans.shared.getPreApproved')}</Title>
        </HeadingGroup>
      </FocusHeader>
      <FocusBody>
        {children}
        <p className='text-secondary type-subhead'>
          {t('prospectLoans.shared.legalDisclaimer')}
        </p>
      </FocusBody>
    </FocusView>
  )
}
