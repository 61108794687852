import * as React from 'react'
import cx from 'classnames'
import { Button } from '@toasttab/buffet-pui-buttons'

interface Props {
  children?: React.ReactNode
  className?: string
}

export const DisclaimerLink = ({ children, className }: Props) => {
  return (
    <sup>
      <Button
        onClick={() => window.scrollTo(0, document.body.scrollHeight)}
        variant='text-link'
        data-testid='disclaimer-link'
        className={cx('!min-w-0 text-link !underline font-semibold', className)}
      >
        {children}
      </Button>
    </sup>
  )
}
