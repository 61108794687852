import React, { ReactElement } from 'react'
import { Panel } from '@toasttab/buffet-pui-config-templates'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { SubmitStatusContainer } from './SubmitStatusContainer'

export const Submitting = (): ReactElement => {
  return (
    <SubmitStatusContainer>
      <Panel className='p-12 relative min-h-[386px]'>
        <MerryGoRound className='pin-center' size='lg' />
      </Panel>
    </SubmitStatusContainer>
  )
}
