import React from 'react'
import { DateField } from '@local/date-field'
import { LeadingZerosNumberInputField } from '@local/leading-zeros-number-input-field'
import { TIN_FIELD_CONFIG, TIN_TYPES } from '@local/tin-field-config'
import { InfoButton } from '../../src/components/InfoButton/InfoButton'
import { NumberInputField } from '@toasttab/buffet-pui-forms'
import { useTranslation } from 'react-i18next'

export const AdditionalOwnershipFields = () => {
  const { t } = useTranslation()
  return (
    <>
      <DateField
        name='owner.dob'
        label={t('financing.personalGuarantor.fields.dob.label')}
        data-testid='owner-dob'
        containerClassName='col-span-2 md:col-span-1'
      />

      <div className='col-span-2 md:col-span-1 md:col-start-1'>
        <label
          htmlFor='tin-field'
          className='flex w-full mb-1 max-h-6 justify-between items-end type-default font-semibold text-default'
        >
          {t('financing.personalGuarantor.fields.ssn.label')}
          <InfoButton
            infoTextParagraph1={t(
              'financing.personalGuarantor.fields.ssn.popup'
            )}
          />
        </label>
        <LeadingZerosNumberInputField
          id='tin-field'
          className='fs-mask'
          autoComplete='false'
          name='owner.ssn'
          data-testid='owner-ssn'
          format={TIN_FIELD_CONFIG[TIN_TYPES.SSN].format}
          mask='_'
          placeholder={TIN_FIELD_CONFIG[TIN_TYPES.SSN].placeholder}
        />
      </div>

      <NumberInputField
        className='fs-mask'
        autoComplete='false'
        name='owner.ownershipStake'
        label={t('financing.personalGuarantor.fields.ownership.label')}
        suffix='%'
        data-testid='owner-ownership'
        containerClassName='col-span-2 md:col-span-1'
      />
    </>
  )
}
