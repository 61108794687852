import React, { ReactElement, useEffect } from 'react'
import {
  BackButton,
  ContentFooter,
  ContinueButton,
  PageBody,
  PageMain
} from '@toasttab/buffet-pui-wizard-templates'
import { useFormikContext } from 'formik'
import { CheckboxField } from '@toasttab/buffet-pui-forms'
import DisclosureList from '../../src/components/DisclosureList'
import { OwnershipInfoFields } from '@local/ownership-info-fields'
import { EasyPayStepProps, EasyPayApplicationForm } from './types'
import { FlowProps } from '@local/use-flow-navigation'
import { scrollToTop } from './helpers'
import { EasyPayAside } from './EasyPayAside'
import { useTranslation } from 'react-i18next'

export const ReviewTerms = ({
  isValid,
  validateForm,
  goToPreviousStep
}: FlowProps<EasyPayStepProps>): ReactElement => {
  const { t } = useTranslation()
  const { submitForm } = useFormikContext<EasyPayApplicationForm>()
  useEffect(() => {
    validateForm()
    scrollToTop()
  }, [validateForm])

  return (
    <PageBody testId='review-page'>
      <PageMain>
        <h1 className='type-headline-4 md:type-headline-2 font-bold text-default mb-3'>
          {t('easypay.owner.title')}
        </h1>
        <p className='text-secondary type-default mb-4'>
          {t('easypay.owner.subtitle')}
        </p>
        <div className='grid grid-cols-2 gap-y-4 gap-x-8 mb-6'>
          <OwnershipInfoFields />
        </div>
        <CheckboxField
          name='owner.disclosureCheckbox'
          label={t('easypay.review.checkbox')}
          data-testid='owner-authorization'
        />
        <DisclosureList isLoan={false} />
        <ContentFooter className='py-6 bg-white'>
          <div className='flex justify-end w-full'>
            <BackButton onClick={goToPreviousStep} />
            <ContinueButton
              testId='submit'
              onClick={submitForm}
              disabled={!isValid}
              className='mr-6'
            >
              {t('common.submit')}
            </ContinueButton>
          </div>
        </ContentFooter>
      </PageMain>
      <EasyPayAside />
    </PageBody>
  )
}
