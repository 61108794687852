import React from 'react'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean
  isBusinessInfo?: boolean
  onRequestClose(): void
}

export const InvalidInfoModal = ({
  isOpen,
  isBusinessInfo = false,
  onRequestClose
}: Props) => {
  const { t } = useTranslation()
  return (
    <Modal
      testId='invalid-info-modal'
      // @ts-ignore
      appElement={document.body}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <ModalHeader>
        <div className='pb-4'>{t('prospectLoans.shared.sorry')}</div>
      </ModalHeader>
      <ModalBody>
        <div>
          {t(
            isBusinessInfo
              ? 'prospectLoans.businessInfo.invalidBusinessInfo'
              : 'prospectLoans.ownership.invalidOwnershipInfo'
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onRequestClose} data-testid='close-invalid-info-modal'>
          {t('prospectLoans.shared.gotIt')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
