import React from 'react'
import { TelephoneField, TextInputField } from '@toasttab/buffet-pui-forms'
import { AddressFields } from '@local/address-fields'
import { useTranslation } from 'react-i18next'

export const OwnershipInfoFields = () => {
  const { t } = useTranslation()
  return (
    <>
      <TextInputField
        className='fs-mask'
        autoComplete='false'
        name='owner.firstName'
        label={t('financing.personalGuarantor.fields.first.label')}
        data-testid='owner-first-name'
        containerClassName='col-span-2 md:col-span-1'
      />

      <TextInputField
        className='fs-mask'
        autoComplete='false'
        name='owner.lastName'
        label={t('financing.personalGuarantor.fields.last.label')}
        data-testid='owner-last-name'
        containerClassName='col-span-2 md:col-span-1'
      />

      <AddressFields
        addressLine1FieldName='owner.address.street1'
        addressLine1FieldLabel={t(
          'financing.personalGuarantor.fields.address1.label'
        )}
        addressLine2FieldName='owner.address.street2'
        addressLine2FieldLabel={t(
          'financing.personalGuarantor.fields.address2.label'
        )}
        cityFieldName='owner.address.city'
        stateFieldName='owner.address.state'
        zipCodeFieldName='owner.address.zip'
        dataTestIdPrefix='owner'
        addressHelperText=''
      />

      <TelephoneField
        className='fs-mask'
        autoComplete='false'
        name='owner.phoneNumber'
        label={t('financing.personalGuarantor.fields.phone.label')}
        data-testid='owner-phone'
        containerClassName='col-span-2 md:col-span-1'
      />

      <TextInputField
        className='fs-mask'
        autoComplete='false'
        name='owner.email'
        label={t('financing.personalGuarantor.fields.email.label')}
        data-testid='owner-email'
        containerClassName='col-span-2 md:col-span-1'
      />
    </>
  )
}
