import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { ProspectLoanFormValues, ProspectLoanSteps } from './types'
import FinancingApi from '../../src/api/Financing'
import { convertFormValuesToRequestPayload } from './convertFormValuesToRequestPayload'

export const useSubmitProspectLoanApplication = (
  goToStep: (stepId: string) => void
) => {
  const { dealNumber } = useParams<{ dealNumber: string }>()

  const { mutate: submitApplication } = useMutation(
    'submit-prospect-loan-application',
    (loanApplication: ProspectLoanFormValues) =>
      FinancingApi.submitProspectLoanApplication(
        convertFormValuesToRequestPayload(loanApplication, dealNumber)
      ),
    {
      onSuccess: () => goToStep(ProspectLoanSteps.SUBMITTED),
      onError: () => goToStep(ProspectLoanSteps.ERROR)
    }
  )

  return submitApplication
}
