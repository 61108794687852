import * as React from 'react'
import ReactDOM from 'react-dom'
import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import App from './App'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  singleSpaCssLifecycles,
  cssScope: 'data-cap-fin',
  rootComponent: App,
  portalContainers: ['banquetPortalsContainer'],
  sentry: {
    publicKey: 'e9b3b1fd492d4f5e9240b05dce3b9894@o37442',
    projectId: '5262164'
  }
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'customer-financing-ui'
