const MAX_ATTEMPTS = 3
export const BUSINESS_INFO_VALIDATION = 'PMTS_CAPITAL_BUSINESS_INFO_VALIDATION'
export const OWNERSHIP_VALIDATION = 'PMTS_CAPITAL_OWNERSHIP_VALIDATION'

export const useLimitValidationAttempts = () => {
  const getValidationAttempts = (validationType: string) => {
    const localStorageItem = localStorage.getItem(validationType)
    const { attempts, timestamp } = localStorageItem
      ? JSON.parse(localStorageItem)
      : { attempts: 0, timestamp: null }

    if (timestamp && Date.now() - timestamp >= 24 * 60 * 60 * 1000) {
      localStorage.removeItem(validationType)
      return 0
    }

    return attempts
  }

  const incrementValidationAttempts = (validationType: string) => {
    const attempts = getValidationAttempts(validationType) + 1
    localStorage.setItem(
      validationType,
      JSON.stringify({ attempts, timestamp: Date.now() })
    )
  }

  const canValidate = (validationType: string) => {
    return getValidationAttempts(validationType) < MAX_ATTEMPTS
  }

  return {
    incrementBusinessInfoValidationAttempts: () =>
      incrementValidationAttempts(BUSINESS_INFO_VALIDATION),
    canValidateBusinessInfo: () => canValidate(BUSINESS_INFO_VALIDATION),
    incrementOwnershipValidationAttempts: () =>
      incrementValidationAttempts(OWNERSHIP_VALIDATION),
    canValidateOwnership: () => canValidate(OWNERSHIP_VALIDATION)
  }
}
