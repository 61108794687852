import React, { useEffect, useMemo, useState } from 'react'
import { useDeepMemo } from './useDeepMemo'

interface FlowBaseProps {
  currentStepId: string
  hasPreviousStep: boolean
  hasNextStep: boolean
  goToNextStep(): void
  goToPreviousStep(): void
  goToStep(stepId: string): void
  steps: Omit<FlowConfig['steps'], 'component'>
}

export type FlowProps<
  OtherStepComponentProps extends Record<string, unknown> = {}
> = FlowBaseProps & OtherStepComponentProps

export interface FlowConfig<
  OtherStepComponentProps extends Record<string, unknown> = {}
> {
  initialStep?: string
  steps: {
    id: string
    component: React.ComponentType<FlowProps<OtherStepComponentProps>>
  }[]
}

export const useFlowNavigation = <
  OtherStepComponentProps extends Record<string, unknown> = {}
>(
  config: FlowConfig<OtherStepComponentProps>
) => {
  const steps = useDeepMemo(() => config.steps, [config.steps])

  if (steps.length === 0) {
    throw new Error('There are no visible steps')
  }

  const stepIdToStepIndexMap = useMemo(
    () =>
      steps.reduce(
        (prev, next, i) => ({ ...prev, [next.id]: i }),
        {} as Record<string, number>
      ),
    [steps]
  )

  const [currentStepId, setCurrentStepId] = useState<string>('')

  useEffect(() => {
    if (stepIdToStepIndexMap[currentStepId] === undefined) {
      const hasValidInitialStep =
        !!config.initialStep &&
        Number.isInteger(stepIdToStepIndexMap[config.initialStep])
      const initialStepId = hasValidInitialStep
        ? config.initialStep
        : steps[0].id
      setCurrentStepId(initialStepId as string)
    }
    // I don't want to trigger this useEffect if any values that did not affect
    // the steps change. Like if the config.initialStep changes, I'm not going
    // to reset the flow to that initialStep.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepIdToStepIndexMap])

  const hasPreviousStep = stepIdToStepIndexMap[currentStepId] > 0
  const hasNextStep = stepIdToStepIndexMap[currentStepId] < steps.length - 1

  const goToStep = (stepId: string) => {
    const stepIndex = stepIdToStepIndexMap[stepId]
    if (stepIndex !== undefined) {
      setCurrentStepId(stepId)
    }
  }

  const goToNextStep = () => {
    if (hasNextStep) {
      const currentStepIndex = stepIdToStepIndexMap[currentStepId]
      setCurrentStepId(steps[currentStepIndex + 1].id)
    }
  }

  const goToPreviousStep = () => {
    if (hasPreviousStep) {
      const currentStepIndex = stepIdToStepIndexMap[currentStepId]
      setCurrentStepId(steps[currentStepIndex - 1].id)
    }
  }

  const props = {
    currentStepId,
    hasPreviousStep,
    hasNextStep,
    goToNextStep,
    goToPreviousStep,
    goToStep,
    steps
  }

  const StepComponent = (
    otherProps: OtherStepComponentProps & Partial<FlowBaseProps>
  ) => {
    const currentStepIndex = stepIdToStepIndexMap[currentStepId]

    if (!Number.isInteger(currentStepIndex)) {
      return null
    }

    const Step = steps[stepIdToStepIndexMap[currentStepId]].component
    return <Step {...props} {...otherProps} />
  }

  return { ...props, StepComponent }
}
