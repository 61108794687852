import { ProspectLoanSteps } from '../../types'

export enum DISPLAY_STEPS {
  LOAN_USE = 'LOAN_USE',
  BUSINESS = 'BUSINESS',
  OWNERSHIP = 'OWNERSHIP',
  FINANCES = 'FINANCES',
  REVIEW = 'REVIEW'
}

export const displayStepsConfig = {
  [DISPLAY_STEPS.LOAN_USE]: [ProspectLoanSteps.LOAN_USE],
  [DISPLAY_STEPS.BUSINESS]: [
    ProspectLoanSteps.RESTAURANT_INFO,
    ProspectLoanSteps.BUSINESS_INFO
  ],
  [DISPLAY_STEPS.OWNERSHIP]: [ProspectLoanSteps.OWNERSHIP],
  [DISPLAY_STEPS.FINANCES]: [
    ProspectLoanSteps.FINANCES,
    ProspectLoanSteps.LINK_ACCOUNTS
  ],
  [DISPLAY_STEPS.REVIEW]: [ProspectLoanSteps.REVIEW_TERMS]
}
