import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import { DeleteIcon } from '@toasttab/buffet-pui-icons'
import { OWNER_ROLE, Owner, BASE_OWNER } from '../types'

interface Props {
  owners?: Owner[]
  removeOwner(index: number): void
  pushOwner(baseOwner: typeof BASE_OWNER): void
  openAddPersonModal(): void
}

export const OwnersList = ({
  owners = [],
  removeOwner,
  pushOwner,
  openAddPersonModal
}: Props): ReactElement => {
  const { t } = useTranslation()

  const isOwnerListEmpty = isEmpty(owners)
  const hasMaxOwners = owners.length === 4

  const handleClickAddPerson = () => {
    pushOwner({ ...BASE_OWNER })
    openAddPersonModal()
  }

  return isOwnerListEmpty ? (
    <div className='flex flex-col items-center mt-6 bg-gray-0 rounded p-6'>
      <p className='text-center text-default font-normal mb-6'>
        {t('prospectLoans.ownership.ownerList.emptyState')}
      </p>
      <Button
        testId='add-person-button'
        className='w-fit'
        variant='secondary'
        onClick={handleClickAddPerson}
        disabled={hasMaxOwners}
      >
        {t('prospectLoans.ownership.ownerList.addPerson')}
      </Button>
    </div>
  ) : (
    <div className='w-full pt-6'>
      <div className='w-full mb-6 flex items-center justify-between'>
        <p className='max-w-[450px]'>
          {t('prospectLoans.ownership.ownerList.description')}
        </p>
        <Button
          variant='secondary'
          onClick={handleClickAddPerson}
          disabled={hasMaxOwners}
        >
          {t('prospectLoans.ownership.ownerList.addPerson')}
        </Button>
      </div>
      <>
        {owners.map((owner, index) => (
          <div
            className='flex items-center mb-4'
            key={`${owner.firstName}-${owner.lastName}`}
          >
            <div className='flex bg-gray-0 rounded p-6 flex-1 mr-4'>
              <div>
                <p className='text-secondary type-default font-medium mb-2'>
                  {owner.firstName} {owner.lastName}
                </p>
                <p className='text-secondary type-default font-normal'>
                  {t('prospectLoans.ownership.ownerList.role')}{' '}
                  {owner.role === OWNER_ROLE.OWNER
                    ? t('prospectLoans.ownership.ownerList.owner')
                    : t('prospectLoans.ownership.ownerList.controlPerson')}
                </p>
              </div>
            </div>
            <IconButton
              onClick={() => removeOwner(index)}
              icon={
                <DeleteIcon className='text-brand-100' aria-label='delete' />
              }
            />
          </div>
        ))}
      </>
    </div>
  )
}
