import { useFormikContext } from 'formik'
import {
  BackButton,
  ContentFooter,
  ContinueButton,
  PageBody,
  PageMain
} from '@toasttab/buffet-pui-wizard-templates'
import React, { ReactElement, useEffect } from 'react'
import { CheckboxField } from '@toasttab/buffet-pui-forms'
import DisclosureList from '../../src/components/DisclosureList'
import { PersonalInfoFields } from '@local/ownership-info-fields'
import { FinancingApplicationForm, FinancingStepProps } from './Types'
import { FlowProps } from '@local/use-flow-navigation'
import { scrollToTop } from './helpers'
import { DisclaimerAside } from './DisclaimerAside'
import { useTranslation } from 'react-i18next'

export const ReviewTerms = ({
  isValid,
  validateForm,
  goToPreviousStep
}: FlowProps<FinancingStepProps>): ReactElement => {
  const { t } = useTranslation()
  const { values, submitForm } = useFormikContext<FinancingApplicationForm>()
  useEffect(() => {
    validateForm()
    scrollToTop()
  }, [validateForm])
  return (
    <PageBody testId='review-terms-page'>
      <PageMain>
        <h1 className='type-headline-4 md:type-headline-2 font-bold text-default mb-3'>
          {t('financing.review.title')}
        </h1>
        {!values.contact.isGuarantor && (
          <div className='grid grid-cols-2 gap-y-4 gap-x-8 mb-6'>
            <PersonalInfoFields />
          </div>
        )}
        {!!values.contact.isGuarantor && (
          <h2 className='type-headline-3 mt-5 mb-2'>
            {t('financing.review.attestations.title')}
          </h2>
        )}
        <CheckboxField
          name='owner.disclosureCheckbox'
          label={t('financing.review.attestations.checkbox')}
          data-testid={'owner-authorization'}
        />
        <DisclosureList isLoan />
        <ContentFooter className='py-6 bg-white'>
          <div className='flex justify-end w-full'>
            <BackButton onClick={goToPreviousStep} />
            <ContinueButton
              testId='review'
              onClick={submitForm}
              disabled={!isValid}
              className='mr-6'
            >
              {t('common.review.submit')}
            </ContinueButton>
          </div>
        </ContentFooter>
      </PageMain>
      <DisclaimerAside />
    </PageBody>
  )
}
