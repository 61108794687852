import React, { ReactElement } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import i18n from 'i18next'
import { PageAside } from '@toasttab/buffet-pui-wizard-templates'
import {
  LockLockedDisplayIcon,
  InfoDisplayIcon
} from '@toasttab/buffet-pui-icons'

export const LinkAccountsAside = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <PageAside>
      <div className='flex items-start mb-16'>
        <LockLockedDisplayIcon accessibility='decorative' className='mr-6' />
        <div>
          <p className='font-semibold type-default mb-3'>
            {t('prospectLoans.linkAccounts.aside.secure.title')}
          </p>
          <p className='text-secondary type-subhead'>
            {t('prospectLoans.linkAccounts.aside.secure.description')}
          </p>
        </div>
      </div>
      <div className='flex items-start'>
        <InfoDisplayIcon accessibility='decorative' className='mr-6' />
        <div>
          <p className='font-semibold type-default mb-3'>
            {t('prospectLoans.linkAccounts.aside.questions.title')}
          </p>
          <p className='text-secondary type-subhead'>
            <Trans
              i18nKey='prospectLoans.linkAccounts.aside.questions.description'
              t={t}
              i18n={(i18n as any).i18next}
            >
              <a
                className='font-semibold text-link underline'
                href='https://pos.toasttab.com/toast-capital-connection-faqs'
                target='_blank'
                rel='noreferrer'
              >
                0
              </a>
            </Trans>
          </p>
        </div>
      </div>
    </PageAside>
  )
}
