import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FieldArray, useFormikContext } from 'formik'
import {
  PageBody,
  PageMain,
  ContentFooter,
  ContinueButton,
  BackButton
} from '@toasttab/buffet-pui-wizard-templates'
import { FlowProps } from '@local/use-flow-navigation'
import { ProspectLoanFormValues, ProspectLoanStepProps } from '../types'
import { PageTitle } from '../shared'
import { OwnershipAside } from './OwnershipAside'
import { OwnersList } from './OwnersList'
import { AddPersonModal } from './AddPersonModal'
import { OWNER_ROLE } from '../types'

export const Ownership = ({
  isValid,
  validateForm,
  goToNextStep,
  goToPreviousStep,
  setPageError
}: FlowProps<ProspectLoanStepProps>): ReactElement => {
  const { t } = useTranslation()

  const [isAddPersonModalOpen, setIsAddPersonModalOpen] = useState(false)

  useEffect(() => {
    validateForm()
  }, [validateForm])

  const { values } = useFormikContext<ProspectLoanFormValues>()
  const handleContinue = () => {
    if (
      values.owners.some((owner) => owner.role === OWNER_ROLE.CONTROL_PERSON)
    ) {
      goToNextStep()
    } else {
      setPageError(t('prospectLoans.ownership.controlPersonError'))
    }
  }

  const handleCloseAddPersonModal = () => {
    setIsAddPersonModalOpen(false)
    setPageError(undefined)
  }

  return (
    <PageBody testId='prospect-loans-ownership-page'>
      <PageMain>
        <PageTitle
          title={t('prospectLoans.ownership.title')}
          description={t('prospectLoans.ownership.description')}
          fullWidth
        />
        <FieldArray name='owners'>
          {({ push, form, remove }) => {
            return (
              <>
                <OwnersList
                  owners={form.values.owners}
                  removeOwner={remove}
                  pushOwner={push}
                  openAddPersonModal={() => setIsAddPersonModalOpen(true)}
                />
                {isAddPersonModalOpen && (
                  <AddPersonModal
                    owners={form.values.owners}
                    removeOwner={remove}
                    onRequestClose={handleCloseAddPersonModal}
                  />
                )}
              </>
            )
          }}
        </FieldArray>
        <ContentFooter className='mt-6 py-6 bg-white'>
          <div className='flex justify-end w-full'>
            <BackButton onClick={goToPreviousStep} />
            <ContinueButton
              onClick={handleContinue}
              disabled={!isValid}
              className='mr-6'
              testId='ownership'
            >
              {t('prospectLoans.shared.continue')}
            </ContinueButton>
          </div>
        </ContentFooter>
      </PageMain>
      <OwnershipAside />
    </PageBody>
  )
}
